import React, { useState } from 'react';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { EventsService } from '../../../services/EventsService/EventsService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import ProductDetailsView from '../InteractiveApp/components/ProductDetailsView/ProductDetailsView';
import { EngineHandoff, HandoffService } from '../../../services/HandoffService/HandoffService';
import StyledText, { TextSize, TextStyle } from '../../shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import HandOffSetupWrapper from './components/HandoffSetupWrapper/HandOffSetupWrapper';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

const HandoffApp: FunctionalComponentWithChildren = () => {
    const eventService = useInjection<EventsService>(DependencyType.EventsService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const handoffService = useInjection<HandoffService>(DependencyType.HandoffService);
    const [handoff, setHandoff] = useState<EngineHandoff>();
    const { t } = useTranslation();

    React.useEffect(() => {
        const interval = setInterval(() => {
            const handoffId = handoffService.getHandoffId();
            if (handoffId) {
                handoffService
                    .getHandoff(handoffId)
                    .then(ho => {
                        if (!handoff || handoff.updatedAt !== ho.updatedAt) {
                            setHandoff(ho);
                        }
                    })
                    .catch(() => {});
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [handoff]);

    if (!configService.config()?.handoff) {
        return (
            <div className={'HandoffApp_noticeScreen'}>
                <StyledText style={TextStyle.Body} size={TextSize.Small} className={'HandoffApp_handoffText'}>
                    <i className="fa-regular fa-face-sad-cry fa-2x handoffIcon" />
                    <br />
                    {t('product_view.customisation.invalid_handoff')}
                </StyledText>
            </div>
        );
    }
    eventService.setOpenProduct(configService.config()?.handoff?.productHandle);

    let content: React.ReactElement;

    if (!eventService.openProduct) {
        content = (
            <div className={'HandoffApp_noticeScreen'}>
                <StyledText style={TextStyle.Body} size={TextSize.Small} className={'HandoffApp_handoffText'}>
                    <i className="fa-regular fa-face-sad-cry fa-2x handoffIcon" />
                    <br />
                    {t('product_view.customisation.invalid_handoff')}
                </StyledText>
            </div>
        );
    } else if (!handoff) {
        content = (
            <div className={'HandoffApp_noticeScreen'}>
                <StyledText style={TextStyle.Body} size={TextSize.Small} className={'HandoffApp_handoffText'}>
                    <i className="fa-solid fa-cloud-arrow-down fa-2x handoffIcon" />
                    <br />
                    {t('product_view.customisation.loading_details')}
                </StyledText>
            </div>
        );
    } else if (handoff.isCanceled) {
        content = (
            <div className={'HandoffApp_noticeScreen'}>
                <StyledText style={TextStyle.Body} size={TextSize.Small} className={'HandoffApp_handoffText'}>
                    <i className="fa-regular fa-face-sad-cry fa-2x handoffIcon" />
                    <br />
                    {t('product_view.customisation.session_cancelled')}
                </StyledText>
            </div>
        );
    } else {
        content = (
            <ProductDetailsView
                blocks={['BLOCK:PRODUCT_TITLE', 'BLOCK:PRODUCT_CUSTOMISER']}
                hideCartOptions
                handoffCustomisationFieldId={configService.config()?.handoff?.productOptionId}
            />
        );
    }

    return (
        <HandOffSetupWrapper explicitHandle={configService.config()?.handoff?.productHandle}>
            {content}
        </HandOffSetupWrapper>
    );
};

export default HandoffApp;
