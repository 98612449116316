import React, { FC, useEffect, useRef, useState } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { Waveform } from '@uiball/loaders';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface IChipProps {
    className?: string;
    renderStyle: 'retailer' | 'subcategory';
    leading?: React.ReactNode;
    trailing?: React.ReactNode;
    text: string;
    onClick: () => void;
    translate?: boolean;
    badgeText?: () => Promise<string>;
    resetHash?: string;
    useContrastColor?: boolean;
}

const Chip: FunctionalComponentWithChildren<IChipProps> = props => {
    const [badgeText, setBadgeText] = useState<string | undefined>(undefined);
    const lastResetHash = useRef<string>('');

    const asyncSetBadgeText = async () => {
        if (props.badgeText) {
            const text = await props.badgeText();
            setBadgeText(text);
        }
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined = undefined;
        if (props.resetHash !== undefined && lastResetHash.current !== props.resetHash) {
            timeout = setTimeout(asyncSetBadgeText, 250);
            lastResetHash.current = props.resetHash;
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [props.resetHash]);

    let extraTextMargin = '';
    if (!props.leading) {
        extraTextMargin = 'Chip__ExtraMargin__Left';
    }

    if (!props.trailing) {
        extraTextMargin = `${extraTextMargin} ${'Chip__ExtraMargin__Right'}`;
    }

    return (
        <button
            className={`Chip ${props.renderStyle === 'retailer' ? 'Chip__RetailerStyled' : ''} ${
                props.renderStyle === 'subcategory' ? 'Chip__SubcategoryStyled' : ''
            } ${props.className}`}
            onClick={props.onClick}
        >
            {props.leading && <span className={'Chip__Leading'}>{props.leading}</span>}
            <StyledText
                style={TextStyle.Body}
                size={TextSize.Small}
                translate={props.translate ?? false}
                className={`Chip__Text ${extraTextMargin} ${
                    props.renderStyle === 'subcategory' ? 'Chip__Text__Black' : ''
                }`}
                noWrap
                useContrastColor={props.useContrastColor}
            >
                {props.text}
                {props.badgeText && (
                    <span
                        className={`Chip__InternalBadge ${
                            badgeText === undefined ? 'Chip__InternalBadge__Loading' : ''
                        }`}
                    >
                        {badgeText === undefined ? <Waveform size={17} color={'white'} /> : badgeText}
                    </span>
                )}
            </StyledText>
            {props.trailing && (
                <span className={`Chip__Trailing ${props.useContrastColor ? 'Chip__Trailing__ContrastColor' : ''}`}>
                    {props.trailing}
                </span>
            )}
        </button>
    );
};

export default Chip;
