import { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

const VersionOverlayComponent: FunctionalComponentWithChildren = () => {
    const version = process.env.REACT_APP_PACKAGE_VERSION ?? 'Unknown';
    const releaseType = process.env.REACT_APP_RELEASE_TYPE ?? 'Unknown';

    const blockUpdates = process.env.REACT_APP_DONT_UPDATE === 'true';

    let shortReleaseType = 'Unk';
    if (releaseType === 'production') {
        shortReleaseType = 'prod';
    } else if (releaseType === 'development') {
        shortReleaseType = 'dev';
    }
    return (
        <p>
            v: {version} ({shortReleaseType}) {blockUpdates ? '(Auto Update Disabled)' : '(Auto Update Enabled)'}
        </p>
    );
};

export default VersionOverlayComponent;
