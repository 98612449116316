import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const NoImagePicture: FunctionalComponentWithChildren = props => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478.5 376.78">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <g>
                        <rect
                            fill="#e0e0e0"
                            x="90.53"
                            y="91.91"
                            width="355"
                            height="236"
                            rx="13.98"
                            ry="13.98"
                            transform="translate(-23.33 33.81) rotate(-6.92)"
                        />
                        <path
                            fill="#f4f4f4"
                            d="M444.57,307.36c-88.81,10.73-234.7,29.04-323.2,40.1-3.01,.5-6.5,.33-9.34-1.06-6.53-3.01-8.69-8.73-9.12-15.53-5.07-40.16-14-112.4-18.92-152.63-1.22-11.05-5.27-41.08-6.26-51.8-.17-7.65,5.97-14.64,13.58-15.46,0,0,143.72-17.22,143.72-17.22,26.23-3.09,156.28-18.38,179.7-21.13,6.16-1.2,12.99,1.74,15.88,7.44,1.55,2.72,1.7,5.93,2.07,8.94,6.83,59.27,16.48,138.56,23.51,197.63,.16,2.36,1.09,6.57,.53,9.01-.89,6.05-6.07,11.04-12.15,11.71h0Zm0,0c7.7-.82,13.5-8.59,12.08-16.21,0,0-.55-4.49-.55-4.49-7.17-58.99-17.03-138.39-24.44-197.52-.39-2.93-.5-5.95-2.01-8.55-2.8-5.26-8.97-7.93-14.78-6.74-22.54,2.89-153.97,19.21-179.54,22.46,0,0-143.67,17.64-143.67,17.64-6.14,.66-11.14,6.28-11.01,12.47,1.03,10.27,4.84,40.72,6.08,51.49,4.97,40.26,13.34,112.49,18.13,152.72,.37,2.91,.43,5.83,1.9,8.41,1.32,2.48,3.46,4.52,6.03,5.69,2.67,1.25,5.41,1.43,8.36,.98,88.44-10.29,234.77-27.67,323.41-38.35h0Z"
                        />
                    </g>
                    <polygon fill="#d3d3d3" points="98.77 288.5 101.39 310.12 391.02 288.5 381.48 263.32 98.77 288.5" />
                    <path
                        fill="#e8e8e8"
                        d="M91.5,112.46c88.75-11.22,234.63-29.59,323.16-40.47,3.02-.5,6.67-.33,9.53,1.11,5.32,2.32,8.72,7.85,9,13.57,0,0,.27,2.24,.27,2.24,0,0,1.1,8.98,1.1,8.98,6.91,57.51,16.7,135.83,23.61,193.06,1.43,8.52-4.76,16.91-13.4,17.88,0,0-287.43,34.52-287.43,34.52-1.8,.07-39.76,5.11-40.68,4.42-4.84-.48-9.33-3.53-11.59-7.83-1.58-2.76-1.76-6.05-2.13-9.1,0,0-1.06-8.98-1.06-8.98-6.05-51.88-15.15-128.02-21.03-179.71,0,0-1.04-8.99-1.04-8.99-.16-2.4-1.07-6.56-.5-9.03,.91-6.06,6.12-11.05,12.21-11.7-6.04,.72-11.2,5.69-12.03,11.73-.48,2.47,.33,6.44,.59,8.97,0,0,1.14,8.98,1.14,8.98,6.45,51.53,16.23,127.77,22.55,179.52,0,0,1.12,8.98,1.12,8.98,.39,2.9,.47,5.83,1.95,8.39,2.73,5.11,8.66,7.67,14.32,6.52,0,0,35.91-4.46,35.91-4.46,0,0,287.34-35.21,287.34-35.21,6.98-.78,12.07-7.61,10.83-14.55-6.79-57.15-16.4-135.7-23.24-193.11-.13-1.55-1.22-9.82-1.35-11.23-.22-4.9-2.95-9.57-7.51-11.57-2.64-1.21-5.24-1.38-8.17-.93-88.42,10.48-234.76,27.8-323.46,37.98h0Z"
                    />
                    <g>
                        <g>
                            <rect fill="#eaeaea" x="47" y="52.5" width="355" height="236" rx="13.98" ry="13.98" />
                            <path
                                fill="#e8e8e8"
                                d="M388.02,288.5c-89.46-.05-236.49,.56-325.68,.89-3.04,.13-6.5-.45-9.14-2.18-6.1-3.75-7.59-9.74-7.19-16.52-.2-40.48-.36-113.27-.4-153.79,.12-11.11-.28-41.42,.03-52.18,.76-7.61,7.69-13.82,15.34-13.71,0,0,144.75,.21,144.75,.21,26.41,.09,157.35,.58,180.93,.67,6.26-.45,12.69,3.3,14.87,9.3,1.21,2.88,.98,6.09,.98,9.12-.35,59.66-.33,139.54-.47,199.03-.13,2.36,.3,6.65-.56,9-1.61,5.9-7.35,10.23-13.47,10.16h0Zm0,0c6.09,.04,11.84-4.27,13.42-10.18,.78-2.4,.48-6.44,.53-8.99,0,0-.01-9.05-.01-9.05-.03-55.77-.23-134.08-.45-189.98-.04-2.96,.22-5.96-.97-8.73-1.58-4.05-5.24-7.21-9.5-8.14-1.42-.32-2.83-.36-4.36-.33,0,0-36.19,.14-36.19,.14-72.15,.39-217.36,.67-289.49,.74-6.17-.08-11.81,4.89-12.43,11.05-.22,10.32-.1,41.01-.17,51.85,.09,40.56-.3,113.28-.4,153.79-.35,6.1,.57,11.45,6.17,14.95,2.5,1.56,5.2,2.07,8.18,1.97,89.04,.44,236.4,.81,325.68,.89h0Z"
                            />
                        </g>
                        <path
                            fill="#fff"
                            d="M47.5,202.73c26.5-25.23,49.19-27.77,78.35,0s53.6,21,69.15-1.23,44.99-86,94-86c56,0,65,50,112.5,106.04v53.87c0,6.96-5.64,12.59-12.59,12.59H60.09c-6.96,0-12.59-5.64-12.59-12.59v-72.68Z"
                        />
                        <circle fill="#fff" cx="151" cy="159.5" r="16" />
                        <path
                            fill="#dbdbdb"
                            d="M60.98,52.5c89.46-.45,236.48-1.12,325.68-1.25,3.06-.13,6.66,.48,9.32,2.25,5,2.95,7.71,8.84,7.3,14.55,0,0,0,2.26,0,2.26,0,0,0,9.05,0,9.05-.07,57.93,.22,136.85,.18,194.5,.39,8.63-6.76,16.21-15.46,16.14,0,0-289.49-.34-289.49-.34-1.8-.15-40.09,.28-40.91-.51-4.75-1.06-8.83-4.62-10.57-9.17-1.24-2.93-1.02-6.22-1.02-9.29,0,0,.03-9.05,.03-9.05,.24-52.23,.38-128.91,.76-180.93,0,0,.05-9.05,.05-9.05,.13-2.4-.27-6.64,.59-9.02,1.63-5.91,7.41-10.23,13.53-10.14-6.08-.02-11.8,4.3-13.35,10.19-.78,2.39-.45,6.43-.49,8.97,0,0,.05,9.05,.05,9.05,.2,51.93,.72,128.8,.76,180.93,0,0,.03,9.05,.03,9.05,.04,2.92-.23,5.84,.93,8.56,2.09,5.4,7.67,8.66,13.44,8.2,0,0,36.19-.1,36.19-.1,0,0,289.49-.34,289.49-.34,7.02,.06,12.89-6.1,12.51-13.14,.14-57.55,.07-136.68,.18-194.5,.06-1.55-.03-9.9,.01-11.31,.37-4.89-1.77-9.85-6.06-12.39-2.47-1.52-5.03-2-7.99-1.91-89.04-.24-236.4-.68-325.68-1.25h0Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#e8e8e8"
                            d="M81.4,6.31c-3.45,.57-4.53,1.65-5.1,5.1-.08,.48-.77,.48-.85,0-.57-3.45-1.65-4.53-5.1-5.1-.48-.08-.48-.77,0-.85,3.45-.57,4.53-1.65,5.1-5.1,.08-.48,.77-.48,.85,0,.57,3.45,1.65,4.53,5.1,5.1,.48,.08,.48,.77,0,.85Z"
                        />
                        <path
                            fill="#e8e8e8"
                            d="M312.4,371.31c-3.45,.57-4.53,1.65-5.1,5.1-.08,.48-.77,.48-.85,0-.57-3.45-1.65-4.53-5.1-5.1-.48-.08-.48-.77,0-.85,3.45-.57,4.53-1.65,5.1-5.1,.08-.48,.77-.48,.85,0,.57,3.45,1.65,4.53,5.1,5.1,.48,.08,.48,.77,0,.85Z"
                        />
                        <circle fill="#e8e8e8" cx="47.28" cy="323.91" r="6.07" />
                        <path
                            fill="#e8e8e8"
                            d="M7.07,360.32c-3.9,0-7.07-3.17-7.07-7.07s3.17-7.07,7.07-7.07,7.07,3.17,7.07,7.07-3.17,7.07-7.07,7.07Zm0-12.13c-2.79,0-5.07,2.27-5.07,5.07s2.27,5.07,5.07,5.07,5.07-2.27,5.07-5.07-2.27-5.07-5.07-5.07Z"
                        />
                        <path
                            fill="#e8e8e8"
                            d="M300.97,24.66c-3.9,0-7.07-3.17-7.07-7.07s3.17-7.07,7.07-7.07,7.07,3.17,7.07,7.07-3.17,7.07-7.07,7.07Zm0-12.13c-2.79,0-5.07,2.27-5.07,5.07s2.27,5.07,5.07,5.07,5.07-2.27,5.07-5.07-2.27-5.07-5.07-5.07Z"
                        />
                        <circle fill="#e8e8e8" cx="472.43" cy="159.31" r="6.07" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default NoImagePicture;
