import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import React, { useEffect, useState } from 'react';
import { RoutesHelperService } from '../../../../../services/RoutesService/RoutesHelperService';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { History } from 'history';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface BrandingBarProps {
    history: History;
}

export const BrandingBar: FunctionalComponentWithChildren<BrandingBarProps> = props => {
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const shouldDisplayBranding = configService.config()?.showCloudshelfBranding;
    const [inProp, setInProp] = useState(shouldDisplayBranding);
    const [aboutCloudshelfCanOpen, setAboutCloudshelfCanOpen] = useState(false);

    useEffect(() => {
        const interactiveLoadingSubscriber = eventsService.observeLoadingComplete().subscribe(() => {
            setAboutCloudshelfCanOpen(true);
        });
        return () => {
            interactiveLoadingSubscriber.unsubscribe();
        };
    }, [eventsService]);

    return (
        <div
            className={`brandingBar ${inProp ? 'brandingBar__in' : 'brandingBar__out'}`}
            onClick={() => {
                if (aboutCloudshelfCanOpen) {
                    props.history.push(RoutesHelperService.toCloudshelfBranding());
                }
            }}
        >
            <div className={'brandingBar__logoWrapper'}>
                <StyledText
                    className={'brandingBar__logoWrapper__poweredBy'}
                    style={TextStyle.Heading}
                    size={TextSize.Small}
                    superNeverTranslate
                >
                    Powered by
                </StyledText>
                <img src={'/logos/pink-logo.svg'} alt="Cloudshelf" className={'brandingBar__logoWrapper__logo'} />
                <StyledText
                    className={'brandingBar__logoWrapper__logoText'}
                    style={TextStyle.Heading}
                    size={TextSize.Small}
                    superNeverTranslate
                >
                    Cloudshelf
                </StyledText>
            </div>
        </div>
    );
};
