export function calculateVirtualKeyboardSize(height: number, width: number, diagonalScreenSize: number) {
    const targetWidth = 80;
    const targetWidthPercent = targetWidth / 100;
    const constraintMaxInch = 12;
    const contraintMinPixel = 400;

    const widthHeightRatio = height / width;
    // console.log('height', height, 'px');
    // console.log('width', width, 'px');
    // console.log('ratio', widthHeightRatio);

    const widthInInches = Math.sqrt(Math.pow(diagonalScreenSize, 2) / (1 + Math.pow(widthHeightRatio, 2)));
    const heightInInches = widthInInches * widthHeightRatio;

    // console.log('diagonal', diagonalScreenSize, 'inch');
    // console.log('widthInInches', widthInInches.toFixed(1), 'inch');
    // console.log('heightInInches', heightInInches.toFixed(1), 'inch');

    const screenWidthInch = widthInInches;
    const screenWidthPixel = width;
    const pixelPerInch = screenWidthPixel / screenWidthInch;

    // console.log('screenWidthInch', screenWidthInch.toFixed(1), 'inch');
    // console.log('screenWidthPixel', screenWidthPixel.toFixed(0), 'px');
    // console.log('pixelPerInch', pixelPerInch.toFixed(0), 'px');
    // console.log('targetWidthPercent', targetWidth.toFixed(0), '%');
    // console.log('constraintMaxInch', constraintMaxInch.toFixed(1), 'inch');
    // console.log('contraintMinPixel', contraintMinPixel.toFixed(0), 'px');

    const keyboardWidthUnconstrained = targetWidthPercent * screenWidthInch;
    const keyboardPixelUnconstrained = targetWidthPercent * width;
    const keyboardWidthAfterMaxInchConstraint = Math.min(keyboardWidthUnconstrained, constraintMaxInch);
    const keyboardWidthAfterMinPixelConstraint = Math.max(
        contraintMinPixel / pixelPerInch,
        keyboardWidthAfterMaxInchConstraint,
    );

    // console.log('keyboardWidthUnconstrained', keyboardWidthUnconstrained.toFixed(1), 'inch');
    // console.log('keyboardPixelUnconstrained', keyboardPixelUnconstrained.toFixed(0), 'px');
    // console.log('keyboardWidthAfterMaxInchConstraint', keyboardWidthAfterMaxInchConstraint.toFixed(1), 'inch');
    // console.log('keyboardWidthAfterMinPixelConstraint', keyboardWidthAfterMinPixelConstraint.toFixed(1), 'inch');

    const keyboardInPxAfterAllConstraint = keyboardWidthAfterMinPixelConstraint * pixelPerInch;
    console.log('keyboardInPxAfterAllConstraint', keyboardInPxAfterAllConstraint);
    return keyboardInPxAfterAllConstraint;
}

export function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function calculateDPI(
    screenSizeInInches: number,
    screenWidthInPixels: number,
    screenHeightInPixels: number,
): number {
    const diagonalInPixels = Math.sqrt(screenWidthInPixels ** 2 + screenHeightInPixels ** 2);
    const dpi = diagonalInPixels / screenSizeInInches;

    // (window as any).DEVICE_SCREEN_SIZE_INCHES = screenSizeInInches;
    // (window as any).DEVICE_DPI = dpi;
    // (window as any).DEVICE_WIDTH_PX = screenWidthInPixels;
    // (window as any).DEVICE_HEIGHT_PX = screenHeightInPixels;
    return dpi;
}

export function getImageTileSize() {
    //images are 50% of the width of the whole screen
    return getOneVw() * 50;
}

export function getOneVh() {
    return window.innerHeight * 0.01;
}

export function getOneVw() {
    return window.innerWidth * 0.01;
}

export function setupOneVh(reason = 'Unknown') {
    const style = 'background-color: purple; color: white; font-style: italic;';
    const realOneVh = getOneVh();
    console.log(`%c[Cloudshelf] Fixing Mobile Viewport Height Unit. One VH is '${realOneVh}' Reason: ${reason}`, style);
    document.documentElement.style.setProperty('--vh', `${realOneVh}px`);
}

export function getRefRule(dpiValue?: number): { minHeight: number; smallestDimension: number } {
    const dpiStr = document.documentElement.style.getPropertyValue('--device-dpi');

    const dpi = dpiValue ?? parseFloat(dpiStr);

    const minTouchPoint = (getMinTouchPointValue() * dpi) / 2.54; // convert 1cm to pixels //0.845
    const minHeight = minTouchPoint / 0.567; // 0.567 is the ratio of the wrapper to the touch point
    //in this case, the reference point becomes 1.763668430335097cm

    const realOneVh = getOneVh();
    const realOneVw = getOneVw();

    let smallestDimension = realOneVh;
    if (realOneVw < smallestDimension) {
        smallestDimension = realOneVw;
    }

    return { minHeight: minHeight, smallestDimension: smallestDimension * getSmallestDimensionValue() };
}

export function setupResponsiveUnits(dpi: number, reason = 'Unknown') {
    const style = 'background-color: purple; color: white; font-style: italic;';
    //Setting the DPI
    document.documentElement.style.setProperty('--device-dpi', `${dpi}`);
    console.log(`%c[Cloudshelf] Device DPI: '${dpi}' Reason: ${reason}`, style);

    const ruleValues = getRefRule(dpi);

    const refRuleNoCss = Math.max(ruleValues.minHeight, ruleValues.smallestDimension);

    // const refRule = `max(${ruleValues.minHeight}px, ${ruleValues.smallestDimension}px)`;
    const refRule = `${refRuleNoCss}px`;
    document.documentElement.style.setProperty('--responsive-reference-point', refRule);
    console.log(`%c[Cloudshelf] Responsive Reference Point: '${refRule}' Reason: ${reason}`, style);
}

export function getPercentOfResponsivePoint(dpi: number, percent: number) {
    const ruleValues = getRefRule(dpi);
    const refRuleNoCss = Math.max(ruleValues.minHeight, ruleValues.smallestDimension);

    const result = refRuleNoCss * percent;
    console.log('getPercentOfResponsivePoint: ', result);
    return result;
}

export function getResponsiveRuleMinMaxValue(): string {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const minOrMax = localStorage.getItem('ResponsiveRuleMinMax');
        if (minOrMax) {
            return minOrMax;
        }
    }

    return 'max';
}

export function setResponsiveRuleMinMaxValue(value: string) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('ResponsiveRuleMinMax', value);
    }
}

export function getMinTouchPointValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const minTouchPoint = localStorage.getItem('minTouchPoint');
        if (minTouchPoint) {
            return parseFloat(minTouchPoint);
        }
    }

    return 0.8;
}

export function removeMinTouchPointValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('minTouchPoint');
    }
}
export function setMinTouchPointValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('minTouchPoint', value.toString());
    }
}

export function removeSmallTouchPointValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('smallestDimension');
    }
}
export function getSmallestDimensionValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const smallestDimension = localStorage.getItem('smallestDimension');
        if (smallestDimension) {
            return parseFloat(smallestDimension);
        }
    }

    return 9;
}

export function setSmallestDimensionValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('smallestDimension', value.toString());
    }
}

export function removeGridMinWidthInCMValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridMinWidthInCM');
    }
}

export function getGridMinWidthInCMValue(): number {
    // if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridMinWidthInCM = localStorage.getItem('gridMinWidthInCM');
        if (gridMinWidthInCM) {
            return parseFloat(gridMinWidthInCM);
        }
    }

    return 3.2;
}

export function setGridMinWidthInCMValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridMinWidthInCM', value.toString());
    }
}

export function removeGridSmallestDimensionValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridSmallestDimension');
    }
}

export function getGridSmallestDimensionValue(): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridSmallestDimension = localStorage.getItem('gridSmallestDimension');
        if (gridSmallestDimension) {
            return parseFloat(gridSmallestDimension);
        }
    }

    return 18;
}

export function setGridSmallestDimensionValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridSmallestDimension', value.toString());
    }
}

export function removeGridGutterValue() {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('gridGutter');
    }
}
export function getGridGutterValue(larger = false): number {
    //if we have local storage
    if (typeof localStorage !== 'undefined') {
        const gridGutter = localStorage.getItem('gridGutter');
        if (gridGutter) {
            return parseFloat(gridGutter);
        }
    }

    if (larger) {
        return 0.1;
    }

    return 0.04;
}

export function setGridGutterValue(value: number) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gridGutter', value.toString());
    }
}

export function canUseAspectRatio(): boolean {
    return window.CSS && window.CSS.supports && window.CSS.supports('aspect-ratio: 1');
}
