import { FC } from 'react';
import _ from 'lodash';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface SkeletonProps {
    type: 'text' | 'multiline' | 'rectangle';
    padding?: boolean;
}

export const Skeleton: FunctionalComponentWithChildren<SkeletonProps> = props => {
    const classNames: string[] = ['Skeleton'];

    if (props.type === 'text') {
        classNames.push('Skeleton__text');
    } else if (props.type === 'multiline') {
        classNames.push('Skeleton__multiline');
    } else if (props.type === 'rectangle') {
        classNames.push('Skeleton__rectangle');
    }

    if (props.padding) {
        classNames.push('Skeleton__padding');
    }

    return <div className={_.join(classNames, ' ')} />;
};
