import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { usePromise } from 'react-use';
import ProductCard, { BannerType } from '../../ProductCard/ProductCard';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import AppConfig from '../../../../../../types/AppConfig';
import PriceService from '../../../../../../services/PriceService/PriceService';
import _ from 'lodash';
import Chip from '../../Chip/Chip';
import * as Sentry from '@sentry/react';
import { ProductFilteringService } from '../../../../../../services/ProductServices/ProductFilteringService';
import ProductOpenerUtil from '../../ProductDetailsView/utils/ProductViewOpener';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { Category } from '../../../../../../services/CategoryService/entities/Category';
import { getSizedImageURL } from '../../../../../../utils/ImageURL.Util';
import { MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import { getMenuHeight } from '../../Menu/Menu';
import { useComputedStyle } from '../../../../../../hooks/UseComputedStyle';
import ResponsiveGrid, {
    generateGridProperties,
    ResponsiveGridItemWithKey,
} from '../../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { getDeviceDPI } from '../../../../../../index';
import { CloudshelfEngineFilter } from '../../../../../../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { VirtualizeList } from '../../VirtualizeList/VirtualizeList';
import ChevronIcon from '../../../../../icons/chevron';
import { TileSize } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { getImageTileSize } from '../../../../../../utils/Responsive.Util';
import { getRandomTileSize } from '../../../../../../utils/MixedGrid.Util';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import {
    FilterableProductWithCursor,
    SearchResult,
} from '../../../../../../services/ProductServices/FilterableProductTypes';
import { getBannerType } from '../../../../../../utils/BannerType.Util';
import { useTranslation } from 'react-i18next';
import GridLoader from '../../../../../shared/GridLoader/GridLoader';
import { FilterSelection } from '../../../../../../services/ProductServices/FilterSelection';
import { ProductsFetchOptions } from '../../../../../../services/ProductServices/ProductsFetchOptions';

const limit = AppConfig.PRODUCTS.PRODUCTS_PER_PAGE;

export interface ProductsPageProps {
    category?: Category;
    handleFetchProducts: (
        filterSelection: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
    ) => Promise<SearchResult<FilterableProductWithCursor>>;
    productDetailsUrl: (product: FilterableProductWithCursor) => string;
    productDetailsRoute: string;
    productListingRoute: string;
    loading?: boolean;
}

export interface FilterCount {
    productIds: string[];
    filter: FilterSelection;
}

export const ProductsPage: FunctionalComponentWithChildren<ProductsPageProps> = ({
    handleFetchProducts,
    productDetailsRoute,
    productDetailsUrl,
    category,
}) => {
    const [hasSlidIn, setHasSlidIn] = useState(false);
    const mounted = usePromise();
    const { t } = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const history = useHistory();
    const [hasMore, setHasMore] = useState(true);
    const [filterSelectionHash, setFilterSelectionHash] = useState<string>('');
    const [productIdToTileSize, setProductIdToTileSize] = useState<{ [key: string]: TileSize }>({});
    const [products, setProducts] = useState<FilterableProductWithCursor[]>([]);
    const [currentFilterSelection, setCurrentFilterSelection] = useState(filteringService.getCurrentSelection());
    const [wrapperSize, setWrapperSize] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const pageRef = useRef<HTMLDivElement>(null);
    const pageHeight = useComputedStyle(pageRef, 'height');
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const gridSizes = generateGridProperties(
        configService.config()!,
        document.body.clientWidth,
        getDeviceDPI(),
        true,
        undefined,
        undefined,
        configService.config()?.theme.productGridTileModifier,
    );

    useEffect(() => {
        console.log('wrapperSize', wrapperSize);

        if (wrapperSize === undefined && pageHeight !== 0) {
            setWrapperSize(`calc(${pageHeight}px - ${getMenuHeight()}px - 10px)`);
        }
    }, [wrapperSize, pageHeight]);

    const contentStyles: CSSProperties = {
        transform: hasSlidIn ? 'unset' : 'translateY(100%)',
        height: `${wrapperSize}`,
        overflowY: 'scroll',
    };

    useEffect(() => {
        if (!hasSlidIn) {
            setHasSlidIn(true);
        }
        setHasSlidIn(true);
    }, [hasSlidIn]);

    useEffect(() => {
        const filterStateObserver = filteringService.observeFilterSelectionState();

        const filterStateObserverSubscription = filterStateObserver.subscribe(
            async (filterSelection: FilterSelection[]) => {
                setIsLoading(true);
                setCurrentFilterSelection(filterSelection);

                //create a quick hash of the filter selection
                const hash = filterSelection
                    .map(filter => {
                        return `${filter.name}:${filter.values.join(',')}`;
                    })
                    .join(',');

                setFilterSelectionHash(hash);

                handleFetchProducts(filterSelection, { limit }, true)
                    .then(result => {
                        const productIdToTileSizeNew: { [key: string]: TileSize } = {};
                        if (configService.config()?.theme.tileSize === TileSize.Mixed) {
                            result.items.forEach(product => {
                                if (productIdToTileSize[product.id]) {
                                    productIdToTileSizeNew[product.id] = productIdToTileSize[product.id];
                                } else {
                                    productIdToTileSizeNew[product.id] = getRandomTileSize(
                                        configService.mixedTileSizeOptions,
                                    );
                                }
                            });

                            //Now get any dictionary values that are not in the new dictionary and add them
                            Object.keys(productIdToTileSize).forEach(productId => {
                                if (!productIdToTileSizeNew[productId]) {
                                    productIdToTileSizeNew[productId] = productIdToTileSize[productId];
                                }
                            });

                            setProductIdToTileSize(productIdToTileSizeNew);
                        }

                        setHasMore(result.hasMore);
                        setProducts(result.items);
                    })
                    .catch(() => {
                        // Nothing to do here, we keep the existing products displayed.
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            },
        );

        return () => filterStateObserverSubscription.unsubscribe();
    }, [productIdToTileSize]);

    useEffect(() => {
        menuService.setMenuStyle(MenuStyle.BAR_AND_FILTER_BUTTON);
        let shouldShowFilterMenu = true;

        if (!category || category.isInternalAllCategory) {
            if (configService.shouldUseOnlineSearch) {
                if (filteringService.onlineData) {
                    shouldShowFilterMenu = false;
                }
            }
        }

        menuService.setFilterButtonVisible(shouldShowFilterMenu);
        menuService.setSearchVisible(true);
    }, [menuService]);

    const loadMoreProducts = useCallback(async () => {
        if (!hasMore) {
            return;
        }

        let cursor = _.last(products)?.cursor;

        if (_.isNumber(cursor)) {
            cursor = cursor + 1;
        }
        const options = { limit, cursor };

        mounted(handleFetchProducts(currentFilterSelection, options, true))
            .then(result => {
                console.log('items from query', result.items.length, result.items);
                // const allProducts = products.concat(result.items);
                const allProducts = [...products, ...result.items].filter(
                    (item, index, self) => index === self.findIndex(t => t.id === item.id),
                );

                const productIdToTileSizeNew: { [key: string]: TileSize } = {};
                if (configService.config()?.theme.tileSize === TileSize.Mixed) {
                    allProducts.forEach(product => {
                        if (productIdToTileSize[product.id]) {
                            productIdToTileSizeNew[product.id] = productIdToTileSize[product.id];
                        } else {
                            productIdToTileSizeNew[product.id] = getRandomTileSize(configService.mixedTileSizeOptions);
                        }
                    });

                    //Now get any dictionary values that are not in the new dictionary and add them
                    Object.keys(productIdToTileSize).forEach(productId => {
                        if (!productIdToTileSizeNew[productId]) {
                            productIdToTileSizeNew[productId] = productIdToTileSize[productId];
                        }
                    });
                }
                setProductIdToTileSize(productIdToTileSizeNew);
                setHasMore(result.hasMore);
                setProducts(allProducts);
            })
            .catch(err => {
                Sentry.captureException(err, {
                    extra: {
                        operationName: 'loadMoreProducts',
                    },
                });
                console.log('error in loadMoreProducts', err);
                // Nothing to do here, we keep the existing products displayed.
            });
    }, [currentFilterSelection, handleFetchProducts, hasMore, mounted, products, productIdToTileSize]);

    const items = useMemo(
        (): ResponsiveGridItemWithKey[] => {
            return products.map((product: FilterableProductWithCursor, index: number) => {
                const url = productDetailsUrl(product);
                const originalPrice = priceService.getFromPriceFromProduct(product, true);
                const price = priceService.getFromPriceFromProduct(product);

                const bannerType: BannerType | undefined = getBannerType(product);

                let preferredImage = product.images.find(image => image.preferred);
                if (!preferredImage && product.images.length !== 0) {
                    preferredImage = product.images[0];
                }

                let imageUrl: string | undefined = undefined;
                const imageSizeFloored = Math.floor(getImageTileSize());
                if (preferredImage) {
                    imageUrl = getSizedImageURL(
                        preferredImage.url,
                        imageSizeFloored,
                        imageSizeFloored,
                        configService.imageAnchor,
                    );
                }

                let tileSize: TileSize = configService.defaultTileSize;

                if (tileSize === TileSize.Mixed) {
                    tileSize = productIdToTileSize[product.id];
                }

                return {
                    element: (
                        <ProductCard
                            key={product.id}
                            handle={product.handle}
                            imageUrl={imageUrl}
                            brand={product.vendor}
                            title={product.title}
                            price={price}
                            originalPrice={originalPrice}
                            onClicked={() => {
                                history.push(url);
                            }}
                            bannerType={bannerType}
                            imageWidth={gridSizes.columnSize}
                            metadata={product.metadata}
                            tags={product.tags}
                            discounts={product.discounts}
                        />
                    ),
                    flipId: product.id,
                    size: tileSize,
                };
            });
        },
        [history, priceService, productDetailsUrl, products], //allFilterItems
    );

    const allFilterItems = filteringService.getBreadcrumbFilterItems();
    const filterChips = useMemo(() => {
        if (configService.shouldUseOnlineSearch && filteringService.onlineData) {
            return [];
        }

        const selectedFilterChips = _.compact(
            _.map(allFilterItems, filterSelection => {
                const valuesWithDisplayNames: string[] = [];
                // console.log('filterSelection', filterSelection);
                _.map(filterSelection.values, value => {
                    const displayValue = filteringService.getChipDisplayValue(filterSelection.definitionId, value);

                    // console.log('displayValue', displayValue);
                    if (displayValue) {
                        valuesWithDisplayNames.push(displayValue);
                    }
                });

                const valueString = valuesWithDisplayNames.join(' | ');

                // console.log('valueString', valueString);
                return (
                    <Chip
                        className={'CategoryProducts__ContentWrapper__FilterContainer__Selected__Chip'}
                        renderStyle={'retailer'}
                        key={`filterchip-${filterSelection.name}-${valueString}`}
                        text={valueString}
                        trailing={
                            <div
                                className={'CategoryProducts__ContentWrapper__FilterContainer__Selected__Chip__Chevron'}
                            >
                                <ChevronIcon />
                            </div>
                        }
                        onClick={() => {
                            filteringService.removeAfterSpecificDefinition(filterSelection.mergeDefinitionId);

                            filteringService.commitSelection();
                        }}
                        translate={false}
                        useContrastColor
                    />
                );
            }),
        );

        return selectedFilterChips;
    }, [allFilterItems]);

    const getSubcategoryCount = async (filter: CloudshelfEngineFilter, value: string) => {
        const selection = filteringService.getCurrentSelection();

        const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(filter.id, value);

        for (const childDefinition of mergedChildDefinitions) {
            selection.push({
                mergeDefinitionId: filter.id,
                definitionId: childDefinition.id,
                name: childDefinition.ecommProviderFieldName,
                type: childDefinition.type,
                values: [value],
            });
        }

        selection.push({
            mergeDefinitionId: filter.id,
            definitionId: filter.id,
            name: filter.ecommProviderFieldName,
            type: filter.type,
            values: [value],
        });

        return (
            await filteringService.countMatchingProducts(
                'ProductPage -> getSubcategoryCount',
                category,
                selection,
                false,
            )
        ).toString();
    };

    const subcategoryfilterChips = useMemo(() => {
        if (configService.shouldUseOnlineSearch && filteringService.onlineData) {
            return [];
        }

        if (products.length === 0) {
            return [];
        }
        const getSubcategoryFilter = filteringService.getSubcategoryFilterItem;

        if (!getSubcategoryFilter) {
            return [];
        }

        const attributes = getSubcategoryFilter?.attributeValues ?? [];

        const selectableFilterChips = _.compact(
            _.map(attributes, attr => {
                return (
                    <Chip
                        className={'CategoryProducts__ContentWrapper__FilterContainer__Options__Chip'}
                        renderStyle={'subcategory'}
                        key={`filterchip-${getSubcategoryFilter.displayName}-${attr.value}`}
                        text={`${filteringService.getChipDisplayValue(getSubcategoryFilter.id, attr.value)}`}
                        resetHash={filterSelectionHash}
                        badgeText={() => getSubcategoryCount(getSubcategoryFilter, attr.value)}
                        onClick={() => {
                            const mergedChildDefinitions = filteringService.findChildDefinitionsByParentAndValue(
                                getSubcategoryFilter.id,
                                attr.value,
                            );

                            for (const childDefinition of mergedChildDefinitions) {
                                filteringService.toggleValue(
                                    getSubcategoryFilter.id,
                                    childDefinition.id,
                                    childDefinition.ecommProviderFieldName,
                                    childDefinition.type,
                                    attr.value,
                                );
                            }

                            filteringService.toggleValue(
                                getSubcategoryFilter.id,
                                getSubcategoryFilter.id,
                                getSubcategoryFilter?.ecommProviderFieldName,
                                getSubcategoryFilter.type,
                                attr.value,
                            );

                            filteringService.commitSelection();
                        }}
                        translate={false}
                    />
                );
            }),
        );

        return selectableFilterChips;
    }, [currentFilterSelection, filterSelectionHash, products]);

    const filterChipStyle: CSSProperties = {};

    if (filterChips.length === 0) {
        filterChipStyle.height = '0px';
    }

    useEffect(() => {
        loadMoreProducts()
            .then(() => _.noop())
            .catch();
    }, []);

    return (
        <div className={'CategoryProducts'} ref={pageRef}>
            <div
                id="CategoryProducts__ContentWrapper"
                className={'CategoryProducts__ContentWrapper'}
                style={contentStyles}
            >
                {configService.shouldUseOnlineSearch && isLoading && (
                    <div className="responsiveGrid__InfiniteScroll__loader">
                        <GridLoader />
                    </div>
                )}
                {!category && configService.shouldUseOnlineSearch && !isLoading && !filteringService.onlineData && (
                    <div className="CategoryProducts__ContentWrapper__OnlineResultCounter">
                        {t('onlineNoResultHelp')}
                    </div>
                )}
                {configService.shouldUseOnlineSearch && filteringService.onlineData ? (
                    <div
                        className={`CategoryProducts__ContentWrapper__OnlineResultCounter ${
                            filteringService.onlineData.totalCount === 0
                                ? 'CategoryProducts__ContentWrapper__OnlineResultCounter__AboveSpace'
                                : ''
                        }`}
                    >
                        {t('countOnlineResults', { count: filteringService.onlineData.totalCount })}
                    </div>
                ) : (
                    <div className={'CategoryProducts__ContentWrapper__FilterContainer'}>
                        <div
                            className={'CategoryProducts__ContentWrapper__FilterContainer__Selected'}
                            style={filterChipStyle}
                        >
                            {filterChips}
                        </div>
                        <div className={'CategoryProducts__ContentWrapper__FilterContainer__Options'}>
                            <VirtualizeList
                                heightRule={
                                    subcategoryfilterChips.length === 0
                                        ? '0'
                                        : 'calc(var(--responsive-reference-point) * 0.48)'
                                }
                                elements={subcategoryfilterChips}
                            />
                        </div>
                    </div>
                )}

                <div className={'CategoryProducts__ContentWrapper__GridContainer'} ref={gridParentDivRef}>
                    <ResponsiveGrid
                        additionalClassName="ProductsPage__GridItem"
                        gridSizes={gridSizes}
                        items={items}
                        hasMoreItems={hasMore}
                        onMoreItemsRequested={loadMoreProducts}
                        shouldUseProductAnimations={false}
                        // temp remove all animations to fix speed issues
                        // shouldUseProductAnimations={configService.shouldUseProductAnimations}
                        scrollableTarget="CategoryProducts__ContentWrapper"
                        setRowHeight
                    />
                </div>
            </div>
            <Switch>
                <Route exact path={productDetailsRoute}>
                    <ProductOpenerUtil />
                </Route>
            </Switch>
        </div>
    );
};
