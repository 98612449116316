import { Record, RecordOf } from 'immutable';

type AnalyticsTrackerParams = {
    id: string;
    name: string;
};

export type AnalyticsTracker = RecordOf<AnalyticsTrackerParams>;

export const makeAnalyticsTracker: Record.Factory<AnalyticsTrackerParams> = Record({
    id: '',
    name: '',
});
