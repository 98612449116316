import { CloudshelfEngineConfig } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import { generateGoogleFontsUrl } from './GoogleFonts.Util';

export function configureCloudshelfTheme(config: CloudshelfEngineConfig) {
    //Colours
    document.documentElement.style.setProperty('--primary-color', config.theme.primaryColor);
    document.documentElement.style.setProperty('--primary-contrast-color', config.theme.primaryContrastColor);
    document.documentElement.style.setProperty('--main-text-color', config.theme.mainTextColor);
    document.documentElement.style.setProperty('--purchase-text-color', config.theme.purchaseTextColor);
    document.documentElement.style.setProperty('--sale-new-color', config.theme.saleColour);
    document.documentElement.style.setProperty('--sale-original-color', config.theme.saleOriginalColour);
    document.documentElement.style.setProperty('--attract-loop-font-color', config.theme.attractLoopFontColor);
    document.documentElement.style.setProperty(
        '--attract-loop-background-color',
        config.theme.attractLoopBackgroundColor,
    );

    //Radius
    if (config.theme.radius.tiles === 0) {
        document.documentElement.style.setProperty('--tiles-radius', `${config.theme.radius.tiles}rem`);
    } else {
        document.documentElement.style.setProperty('--tiles-radius', `calc(var(--responsive-reference-point)* 0.15)`);
    }

    if (config.theme.radius.drawer === 0) {
        document.documentElement.style.setProperty('--drawer-radius', `${config.theme.radius.drawer}rem`);
    } else {
        document.documentElement.style.setProperty('--drawer-radius', `calc(var(--responsive-reference-point)* 0.50)`);
    }

    if (config.theme.radius.modal === 0) {
        document.documentElement.style.setProperty('--modal-radius', `${config.theme.radius.modal}rem`);
    } else {
        document.documentElement.style.setProperty('--modal-radius', `calc(var(--responsive-reference-point)* 0.25)`);
    }

    if (config.theme.radius.inputs === 0) {
        document.documentElement.style.setProperty('--input-radius', `${config.theme.radius.inputs}rem`);
    } else {
        document.documentElement.style.setProperty('--input-radius', `calc(var(--responsive-reference-point)* 0.35)`);
    }

    //Fonts
    //Font Links
    let headingFontURL = generateGoogleFontsUrl(
        config.theme.headingsFont.fontFamily,
        config.theme.headingsFont.fontWeight,
    );
    if (config.theme.headingsFont.isCustomFont && config.theme.headingsFont.cdn) {
        headingFontURL = config.theme.headingsFont.cdn;
    }
    document.querySelector('#heading-font')?.setAttribute('href', headingFontURL);

    let subheadingFontURL = generateGoogleFontsUrl(
        config.theme.subheadingsFont.fontFamily,
        config.theme.subheadingsFont.fontWeight,
    );
    if (config.theme.subheadingsFont.isCustomFont && config.theme.subheadingsFont.cdn) {
        subheadingFontURL = config.theme.subheadingsFont.cdn;
    }
    document.querySelector('#subheading-font')?.setAttribute('href', subheadingFontURL);

    let bodyFontURL = generateGoogleFontsUrl(config.theme.bodyFont.fontFamily, config.theme.bodyFont.fontWeight);
    if (config.theme.bodyFont.isCustomFont && config.theme.bodyFont.cdn) {
        bodyFontURL = config.theme.bodyFont.cdn;
    }
    document.querySelector('#body-font')?.setAttribute('href', bodyFontURL);

    // Font CSS
    document.documentElement.style.setProperty('--headings-font', `${config.theme.headingsFont.fontFamily}, serif`);
    document.documentElement.style.setProperty(
        '--subheadings-font',
        `${config.theme.subheadingsFont.fontFamily}, serif`,
    );
    document.documentElement.style.setProperty('--body-font', `${config.theme.bodyFont.fontFamily}, serif`);

    //Font Weights
    document.documentElement.style.setProperty('--headings-font-weight', config.theme.headingsFont.fontWeight);

    document.documentElement.style.setProperty('--subheadings-font-weight', config.theme.subheadingsFont.fontWeight);

    document.documentElement.style.setProperty('--body-font-weight', config.theme.bodyFont.fontWeight);
}
