import { useEffect, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import { PurchaseStep, usePurchase } from '../../../../../hooks/contexts/Purchase/Purchase';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import Modal from '../../../../shared/Modal/Modal';
import ProductVariants from '../ProductDetailsView/buildingBlocks/variants/ProductVariants';
import {
    FilterableProduct,
    FilterableProductImage,
    FilterableProductVariant,
} from '../../../../../services/ProductServices/FilterableProductTypes';
import Button, { ButtonOutlineVariant, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import { TextSize } from '../../../../shared/StyledText/StyledText';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import { useTranslation } from 'react-i18next';
import Quantity from '../Quantity/Quantity';
import { BasketItem } from '../../../../../services/BasketService/Basket.type';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import _ from 'lodash';

export interface VariantSelectorModalProps {
    basketItem?: BasketItem;
    forProduct?: FilterableProduct;
    currentVariant?: FilterableProductVariant;
    onClose?: () => void;
    addToBasket?: (
        product: FilterableProduct,
        variant: FilterableProductVariant,
        quantity: number,
        updateUI: boolean,
    ) => void;
}

export const VariantSelectorModal: FunctionalComponentWithChildren<VariantSelectorModalProps> = props => {
    const [originalSelectedVariant, setOriginalSelectedVariant] = useState<FilterableProductVariant | undefined>(
        undefined,
    );
    const [originalQuantity, setOriginalQuantity] = useState<number | undefined>(undefined);
    const [currentQuantity, setCurrentQuantity] = useState<number | undefined>(undefined);
    const [productForVariantModal, setProductForVariantModal] = useState<FilterableProduct | undefined>(undefined);
    const [selectedVariant, setSelectedVariant] = useState<FilterableProductVariant | undefined>(undefined);
    const [currentBasketItem, setCurrentBasketItem] = useState<BasketItem | undefined>(undefined);
    const [loadingChanges, setLoadingChanges] = useState(false);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const { t } = useTranslation();
    const purchaseContext = usePurchase();
    const productCustomiserPriceModifierVariant = configService.productCustomiserPriceModifierVariant;
    const [productImages, setProductImages] = useState<FilterableProductImage[]>([]);

    useEffect(() => {
        if (props.forProduct) {
            setProductForVariantModal(props.forProduct);
            const images = _.cloneDeep(props.forProduct.images ?? []);
            const preferredImage = _.find(images, image => image.preferred);
            if (preferredImage) {
                _.remove(images, preferredImage);
                images.unshift(preferredImage);
            }

            setProductImages(images);
        }
    }, [props.forProduct]);

    useEffect(() => {
        if (props.currentVariant) {
            setOriginalSelectedVariant(props.currentVariant);
            setSelectedVariant(props.currentVariant);
        }
    }, [props.currentVariant]);

    useEffect(() => {
        if (props.basketItem) {
            setCurrentBasketItem(props.basketItem);
            setCurrentQuantity(props.basketItem.quantity);
            setOriginalQuantity(props.basketItem.quantity);
        } else {
            setCurrentQuantity(1);
        }
    }, [props.basketItem]);

    if (productForVariantModal === undefined) {
        return null;
    }

    const closeModal = () => {
        setProductForVariantModal(undefined);
        setSelectedVariant(undefined);
        setCurrentBasketItem(undefined);
        props.onClose?.();
    };

    const useSalePrice = selectedVariant?.originalPrice !== selectedVariant?.price;

    const handleRemove = async () => {
        console.log('handle remove');
        if (!currentBasketItem || !selectedVariant) {
            return;
        }

        setLoadingChanges(true);
        await basketService.removeItem(currentBasketItem.variant, currentBasketItem.attributes);
        if (productCustomiserPriceModifierVariant) {
            await basketService.removeItem(productCustomiserPriceModifierVariant, currentBasketItem.attributes);
        }
        setLoadingChanges(false);
        closeModal();
    };

    let updateButtonEnabled = false;

    if (selectedVariant) {
        if (currentQuantity !== originalQuantity) {
            console.log('Update quantity', currentQuantity, originalQuantity);
            updateButtonEnabled = true;
        }

        if (selectedVariant.id !== originalSelectedVariant?.id) {
            console.log('Update variant', selectedVariant.id, originalSelectedVariant?.id);
            updateButtonEnabled = true;
        }
    }

    const handleCallCustomAddToBasket = async () => {
        if (!selectedVariant || !currentQuantity) {
            return;
        }
        setLoadingChanges(true);
        props.addToBasket?.(productForVariantModal, selectedVariant, currentQuantity, true);
        closeModal();
        setLoadingChanges(false);
    };

    const handleUpdate = async () => {
        if (!selectedVariant || !currentBasketItem || !originalSelectedVariant || !currentQuantity) {
            return;
        }

        setLoadingChanges(true);

        if (selectedVariant.id === originalSelectedVariant?.id) {
            // Update the quantity if the selected variant is the same
            await basketService.setItemQuantity(
                productForVariantModal,
                selectedVariant,
                currentQuantity,
                currentBasketItem.attributes,
                productForVariantModal?.title ?? selectedVariant.title,
            );
        } else {
            // Remove the original item from the basket
            await basketService.removeItem(originalSelectedVariant, currentBasketItem.attributes);

            // Add the new variant to the basket
            await basketService.setItemQuantity(
                productForVariantModal,
                selectedVariant,
                currentQuantity,
                currentBasketItem.attributes,
                productForVariantModal?.title ?? selectedVariant.title,
            );
        }

        setLoadingChanges(false);
        closeModal();
    };

    return (
        <Modal
            topMost
            isOpen={productForVariantModal !== undefined}
            displayVariant="compact"
            bodyClassName="VariantSelectorModal"
            onClose={closeModal}
            dontChangeMenu
        >
            <PanelHeader
                className={'VariantSelectorModal__Header'}
                onCloseButtonClicked={closeModal}
                title={productForVariantModal.title}
                textSize={TextSize.Medium}
                leftAlignTitle
            />
            <div className={'VariantSelectorModal__Content'}>
                <div className="VariantSelectorModal__Content__Variants">
                    <ProductVariants
                        key="VariantSelectorModal-variants"
                        reactKey="VariantSelectorModal-variants"
                        variants={productForVariantModal?.variants ?? []}
                        originalSelectedVariant={originalSelectedVariant}
                        onVariantSelected={setSelectedVariant}
                        onMatchingPartialVariant={() => setSelectedVariant(undefined)}
                        images={configService.shouldMatchVariantColourImages ? productImages : []}
                    />
                </div>
                <div className="VariantSelectorModal__Content__Quantity">
                    <div className="VariantSelectorModal__Content__Quantity__Text">{t('quantity.title')}</div>
                    <div className="VariantSelectorModal__Content__Quantity__Control">
                        <Quantity
                            className=""
                            initialQuantity={currentQuantity}
                            onQuantityChanged={setCurrentQuantity}
                            onRemove={props.basketItem ? handleRemove : undefined}
                            variant={'inline'}
                            useRemoveIcon
                            loading={loadingChanges}
                            overrideQuantity={currentQuantity}
                            maxQuantity={props.basketItem?.maxQuantity}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className={'VariantSelectorModal__Footer'}>
                <div className="VariantSelectorModal__Footer__Price">
                    {selectedVariant && (
                        <>
                            {useSalePrice ? (
                                <>
                                    <div className="VariantSelectorModal__Footer__Price__CurrentPrice VariantSelectorModal__Footer__Price__Sale">
                                        {CurrencyService.format(selectedVariant.price)}
                                    </div>
                                    <div className="VariantSelectorModal__Footer__Price__OriginalPrice">
                                        {CurrencyService.format(selectedVariant.originalPrice)}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="VariantSelectorModal__Footer__Price__CurrentPrice">
                                        {CurrencyService.format(selectedVariant.price)}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="VariantSelectorModal__Footer__Buttons">
                    <Button
                        variant={ButtonVariant.GREY}
                        outline={ButtonOutlineVariant.PRIMARY}
                        disabled={!productForVariantModal}
                        onClick={async () => {
                            purchaseContext.continue(PurchaseStep.NONE);
                            eventsService.setOpenProduct(productForVariantModal.handle);
                            setProductForVariantModal(undefined);
                        }}
                    >
                        {t('detail')}
                    </Button>
                    {props.addToBasket ? (
                        <Button disabled={false} loading={loadingChanges} onClick={handleCallCustomAddToBasket}>
                            {t('product_view.buy_buttons.add_to_basket')}
                        </Button>
                    ) : (
                        <Button disabled={!updateButtonEnabled} loading={loadingChanges} onClick={handleUpdate}>
                            {t('update')}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};
