import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const OrderIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5298 3.6001C14.3745 3.6001 15.0598 4.2807 15.0598 5.12029V6.64048H16.6759C17.2177 6.64048 17.7086 6.85268 18.1198 7.23272L20.5837 9.68087C20.9662 10.0609 21.1798 10.5771 21.1798 11.1155V14.7482C21.744 14.7482 22.1998 15.2011 22.1998 15.7616C22.1998 16.3222 21.744 16.7751 21.1798 16.7751H20.1598C20.1598 18.4536 18.7892 19.8155 17.0998 19.8155C15.3817 19.8155 14.0398 18.4536 14.0398 16.7751H9.95981C9.95981 18.4536 8.58918 19.8155 6.89981 19.8155C5.18174 19.8155 3.8398 18.4536 3.8398 16.7751H3.3298C2.4848 16.7751 1.7998 16.0942 1.7998 15.2549V5.12029C1.7998 4.2807 2.4848 3.6001 3.3298 3.6001H13.5298ZM15.0598 8.66741V11.7078H19.1398V11.1155L16.6759 8.66741H15.0598ZM6.89981 15.2549C6.05512 15.2549 5.36981 15.9358 5.36981 16.7751C5.36981 17.6144 6.05512 18.2953 6.89981 18.2953C7.74449 18.2953 8.42981 17.6144 8.42981 16.7751C8.42981 15.9358 7.74449 15.2549 6.89981 15.2549ZM17.0998 18.2953C17.9445 18.2953 18.6298 17.6144 18.6298 16.7751C18.6298 15.9358 17.9445 15.2549 17.0998 15.2549C16.2551 15.2549 15.5698 15.9358 15.5698 16.7751C15.5698 17.6144 16.2551 18.2953 17.0998 18.2953Z"
                fill="white"
            />
        </svg>
    );
};
export default OrderIcon;
