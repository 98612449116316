import { ShopifyStore } from './ShopifyStore';

export class ShopifyStoreAssemblerService {
    static assembleShopifyStore(domain: string, storefrontToken: string): ShopifyStore {
        return {
            domain,
            storefrontToken,
        };
    }
}
