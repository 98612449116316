import { FC } from 'react';
import CSServiceScreenBackground from '../../../icons/cs_service_screen_background';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

const BaseServiceScreen: FunctionalComponentWithChildren = props => {
    return (
        <div className={'baseServiceScreen'}>
            <div className={'baseServiceScreen__background'}>
                <CSServiceScreenBackground />
            </div>
            <div className={'baseServiceScreen__children'}>{props.children}</div>

            <img className={'baseServiceScreen__logo'} src="/logos/cloudshelf_logo_secondary.svg" alt="Cloudshelf" />
        </div>
    );
};

export default BaseServiceScreen;
