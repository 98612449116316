import React, { forwardRef, ReactNode } from 'react';
import { Flipped, spring } from 'react-flip-toolkit';
import _ from 'lodash';
import { SpringConfigs } from '../../../../types/SpringConfigs';

export interface ResponsiveGridItemProps {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    flipId?: string;
    useAnimations?: boolean;
}

export const ResponsiveGridItem = forwardRef<HTMLDivElement, ResponsiveGridItemProps>((props, ref) => {
    const onElementAppear = (el: HTMLElement, index: number) =>
        spring({
            onUpdate: val => {
                // if val is number, use it directly, otherwise just use 0
                const safeVal = typeof val === 'number' ? val.toFixed(3) : '0';
                el.style.opacity = safeVal;
                el.style.transform = `scale(${safeVal})`;
                el.style.transformOrigin = 'center';
            },
            delay: _.min([index * 30, 150]),
            config: {
                overshootClamping: true,
                damping: SpringConfigs.appearDamping,
                stiffness: SpringConfigs.appearStiffness,
            },
        });

    const onExit = (el: any, index: any, removeElement: any) => {
        spring({
            onUpdate: (val: any) => {
                el.style.transformOrigin = 'center';
                el.style.transform = `scale(${1 - val})`;
                el.style.opacity = `${1 - val}`;
            },
            delay: _.min([index * 30, 150]),
            onComplete: removeElement,
            config: {
                overshootClamping: true,
                damping: SpringConfigs.exitDamping,
                stiffness: SpringConfigs.exitStiffness,
            },
        });

        return () => {
            el.style.opacity = 0;
            removeElement();
        };
    };

    const getGridItem = () => (
        <div
            className={`ResponsiveGridItem ${props.disabled ? 'ResponsiveGridItem--disabled' : ''} ${props.className}`}
            ref={ref}
        >
            <>{props.children}</>
        </div>
    );

    return (
        <>
            {props.useAnimations ? (
                <Flipped flipId={props.flipId} onAppear={onElementAppear} onExit={onExit} stagger>
                    {getGridItem()}
                </Flipped>
            ) : (
                getGridItem()
            )}
        </>
    );
});

ResponsiveGridItem.defaultProps = {
    useAnimations: true,
};
ResponsiveGridItem.displayName = 'ResponsiveGridItem';
