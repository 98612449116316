import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

export interface PendingCircleProps {
    animate?: boolean;
}

const circleVariants = {
    start: {
        pathLength: 0.98,
        rotate: 0,
    },
    end: {
        pathLength: 0.98,
        rotate: 360,
        transition: {
            duration: 5,
            ease: 'linear',
            repeat: Infinity,
        },
    },
};

const innerCircleVariants = {
    start: {
        pathLength: 0.95,
        rotate: 360,
    },
    end: {
        pathLength: 0.95,
        rotate: 0,
        transition: {
            duration: 3,
            ease: 'linear',
            repeat: Infinity,
        },
    },
};

const PendingCircle: FunctionalComponentWithChildren<PendingCircleProps> = props => {
    if (props.animate) {
        return (
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 170 170"
                fill="none"
                stroke="#FFF"
                strokeWidth={4}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                overflow="inherit"
            >
                <motion.rect
                    width="170"
                    height="170"
                    rx="85"
                    fill="transparent"
                    variants={circleVariants}
                    initial="start"
                    animate="end"
                />
                <motion.circle
                    cx="84.5"
                    cy="85.5"
                    fill="transparent"
                    id="svg_3"
                    r="32.9"
                    variants={innerCircleVariants}
                    initial="start"
                    animate="end"
                />
            </motion.svg>
        );
    }

    return (
        <svg width="170" height="170" xmlns="http://www.w3.org/2000/svg">
            <rect width="170" height="170" rx="85" fill="#7f7f7f" />
            <circle cx="84.5" cy="85.5" fill="#ffffff" id="svg_3" r="32.9" />
        </svg>
    );
};
export default PendingCircle;
