import React from 'react';

export function useComputedStyle(
    ref: React.RefObject<Element>,
    propertyName: keyof CSSStyleDeclaration,
    defaultValue = 0,
): number {
    const { current } = ref;
    if (!current) {
        return defaultValue;
    }
    const styles = getComputedStyle(current);
    if (!styles || !styles[propertyName]) {
        return defaultValue;
    }
    const value = styles[propertyName];
    return typeof value === 'string' && value !== '' ? parseInt(value, 10) : defaultValue;
}
