import { Record, RecordOf } from 'immutable';
import { CurrencyCode } from '../../types/CurrencyCode.enum';

export type PaymentSettingsParams = {
    currencyCode: CurrencyCode;
};

export type StoreSettingsParams = {
    name: string;
    moneyFormat: string;
    paymentSettings: PaymentSettings;
};

export type PaymentSettings = RecordOf<PaymentSettingsParams>;
export type StoreSettings = RecordOf<StoreSettingsParams>;

export interface StoreService {
    fetchStoreSettings(): Promise<StoreSettings>;
}

export const makePaymentSettings: Record.Factory<PaymentSettingsParams> = Record({
    currencyCode: <CurrencyCode>CurrencyCode.Gbp,
});

export const makeStoreSettings: Record.Factory<StoreSettingsParams> = Record({
    name: '',
    moneyFormat: '',
    paymentSettings: makePaymentSettings(),
});
