import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { inject, injectable } from 'inversify';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import DependencyType from '../../dependancyInjection/DependencyType';
import {
    AbortPaymentRequestDocument,
    AbortPaymentRequestMutation,
    AbortPaymentRequestMutationVariables,
    CreatePaidOrderDocument,
    CreatePaidOrderInput,
    CreatePaidOrderMutation,
    CreatePaidOrderMutationFn,
    CreatePaidOrderMutationVariables,
    CreatePaymentAttemptDocument,
    CreatePaymentAttemptQuery,
    CreatePaymentAttemptQueryVariables,
    GetPaymentRequestDocument,
    GetPaymentRequestQuery,
    GetPaymentRequestQueryVariables,
    GetPaymentTokenDocument,
    GetPaymentTokenQuery,
    GetPaymentTokenQueryVariables,
    PaymentGenericPayload,
    StartPaymentRequestDocument,
    StartPaymentRequestMutation,
    StartPaymentRequestMutationVariables,
    ThirdPartyOrder,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { OrderExtraDetails } from '../../utils/OrderAttribute.Utils';

export type CreatePaidOrderPayload = {
    success: boolean;
    message?: string;
    order?: ThirdPartyOrder;
};

export type GetPaymentTokenResponse = {
    success: boolean;
    token?: string;
    message?: string;
};

@injectable()
export class PaymentDemoService {
    constructor(
        @inject(DependencyType.ApolloClient) private readonly apolloClient: ApolloClient<NormalizedCacheObject>,
        @inject(DependencyType.ConfigurationService) private readonly configService: ConfigurationService,
    ) {}
    //

    async getPaymentToken(sessionId: string): Promise<GetPaymentTokenResponse> {
        const { data, errors, error } = await this.apolloClient.query<
            GetPaymentTokenQuery,
            GetPaymentTokenQueryVariables
        >({
            query: GetPaymentTokenDocument,
            variables: {
                organisationId: this.configService.config()?.ownerId ?? '',
                sessionId,
            },
            fetchPolicy: 'no-cache',
        });

        if (!!error || (errors && Array.isArray(errors)) || !data.getPaymentToken || !data.getPaymentToken.success) {
            console.log('errors', errors, error);
            console.log('data', data);
            return {
                success: false,
                token: undefined,
                message: data.getPaymentToken.message ?? 'Failed to get payment token',
            };
        }

        return {
            success: data.getPaymentToken.success,
            token: data.getPaymentToken.token ?? undefined,
            message: data.getPaymentToken.message ?? undefined,
        };
    }

    async createAttemptId(sessionId: string, fulfilmentMethod: string): Promise<PaymentGenericPayload> {
        const { data, errors, error } = await this.apolloClient.query<
            CreatePaymentAttemptQuery,
            CreatePaymentAttemptQueryVariables
        >({
            query: CreatePaymentAttemptDocument,
            variables: {
                organisationId: this.configService.config()?.ownerId ?? '',
                sessionId,
                fulfilmentMethod,
            },
            fetchPolicy: 'no-cache',
        });

        if (
            !!error ||
            (errors && Array.isArray(errors)) ||
            !data.createPaymentAttempt ||
            !data.createPaymentAttempt.success
        ) {
            console.log('errors', errors, error);
            console.log('data', data);
            return {
                success: false,
                extra: data.createPaymentAttempt.extra ?? undefined,
                message: data.createPaymentAttempt.message ?? 'Failed to get payment attempt id',
            };
        }

        return {
            success: data.createPaymentAttempt.success,
            extra: data.createPaymentAttempt.extra ?? undefined,
            message: data.createPaymentAttempt.message ?? undefined,
        };
    }

    async startPaymentRequest(
        sessionId: string,
        attemptId: string,
        token: string,
        amount: number,
    ): Promise<PaymentGenericPayload> {
        console.log('ammount', amount);
        const { data, errors } = await this.apolloClient.mutate<
            StartPaymentRequestMutation,
            StartPaymentRequestMutationVariables
        >({
            mutation: StartPaymentRequestDocument,
            variables: {
                sessionId,
                attemptId,
                amount,
                token,
                organisationId: this.configService.config()?.ownerId ?? '',
            },
            fetchPolicy: 'no-cache',
        });

        if ((errors && Array.isArray(errors)) || !data?.startPaymentRequest || !data.startPaymentRequest.success) {
            return {
                success: false,
                message: data?.startPaymentRequest.message ?? 'Failed to start payment request',
            };
        }

        return {
            success: data.startPaymentRequest.success,
            message: data.startPaymentRequest.message,
        };
    }

    async abortPaymentRequest(sessionId: string, attemptId: string, token: string): Promise<PaymentGenericPayload> {
        const { data, errors } = await this.apolloClient.mutate<
            AbortPaymentRequestMutation,
            AbortPaymentRequestMutationVariables
        >({
            mutation: AbortPaymentRequestDocument,
            variables: {
                sessionId,
                token,
                attemptId,
            },
            fetchPolicy: 'no-cache',
        });

        if ((errors && Array.isArray(errors)) || !data?.abortPaymentRequest || !data.abortPaymentRequest.success) {
            return {
                success: false,
                message: data?.abortPaymentRequest.message ?? 'Failed to abort payment request',
            };
        }

        return {
            success: data.abortPaymentRequest.success,
            message: data.abortPaymentRequest.message,
        };
    }

    async getPaymentRequest(sessionId: string, attemptId: string, token: string): Promise<PaymentGenericPayload> {
        const { data, errors, error } = await this.apolloClient.query<
            GetPaymentRequestQuery,
            GetPaymentRequestQueryVariables
        >({
            query: GetPaymentRequestDocument,
            variables: {
                sessionId,
                attemptId,
                token,
            },
            fetchPolicy: 'no-cache',
        });

        if (!!error || (errors && Array.isArray(errors)) || !data?.getPaymentRequest || !data.getPaymentRequest) {
            return {
                success: false,
                message: data.getPaymentRequest.message ?? 'Failed to get payment request',
            };
        }

        return data.getPaymentRequest;
    }

    async createPaidOrder(
        basketId: string,
        sessionId: string,
        attemptId: string,
        token: string,
        emailAddress: string,
        acquisitionOptionId: string,
        onDevicePaymentId: string | undefined,
        orderExtraDetails: OrderExtraDetails,
    ): Promise<CreatePaidOrderPayload> {
        const input: CreatePaidOrderInput = {
            basketId,
            sessionId,
            attemptId,
            token,
            emailAddress,
            acquisitionOptionId,
            onDevicePaymentId,
            attributes: orderExtraDetails.attributes,
            note: orderExtraDetails.note,
        };

        const { data, errors } = await this.apolloClient.mutate<
            CreatePaidOrderMutation,
            CreatePaidOrderMutationVariables
        >({
            mutation: CreatePaidOrderDocument,
            variables: {
                input,
            },
            fetchPolicy: 'no-cache',
        });

        if ((errors && Array.isArray(errors)) || !data?.createPaidOrder || !data.createPaidOrder.success) {
            return {
                success: false,
                message: data?.createPaidOrder.message ?? 'Failed to create paid order',
            };
        }

        return {
            success: data.createPaidOrder.success,
            message: data.createPaidOrder.message ?? undefined,
            order: data.createPaidOrder.order ?? undefined,
        };
    }
}
