import { FC } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import useStateRef from 'react-usestateref';
import ProductsLoader from '../../../../shared/ProductsLoader/ProductsLoader';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import BaseServiceScreen from '../../../../shared/ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { AttractLoopOrchestratorService } from '../../../../../services/AttractLoopOrchestratorService/AttractLoopOrchestratorService';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const SetupWrapper: FunctionalComponentWithChildren = props => {
    const [setupComplete, setSetupComplete] = useStateRef(false);
    const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(
        DependencyType.AttractLoopOrchestratorService,
    );
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);

    return (
        <>
            {setupComplete ? (
                props.children
            ) : (
                <BaseServiceScreen>
                    <StyledText style={TextStyle.Heading} size={TextSize.Large} className={'SetupWrapper__Text'}>
                        Just a moment
                    </StyledText>
                    <StyledText
                        style={TextStyle.Subheading}
                        size={TextSize.Small}
                        className={`SetupWrapper__Text SetupWrapper__Info`}
                    >
                        Loading products & updates
                    </StyledText>
                    <div style={{ marginTop: '3vh' }}>
                        <ProductsLoader
                            onComplete={() => {
                                attractLoopOrchestratorService
                                    .populateAttractLoop()
                                    .then(() => {
                                        eventsService.loadingComplete();
                                        setSetupComplete(true);
                                    })
                                    .catch(() => {});
                            }}
                            onCachePopulated={() => {}}
                        />
                    </div>
                </BaseServiceScreen>
            )}
        </>
    );
};

export default SetupWrapper;
