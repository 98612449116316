import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_FILTER, NAME_FILTER_ID } from '../../../../provider/cloudshelf/filter/CloudshelfFilters';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { FilterType } from '../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ProductFilteringService } from '../../ProductFilteringService';
import useStateRef from 'react-usestateref';
import './NameFilterInput.scss';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';
import VirtualKeyboardInput from '../../../../components/shared/VirtualKeyboardInput/VirtualKeyboardInput';
import { FilterSelection } from '../../FilterSelection';

export interface INameFilterInputProps {
    initialValue: string;
    onChange: () => void;
    isOnScreen: boolean;
    short?: boolean;
    commitFilters?: boolean;
    minContentLengthForCommit?: number;
    autoFocus?: boolean;
}

export const NameFilterInput: FunctionalComponentWithChildren<INameFilterInputProps> = ({
    initialValue,
    onChange,
    isOnScreen,
    short,
    commitFilters,
    minContentLengthForCommit = 3,
    autoFocus,
}) => {
    const { t } = useTranslation();
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const currentSelection = filteringService.getCurrentSelection();
    const [, setOnScreen, onScreenRef] = useStateRef(false);
    const [currentNameSelection, setCurrentNameSelection, currentNameSelectionRef] = useStateRef<string>(
        _.find(currentSelection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '',
    );

    useEffect(() => {
        setOnScreen(isOnScreen);
    }, [isOnScreen]);

    const getNameSelection = (selection: FilterSelection[]) => {
        const newNameSelection =
            _.find(selection, selection => selection.definitionId === NAME_FILTER_ID)?.values?.[0] ?? '';

        if (currentNameSelectionRef.current !== newNameSelection) {
            setCurrentNameSelection(newNameSelection);
        }
    };

    useEffect(() => {
        const filterViewStateObserver = filteringService.observeFilterViewSelectionState();

        const filterViewStateObserverSubscription = filterViewStateObserver.subscribe(
            async (selection: FilterSelection[]) => {
                getNameSelection(selection);
            },
        );

        return () => filterViewStateObserverSubscription.unsubscribe();
    }, []);

    const handleValueUpdateInternals = (updatedValue: string, forceCommit: boolean) => {
        if (updatedValue === '') {
            filteringService.clearSingleFilter(NAME_FILTER_ID);
        } else {
            filteringService.setStringValue(NAME_FILTER_ID, NAME_FILTER, FilterType.ProductTitle, updatedValue, true);
            // setValue(updatedValue);
        }
        if (commitFilters && (updatedValue.length >= (minContentLengthForCommit ?? 3) || forceCommit)) {
            filteringService.commitSelection();
            console.log('committing');
        }
        onChange();
    };

    const debouncedHandleValueUpdateInternals = _.debounce(handleValueUpdateInternals, 1000);

    const handleValueUpdate = (updatedValue: string, forceCommit: boolean) => {
        debouncedHandleValueUpdateInternals(updatedValue, forceCommit);
    };

    const handleClear = useCallback(() => {
        handleValueUpdate('', true);
        filteringService.clearSearchTerm();
    }, [handleValueUpdate]);

    const handleOnChange = (changedValue: string) => {
        console.log(`handleOnChange in nameFilterInput`, changedValue);
        handleValueUpdate(changedValue, true);
    };

    return (
        <div className="NameFilterInput">
            <div className="NameFilterInput__container">
                <VirtualKeyboardInput
                    name="NameFilterInput"
                    className={`NameFilterInput__input ${!short && 'NameFilterInput__input__expanded'}`}
                    onChange={handleOnChange}
                    placeholder={t('common.search')}
                    type="text"
                    enterKeyHint="done"
                    autoComplete="off"
                    autoCorrect="off"
                    autoFocus={autoFocus}
                    shouldUseSearchIcon={true}
                    onClear={handleClear}
                    initialValue={initialValue}
                    isOnScreen={isOnScreen}
                />
            </div>
        </div>
    );
};
