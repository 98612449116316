import { CheckoutSupportedPaymentMethod } from '../Checkout.Supported.Payment.Method.enum';
import { BaseCheckoutService } from './BaseCheckoutService';
import { PossibleTrackedURL } from '../../TrackedURLService/TrackedURL.type';
import { Basket } from '../../BasketService/Basket.type';
import { Checkout, CheckoutStyle } from '../Checkout.type';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import { LogUtil } from '../../../utils/Logging.Util';
import { CloudshelfTrackedURLService } from '../../TrackedURLService/CloudshelfTrackedURLService';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';
import { reportBasketToCloudshelf } from '../helpers/ReportToCloudshelf';
import { BasketService } from '../../BasketService/BasketService';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageService } from '../../StorageService/StorageService';
import { StorageKey } from '../../StorageService/StorageKeys.enum';
import { dexieDatabase } from '../../StorageService/DexieDatabase';

export type SFCCBasketItem = {
    productId: string;
    quantity: number;
};

export type SFCCBasket = {
    cloudshelfBasketId?: string;
    deviceId?: string;
    cloudshelfId?: string;
    salesAssistantId?: string;
    locationId?: string;
    productItems: SFCCBasketItem[];
};

export class SalesforceB2CBasketCheckoutService extends BaseCheckoutService {
    private existingCloudshelfOrderId: string | undefined = undefined;

    constructor(
        _basketService: BasketService,
        _configurationService: ConfigurationService,
        _storageService: StorageService,
    ) {
        super(_basketService, _configurationService, CheckoutStyle.QR, _storageService);
    }

    clear() {
        super.clear();
        this.existingCloudshelfOrderId = undefined;
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        if (!basket) {
            return;
        }

        let newCheckout: Checkout = {
            loading: true,
            appliedCouponCodes: [],
            subTotal: 0,
            total: 0,
        };
        this.propagateChanges(newCheckout);

        this.existingCloudshelfOrderId =
            (await reportBasketToCloudshelf(this.existingCloudshelfOrderId, undefined, basket)) ?? undefined;

        //add up the price of the basket
        let subTotal = 0;
        let total = 0;
        basket.lineItems.forEach(item => {
            subTotal += item.quantity * item.variant.price;
            total += item.quantity * item.variant.price;
        });

        newCheckout = {
            loading: false,
            appliedCouponCodes: [],
            subTotal: subTotal,
            total: total,
        };
        this.propagateChanges(newCheckout);
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        //We don't know what payment methods are supported by the third party accepting the checkout request
        return [];
    }

    async generateURLForOffloadingQR(fulfilmentMethod: string): Promise<PossibleTrackedURL | undefined> {
        //we need to generate a URL that will be used to offload the QR code

        //todo: get link for Salesforce B2C checkout
        const untrackedCheckoutURL = await this.generateLink(this.basketService.basket);
        if (!untrackedCheckoutURL) {
            return undefined;
        }

        const trackedUrlService = dependenciesContainer.get<CloudshelfTrackedURLService>(
            DependencyType.CloudshelfTrackedURLService,
        );
        const sessionService = dependenciesContainer.get<SessionManagementService>(
            DependencyType.SessionManagementService,
        );
        const trackedURL = await trackedUrlService.generateTrackedURL(
            untrackedCheckoutURL,
            sessionService.sanitizedSessionId,
            fulfilmentMethod,
        );

        if (trackedURL) {
            LogUtil.Log('[SalesforceB2CBasketCheckoutService Checkout Service] Tracked URL: ' + trackedURL.url);

            return {
                url: trackedURL.url,
                isTracked: true,
                id: trackedURL.id,
            };
        } else {
            LogUtil.Log('[SalesforceB2CBasketCheckoutService Checkout Service]Untracked URL: ' + untrackedCheckoutURL);

            return {
                url: untrackedCheckoutURL,
                isTracked: false,
            };
        }
    }

    // {"deviceId":"1234567890","cloudshelfId":"1234567890","salesAssistantId":"1234567890","productItems":[{"productId":"78916783M-2","quantity":1}]}
    async generateLink(basket: Basket | undefined): Promise<string | undefined> {
        if (!basket || basket.lineItems.length === 0) {
            return undefined;
        }

        // https://{{hostnmame}}/on/demandware.store/Sites-{{site}}-Site/en_US/Cloudshelf-CreateBasket?data
        // const domain = `https://zynw-001.dx.commercecloud.salesforce.com/on/demandware.store/Sites-RefArch-Site/en_US/`;
        const configKvps = this.configurationService.providerConfig();
        let domain: string | undefined;
        const domainKvp = configKvps?.find(kvp => kvp.key === 'SALESFORCE_BASKET_URL');

        if (domainKvp && domainKvp.values.length > 0) {
            domain = domainKvp.values[0];
        }

        if (!domain) {
            return undefined;
        }

        if (!domain.endsWith('/')) {
            domain = domain + '/';
        }

        const config = this.configurationService.config();
        const deviceId = config?.device?.id;
        const cloudshelfId = config?.id;

        let salesAssistantId: string | undefined;

        if (this.configurationService.config()?.retailerRules.allocateSalesToAssignedSalesPerson) {
            const storedAssociate = await dexieDatabase().getSalesAssociateId();
            if (storedAssociate) {
                const salesPerson = this.configurationService
                    .config()
                    ?.teamMembers.find(member => member.id === storedAssociate);
                salesAssistantId = salesPerson ? salesPerson.id : undefined;
            }
        }

        let locationId: string | undefined;

        if (this.configurationService.isDevice()) {
            if (this.configurationService.config()?.device?.location) {
                locationId = this.configurationService.config()?.device?.location?.id ?? undefined;
            }
        }

        const sfccBasketPayload: SFCCBasket = {
            cloudshelfBasketId: this.existingCloudshelfOrderId,
            deviceId: deviceId,
            cloudshelfId: cloudshelfId,
            salesAssistantId: salesAssistantId,
            locationId: locationId,
            productItems: [],
        };

        basket.lineItems.forEach(item => {
            const splits = item.variant.eCommercePlatformProvidedId.split('/');

            if (splits.length > 0) {
                const sfccProdId = splits[splits.length - 1];
                sfccBasketPayload.productItems.push({
                    productId: sfccProdId,
                    quantity: item.quantity,
                });
            }
        });

        const payloadAsJson = JSON.stringify(sfccBasketPayload);
        const payloadAsUrlEncoded = encodeURIComponent(payloadAsJson);

        const url = `${domain}Cloudshelf-CreateBasket?data=${payloadAsUrlEncoded}`;

        LogUtil.Log('[SalesforceB2CBasketCheckoutService Checkout Service] Generated URL: ' + url);
        return url;
    }

    async getBasketId(): Promise<string | undefined> {
        return undefined;
    }
}
