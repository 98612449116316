import { FC } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export interface ProductPriceProps {
    originalPriceString?: string;
    priceString?: string;
}

const ProductPrice: FunctionalComponentWithChildren<ProductPriceProps> = props => {
    return (
        <>
            {props.originalPriceString && (
                <h2 className={'ProductPrice__price'}>
                    <StyledText className="ProductPrice__newPrice" style={TextStyle.Heading} size={TextSize.Large}>
                        {props.priceString}
                    </StyledText>
                    <StyledText
                        className="ProductPrice__originalPrice"
                        style={TextStyle.Heading}
                        size={TextSize.Medium}
                    >
                        {props.originalPriceString}
                    </StyledText>
                    {/* <span className={'ProductPrice__newPrice'}>{props.priceString}</span> */}
                    {/* <span className={'ProductPrice__originalPrice'}>{props.originalPriceString}</span> */}
                </h2>
            )}

            {!props.originalPriceString && <h2 className={'ProductPrice__price'}>{props.priceString}</h2>}
        </>
    );
};

export default ProductPrice;
