export enum SessionEventType {
    Started,
    Ended,
}

export interface SessionEvent {
    type: SessionEventType;
    startTime: number;
    endTime?: number;
    duration?: number;
    id: string;
}
