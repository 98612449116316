import ReactGA, { Tracker as GaTracker } from 'react-ga';
import { List } from 'immutable';
import { AnalyticsService } from './AnalyticsService';
import { AnalyticsTracker } from './entities/AnalyticsTracker';
import { AnalyticsEvent } from './entities/AnalyticsEvent';
import { GoogleMetadataService } from './metadata/GoogleMetadataService';

export class GoogleAnalyticsService implements AnalyticsService {
    private readonly _trackerNames: string[];

    private readonly _metadataService: GoogleMetadataService;

    constructor(googleMetadataService: GoogleMetadataService, trackers: List<AnalyticsTracker>) {
        this._metadataService = googleMetadataService;
        this._trackerNames = trackers.map(({ name }) => name).toArray();
        ReactGA.initialize(GoogleAnalyticsService.toGoogleAnalyticsTrackers(trackers));
        this._metadataService.observe().subscribe(metaData => {
            ReactGA.set(metaData);
        });
    }

    recordEvent(event: AnalyticsEvent): void {
        ReactGA.event(event, this._trackerNames);
    }

    recordPageView(page: string): void {
        ReactGA.pageview(`${process.env.PUBLIC_URL}${page}`, this._trackerNames);
    }

    static toGoogleAnalyticsTrackers(trackers: List<AnalyticsTracker>): GaTracker[] {
        return trackers.map(({ id, name }) => ({ trackingId: id, gaConfiguration: { name } })).toArray();
    }
}
