import { DeviceInfo } from '../services/ConfigurationService/DeviceService';
import { CloudshelfPayloadType } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';

export interface CloudshelfInfo {
    deviceNeedsPairing: boolean;
    payloadType: CloudshelfPayloadType;
    cloudshelfOrDeviceId: string;
}

export function getShouldRenderVersionApp(): boolean {
    if (!window || !window.location) {
        throw new Error('Not running in browser window');
    }

    const overrideHostName = process.env.REACT_APP_OVERRIDE_HOSTNAME;
    const hostname = overrideHostName ?? window.location.hostname;
    const hostnameSplits = hostname.split('.');

    if (hostnameSplits.includes('engine-version')) {
        return true;
    }
    return false;
}

export function getPayloadTypeBasedOnURL(): CloudshelfPayloadType {
    if (!window || !window.location) {
        throw new Error('Not running in browser window');
    }

    const overrideHostName = process.env.REACT_APP_OVERRIDE_HOSTNAME;
    const hostname = overrideHostName ?? window.location.hostname;
    const hostnameSplits = hostname.split('.');

    if (hostnameSplits.includes('preview')) {
        return CloudshelfPayloadType.Preview;
    } else if (hostnameSplits.includes('handoff')) {
        return CloudshelfPayloadType.Handoff;
    } else if (hostnameSplits.includes('device')) {
        return CloudshelfPayloadType.Device;
    }

    throw new Error('Unable to determine payload type. Hostname: ' + hostname + ', override: ' + overrideHostName);
}

export function getCloudshelfInfo(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
    if (!window || !window.location) {
        throw new Error('Not running in browser window');
    }

    const payloadType = getPayloadTypeBasedOnURL();
    const path = process.env.REACT_APP_OVERRIDE_PATHNAME ?? `${window.location.pathname}/`;

    const cloudshelfOrDeviceName = path.indexOf('/', 1) === -1 ? path : path.substring(1, path.indexOf('/', 1));

    const shouldForceAllowPairing = payloadType === CloudshelfPayloadType.Device && cloudshelfOrDeviceName === 'new';
    const nameOrId = payloadType === CloudshelfPayloadType.Device ? localDeviceInfo?.id ?? '' : cloudshelfOrDeviceName;

    return {
        deviceNeedsPairing: shouldForceAllowPairing ? true : !localDeviceInfo?.registered ?? false,
        payloadType,
        cloudshelfOrDeviceId: nameOrId,
    };
}

export function useCloudshelfInfo(localDeviceInfo?: DeviceInfo): CloudshelfInfo {
    return getCloudshelfInfo(localDeviceInfo);
}
