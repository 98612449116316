import { injectable } from 'inversify';
import { FilterType } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import {
    CATEGORY_FILTER_ID,
    CLEAR_ALL_FILTERS,
    CLEAR_ALL_FILTERS_ID,
    NAME_FILTER,
    NAME_FILTER_ID,
} from '../../provider/cloudshelf/filter/CloudshelfFilters';
import { List } from 'immutable';
import { Observable, Subject } from 'rxjs';
import { FilterableProduct, FilterableProductWithCursor, SearchResult } from './FilterableProductTypes';
import { ImageTransformInput } from '../../provider/shopify/graphql/generated/shopify_types';
import {
    CloudshelfEngineFilter,
    EngineAttributeValue,
} from '../ConfigurationService/types/filters/CloudshelfEngineFilter';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { Category } from '../CategoryService/entities/Category';
import { getPreselection, getPriceRange, removeHiddenAndMergedFilters } from '../../utils/EngineFilter.Util';
import { SentryUtil } from '../../utils/Sentry.Util';
import { CloudshelfBridge } from '../../utils/CloudshelfBridge.Utils';
import { productWorker } from '../../workers/product/Product.Worker.Instance';
import { FilterSelection } from './FilterSelection';
import { OnlineSearchData } from './OnlineSearchData';
import { ProductsFetchOptions } from './ProductsFetchOptions';

export const RANGE_FILTER_TYPES = [FilterType.Price];

@injectable()
export class ProductFilteringService {
    private _matchingCount = -1;
    private _filterSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _filterViewSelectionSubject: Subject<FilterSelection[]> = new Subject<FilterSelection[]>();
    private _meaningfulChangedSubject: Subject<void> = new Subject<void>();
    private _overrideMeaningfulAttributeValues: {
        filterName: string;
        visibleAttributeValues: EngineAttributeValue[];
    }[] = [];
    private _meaningFULFilters: CloudshelfEngineFilter[] = [];
    private _storedSearchTerm: string | undefined = undefined;
    private _cachedOnlineResults: Map<string, FilterableProduct> = new Map();
    private _onlineSearchData: OnlineSearchData | undefined = undefined;

    constructor(
        private readonly _configService: ConfigurationService,
        private filterSelection: FilterSelection[] = [],
        private filterViewSelection: FilterSelection[] = [],
        private _preselection: FilterSelection[] = [],
    ) {
        this.clearSelection(true);
        this._configService.observe().subscribe(() => {
            // Update the preselection if a new config comes in and we don't have any filters selected
            if (this._preselection.length === 0 && this.filterSelection.length === 0) {
                this.clearSelection(true);
            }
        });
        this._overrideMeaningfulAttributeValues = [];
        this._meaningFULFilters = removeHiddenAndMergedFilters(_configService.config()?.filters ?? []);
    }

    public get onlineData() {
        return this._onlineSearchData;
    }

    public clearSearchTerm() {
        this._storedSearchTerm = undefined;
        this._onlineSearchData = undefined;
    }

    public storeSearchTerm(term: string) {
        this._storedSearchTerm = term;
    }

    public get searchTerm(): string | undefined {
        return this._storedSearchTerm;
    }

    public clearPrice() {
        // Remove price
        this._overrideMeaningfulAttributeValues = _.filter(
            this._overrideMeaningfulAttributeValues,
            v => v.filterName !== 'Price',
        );
    }

    public get searchValue(): string {
        //get the filter from the list that has the id: NAME_FILTER_ID
        const nameFilter = this.filterSelection.find(f => f.definitionId === NAME_FILTER_ID);
        if (!nameFilter) {
            return '';
        }

        return nameFilter.values[0];
    }
    public observeFilterSelectionState(): Observable<FilterSelection[]> {
        return this._filterSelectionSubject.asObservable();
    }

    public observeFilterViewSelectionState(): Observable<FilterSelection[]> {
        return this._filterViewSelectionSubject.asObservable();
    }

    public observeMeaningfulFilterState(): Observable<void> {
        return this._meaningfulChangedSubject.asObservable();
    }

    async findAndSetProductCustomiserPriceModifierVariant() {
        const product = await this.getFilterableProductByHandle('product-customizer-item-customizations');

        this._configService.setProductCustomiserPriceModifierProduct(product);
        if ((product?.variants ?? []).length > 1) {
            this._configService.setProductCustomiserPriceModifierVariant(product?.variants[0]);
        }
    }

    public visibleFilterOptions(): CloudshelfEngineFilter[] {
        return this._meaningFULFilters;
    }

    public get preselection() {
        return _.cloneDeep(this._preselection);
    }

    clearSelection(commit = false) {
        const filters = this._configService.config()?.filters;
        if (filters) {
            this._preselection = getPreselection(filters);
            this.filterViewSelection = this.preselection;
        } else {
            this.filterViewSelection = [];
        }
        this._matchingCount = -1;
        if (commit) {
            this.commitSelection();
        }
        this._meaningFULFilters = removeHiddenAndMergedFilters(this._configService.config()?.filters ?? []);
        this.debounceFilterViewSelectionSubject();
    }

    commitSelection() {
        this.filterSelection = [...this.filterViewSelection];
        this.debounceFilterSelectionSubject();
    }

    clearSingleFilter(definitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        _.remove(currentViewSelection, filter => filter.definitionId === definitionId);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    get getSubcategoryFilterItem(): CloudshelfEngineFilter | undefined {
        const currentSelection = this.filterSelection ?? [];
        const filters = _.orderBy(this._meaningFULFilters ?? [], value => value.priority);

        //Find the first filter that is does not have any values in the currentSelection and has at least 2 values
        const subCategoryFilter = _.find(filters, filter => {
            const isBlacklistedType = _.includes(
                [FilterType.Price, FilterType.StockLevel, FilterType.SortOrder],
                filter.type,
            );

            const isMerged = filter.isMergedChild;

            const matchingSelection = _.find(currentSelection, selection => selection.definitionId === filter.id);
            return (
                !isMerged && !isBlacklistedType && matchingSelection === undefined && filter.attributeValues.length > 1
            );
        });

        return subCategoryFilter;
    }

    async getFilterableProductByHandle(handle: string): Promise<FilterableProduct | undefined> {
        //Always try and use online data if it exists
        const existingCachedValue = this._cachedOnlineResults.get(handle);
        if (existingCachedValue != undefined) {
            return existingCachedValue;
        }

        //offline search
        const results = await this.matchingProducts(
            'getFilterableProductByHandle (offline)',
            null,
            [
                {
                    mergeDefinitionId: '1',
                    definitionId: '1',
                    values: [handle],
                    type: FilterType.ProductHandle,
                    name: 'handle',
                },
            ],
            { limit: 1 },
            false,
            [],
            true,
        );

        if (results.items.length !== 0) {
            return results.items[0];
        }

        return undefined;
    }

    get getSubcategoryFilterSelection(): FilterSelection | undefined {
        const subCategoryFilter = this.getSubcategoryFilterItem;

        if (!subCategoryFilter) {
            return undefined;
        }

        return _.find(
            this.getCurrentSelection(),
            selectedFilter => selectedFilter.definitionId === subCategoryFilter.id,
        );
    }

    get isUsingSubcategoryFilter(): boolean {
        return this.getSubcategoryFilterItem !== undefined;
    }

    getChipDisplayValue(definitionId: string, internalValue: string): string {
        const filters = this._configService.config()?.filters ?? [];

        const matchingFilter = _.find(filters, filter => filter.id === definitionId);
        if (matchingFilter) {
            const matchingValue = _.find(
                matchingFilter.valueOverrides,
                override => override.originalValue === internalValue,
            );

            if (matchingValue && !_.isEmpty(matchingValue.displayValue)) {
                return matchingValue.displayValue;
            }

            return internalValue;
        } else {
            if (definitionId === CATEGORY_FILTER_ID) {
                const categoryByHandle = this._configService.categories.find(c => c.handle === internalValue);
                if (categoryByHandle) {
                    return categoryByHandle.title;
                } else {
                    return internalValue;
                }
            }
        }

        return internalValue;
    }

    findChildDefinitionsByParentAndValue(parentDefinitionId: string, value: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        const childrenOfParent = _.filter(filters, f => f.isMergedChild && f.parentId === parentDefinitionId);

        return _.filter(childrenOfParent, child => _.some(child.attributeValues, av => av.value === value));
    }

    findDefinitions(definitionId: string): CloudshelfEngineFilter[] {
        const filters = this._configService.config()?.filters ?? [];

        return _.filter(filters, filter => filter.id === definitionId);
    }

    toggleValue(mergeDefinitionId: string, definitionId: string, filterName: string, type: FilterType, value: string) {
        let currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                existingFilterSelection.values = _.filter(existingFilterSelection.values, eV => eV !== value);
                if (existingFilterSelection.values.length === 0) {
                    currentViewSelection = _.filter(
                        currentViewSelection,
                        filter => filter.definitionId !== definitionId,
                    );
                }
            } else {
                existingFilterSelection.values = _.concat(existingFilterSelection.values, value);
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    toggleSingleValue(
        mergeDefinitionId: string,
        definitionId: string,
        filterName: string,
        type: FilterType,
        value: string,
    ) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            if (_.includes(existingFilterSelection.values, value)) {
                this.removeSpecificValue(definitionId, filterName, value);
            } else {
                existingFilterSelection.values = [value];
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        } else {
            currentViewSelection.push({
                mergeDefinitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            });
            this.filterViewSelection = currentViewSelection;
            this.debounceFilterViewSelectionSubject();
        }
    }

    setStringValue(definitionId: string, filterName: string, type: FilterType, value: string, pushToFront?: boolean) {
        if (definitionId === NAME_FILTER_ID && filterName === NAME_FILTER && type === FilterType.ProductTitle) {
            if (value.toLowerCase() === 'cs:settings') {
                CloudshelfBridge.exitEngine();
                return;
            }

            if (value.toLowerCase() === 'cs:console') {
                const existingEruda = document.getElementById('eruda');

                if (!existingEruda) {
                    const script = document.createElement('script');
                    script.src = 'https://cdn.jsdelivr.net/npm/eruda';
                    document.body.append(script);
                    script.onload = function () {
                        (window as any).eruda.init();
                    };
                }
                return;
            }

            if (value.toLowerCase() === 'cs:bridge') {
                alert(
                    `Bridge Available: ${CloudshelfBridge.isAvailable()}. Extra: ${JSON.stringify(
                        window.CloudshelfBridge,
                        null,
                        2,
                    )}`,
                );
                return;
            }
        }
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection && existingFilterSelection.values) {
            existingFilterSelection.values = [value];
        } else {
            const newFilter = {
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [value],
            };
            if (pushToFront) {
                currentViewSelection.unshift(newFilter);
            } else {
                currentViewSelection.push(newFilter);
            }
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    setRangeValue(definitionId: string, filterName: string, type: FilterType, min: number, max: number) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );
        if (existingFilterSelection) {
            existingFilterSelection.values = [min.toString(), max.toString()];
        } else {
            currentViewSelection.push({
                mergeDefinitionId: definitionId,
                definitionId,
                name: filterName,
                type,
                values: [min.toString(), max.toString()],
            });
        }
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    refreshViewSelection() {
        this.filterViewSelection = _.cloneDeep(this.filterSelection);
        if (this.filterViewSelection.length === 0 && this.preselection.length > 0) {
            this.filterViewSelection = _.cloneDeep(this.preselection);
        }
        this.debounceFilterViewSelectionSubject();
    }

    getCurrentViewSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterViewSelection);
    }

    getCurrentSelection(): FilterSelection[] {
        return _.cloneDeep(this.filterSelection);
    }

    getSelectedRangeFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => _.includes(RANGE_FILTER_TYPES, filter.type));
    }

    getAllFilterItemsAsSingleValues(): FilterSelection[] {
        const allValueFilterItems = _.chain(this.getSelectedValueFilters())
            .map(filter =>
                _.map(filter.values, val => ({
                    mergeDefinitionId: filter.mergeDefinitionId,
                    definitionId: filter.definitionId,
                    name: filter.name,
                    values: [val],
                    type: filter.type,
                })),
            )
            .flatten()
            .value();
        const allRangeFilterItems = _.chain(this.getSelectedRangeFilters())
            .map(filter => ({
                mergeDefinitionId: filter.mergeDefinitionId,
                definitionId: filter.definitionId,
                name: filter.name,
                values: [getPriceRange(filter.values, 2)],
                type: filter.type,
            }))
            .value();

        return _.concat(
            [
                ...(allRangeFilterItems.length > 0 || allValueFilterItems.length > 0
                    ? [
                          {
                              definitionId: CLEAR_ALL_FILTERS_ID,
                              name: CLEAR_ALL_FILTERS,
                              values: ['Clear All'],
                              mergeDefinitionId: CLEAR_ALL_FILTERS_ID,
                              type: undefined,
                          },
                      ]
                    : []),
            ],
            allValueFilterItems,
            allRangeFilterItems,
        );
    }

    getBreadcrumbFilterItems(): FilterSelection[] {
        const preselection = this.preselection;
        const cloned = this.getCurrentSelection();
        const returnable: FilterSelection[] = [];

        _.map(cloned, filter => {
            if (filter.mergeDefinitionId !== filter.definitionId) {
                return null;
            }

            const isClearAll = filter.name === CLEAR_ALL_FILTERS;
            const isSearch = filter.name === NAME_FILTER;

            if (isClearAll || isSearch) {
                return null;
            }

            const preselectedFilter = _.find(preselection, s => s.definitionId === filter.definitionId);
            if (preselectedFilter) {
                return null;
            }

            returnable.push(filter);
        });

        return returnable;
    }

    getSelectedValueFilters(): FilterSelection[] {
        const cloned = this.getCurrentSelection();
        return _.filter(cloned, filter => !_.includes(RANGE_FILTER_TYPES, filter.type) && filter.values.length > 0);
    }

    removeSpecificValue(definitionId: string, filterName: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelection = _.find(
            currentViewSelection,
            filterSelection => filterSelection.definitionId === definitionId,
        );

        if (existingFilterSelection) {
            if (existingFilterSelection.values?.length === 1 && existingFilterSelection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, existingFilterSelection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                existingFilterSelection.values = _.filter(existingFilterSelection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        }
    }

    removeSpecificValueAndMerged(mergeDefinitionId: string, value: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const existingFilterSelections = _.filter(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        _.map(existingFilterSelections, selection => {
            if (selection.values?.length === 1 && selection.values[0] === value) {
                // Handle single values (e.g. search term) by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else if (_.includes(RANGE_FILTER_TYPES, selection.type)) {
                // Handle range selections by removing filter entirely
                this.clearSingleFilter(selection.definitionId);
            } else {
                // Handle all other cases by only removing the requested value
                selection.values = _.filter(selection.values, val => val !== value);
                this.filterViewSelection = currentViewSelection;
                this.debounceFilterViewSelectionSubject();
            }
        });
    }

    removeAfterSpecificDefinition(mergeDefinitionId: string) {
        const currentViewSelection = this.getCurrentViewSelection();
        const foundIndex = _.findIndex(
            currentViewSelection,
            filterSelection => filterSelection.mergeDefinitionId === mergeDefinitionId,
        );

        currentViewSelection.splice(foundIndex + 1, currentViewSelection.length - foundIndex);
        this.filterViewSelection = currentViewSelection;
        this.debounceFilterViewSelectionSubject();
    }

    private debounceFilterViewSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentViewSelection();
        this._filterViewSelectionSubject.next(selection);
    }, 200);

    private debounceFilterSelectionSubject = _.debounce(() => {
        const selection = this.getCurrentSelection();
        this._filterSelectionSubject.next(selection);
    }, 200);

    get matchingProductCount() {
        return this._matchingCount;
    }

    set matchingProductCount(val: number) {
        this._matchingCount = val;
    }

    static addCategoryFilter(category: Category | undefined | null, filters: FilterSelection[]): FilterSelection[] {
        if (category) {
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: [category.handle],
            });
        }
        return filters;
    }

    static addCategoryFilters(categories: List<Category> | undefined, filters: FilterSelection[]): FilterSelection[] {
        if (categories) {
            const handles = _.map(categories.valueSeq().toArray(), category => category.handle);
            return _.concat(filters, {
                mergeDefinitionId: CATEGORY_FILTER_ID,
                definitionId: CATEGORY_FILTER_ID,
                type: FilterType.CategoryHandle,
                name: 'Category Handle',
                values: handles,
            });
        }
        return filters;
    }

    async countMatchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        setMeaningful: boolean,
    ): Promise<number> {
        const trans = SentryUtil.StartTransaction('FilterService.CountMatching', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const matchingProductsResult = await this.filterProducts(
            filterReason,
            filtersWithCategory,
            { limit: 0 },
            setMeaningful,
        );
        this.matchingProductCount = matchingProductsResult.totalCount;
        SentryUtil.EndSpan(trans.newTransaction);

        return matchingProductsResult.totalCount;
    }

    async matchingProducts(
        filterReason: string,
        category: Category | undefined | null,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
        excludeProductHandles?: string[],
        onlineSearchBypass?: boolean,
    ): Promise<SearchResult<FilterableProductWithCursor>> {
        const trans = SentryUtil.StartTransaction('FilterService.MatchingProducts', false);
        const filtersWithCategory = ProductFilteringService.addCategoryFilter(category, filters);
        const ret = this.filterProducts(
            filterReason,
            filtersWithCategory,
            options,
            setMeaningful,
            excludeProductHandles,
            onlineSearchBypass,
        );
        SentryUtil.EndSpan(trans.newTransaction);

        return ret;
    }

    private async filterProducts(
        filterReason: string,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
        excludeHandles?: string[],
        onlineSearchBypass?: boolean,
    ): Promise<SearchResult<FilterableProductWithCursor>> {
        try {
            const workerResult = await productWorker().filterProducts(
                filterReason,
                filters,
                options,
                setMeaningful,
                excludeHandles,
                onlineSearchBypass,
            );

            if (setMeaningful) {
                this._meaningFULFilters = workerResult.meaningfulFilters;
                this._meaningfulChangedSubject.next();
            }

            if (workerResult.onlineSearchData) {
                this._onlineSearchData = workerResult.onlineSearchData;

                workerResult.result.items.forEach(item => {
                    // Perform some operation on each item
                    this._cachedOnlineResults.set(item.handle, item);
                });
            }

            return workerResult.result;
        } catch (error) {
            console.error('Error filtering products:', error);
            throw error; // Re-throw or handle as needed
        }
    }
}
