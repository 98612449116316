import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { dependenciesContainer } from '../dependancyInjection/DependenciesInitializer';
import { StorageService } from '../services/StorageService/StorageService';
import DependencyType from '../dependancyInjection/DependencyType';
import { StorageKey } from '../services/StorageService/StorageKeys.enum';
import { DeviceInfo } from '../services/ConfigurationService/DeviceService';

export function getDeviceInfo(): DeviceInfo {
    if (!window || !window.location) {
        throw new Error('Not running in browser window');
    }

    const storageService = dependenciesContainer.get<StorageService>(DependencyType.StorageService);
    let valueFromStorage: string | undefined = 'Unknown';

    try {
        const locallyStoredInfo = storageService.get(StorageKey.CLOUDSHELF_DEVICE_INFO, true);
        valueFromStorage = locallyStoredInfo;

        if (locallyStoredInfo) {
            const locallyStoredInfoCast = JSON.parse(locallyStoredInfo) as DeviceInfo;

            if (locallyStoredInfoCast.id && !locallyStoredInfoCast.id.startsWith('gid://')) {
                locallyStoredInfoCast.id = `gid://external/cloudshelfV2Device/${locallyStoredInfoCast.id}`;
            }

            return locallyStoredInfoCast;
        }
    } catch (e) {
        Sentry.captureException(e, {
            extra: {
                operationName: 'getDeviceInfo',
                valueFromStorage: valueFromStorage ?? 'Unknown',
            },
        });
        console.log('Unable to get device info from local storage.', e);

        if (e instanceof SyntaxError) {
            console.log('Removing invalid device info from local storage.');
            storageService.delete(StorageKey.CLOUDSHELF_DEVICE_INFO);
        }
    }

    return {
        id: '',
        registrationCode: '',
        registered: false,
    };
}

function setDeviceInfo(deviceInfo: DeviceInfo) {
    const storageService = dependenciesContainer.get<StorageService>(DependencyType.StorageService);
    const data = JSON.stringify(deviceInfo);
    storageService.put(StorageKey.CLOUDSHELF_DEVICE_INFO, data);
}

export function clearDeviceInfo(): boolean {
    const storageService = dependenciesContainer.get<StorageService>(DependencyType.StorageService);
    const locallyStoredInfo = storageService.get(StorageKey.CLOUDSHELF_DEVICE_INFO, true);

    if (locallyStoredInfo) {
        storageService.delete(StorageKey.CLOUDSHELF_DEVICE_INFO);
        return true;
    }

    return false;
}

export function useDeviceInfo(): [DeviceInfo, (deviceInfo: DeviceInfo) => void] {
    const localInfo = getDeviceInfo();
    const [info, setInfo] = useState(localInfo);
    return [
        info,
        deviceInfo => {
            setDeviceInfo(deviceInfo);
            setInfo(deviceInfo);
        },
    ];
}
