import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeviceInfo } from '../../../../hooks/UseDeviceInfo';
import { useInjection } from '../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../dependancyInjection/DependencyType';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { Routes } from '../../../../services/RoutesService/Routes';
import { DeviceService } from '../../../../services/ConfigurationService/DeviceService';
import { DotWave } from '@uiball/loaders';
import NonResponsiveStyledText, {
    NonResponsiveTextSize,
    NonResponsiveTextStyle,
} from '../../../shared/StyledText/NonResponsiveStyledText';
import BaseServiceScreen from '../../../shared/ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

const PairDeviceComponent: FunctionalComponentWithChildren = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [localDeviceInfo, setDeviceInfo] = useDeviceInfo();
    const [hasDoneRequest, setHasDoneRequest] = React.useState(false);
    const DeviceService = useInjection<DeviceService>(DependencyType.DeviceService);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const remoteDeviceInfo = await DeviceService.getDeviceInfo(localDeviceInfo.id);

                if (!remoteDeviceInfo) {
                    setDeviceInfo({
                        id: '',
                        registrationCode: '',
                        registered: false,
                    });
                    history.replace(Routes.ROOT);
                    return;
                }
                if (remoteDeviceInfo.registered) {
                    setDeviceInfo(remoteDeviceInfo);
                    window.location.pathname = Routes.ROOT;
                } else {
                    setDeviceInfo(remoteDeviceInfo);
                    setHasDoneRequest(true);
                }
            } catch (err: any) {
                if (err.message && _.includes(err.message, 'No device found')) {
                    setDeviceInfo({
                        id: '',
                        registrationCode: '',
                        registered: false,
                    });
                } else {
                    Sentry.captureException(err, {
                        extra: {
                            operationName: 'pairDevice useEffect',
                        },
                    });
                    throw err;
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [localDeviceInfo]);

    return (
        <BaseServiceScreen>
            <div className="PairingApp_title">
                <NonResponsiveStyledText
                    style={NonResponsiveTextStyle.CloudshelfBold}
                    size={NonResponsiveTextSize.Small}
                    center
                    translate
                    className={'PairingApp_white'}
                >
                    Device Pairing Code
                </NonResponsiveStyledText>

                <h1 className="PairingApp_deviceCode">
                    {hasDoneRequest ? localDeviceInfo.registrationCode : <DotWave color="white" size={180} />}
                </h1>
            </div>
        </BaseServiceScreen>
    );
};

export default PairDeviceComponent;
