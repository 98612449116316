import { FC } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

const DisplayOnlyOverlayComponent: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const displayOnlyConfig = configService.displayOnlyConfig;
    return (
        <>
            Collection Type: {displayOnlyConfig.collectionType}
            <br />
            Should display logo: {displayOnlyConfig.displayLogo ? 'Yes' : 'No'}
            <br />
            Max Products Per Collection: {displayOnlyConfig.maxProductsPerCollection}
            <br />
            Max Photos Per Product: {displayOnlyConfig.maxPhotosPerProduct}
            <br />
            Display each photo for (seconds): {displayOnlyConfig.timePerPhoto}
        </>
    );
};

export default DisplayOnlyOverlayComponent;
