export class GlobalIDUtils {
    static stripGlobalID(globalID: string): string {
        return globalID;

        if (globalID.startsWith('gid://cloudshelf/')) {
            return globalID.substring(globalID.lastIndexOf('/') + 1);
        }
        return globalID;
    }

    static rebuildGlobalID(type: string, id: string): string {
        if (id.startsWith('gid://')) {
            return id;
        }
        return `gid://cloudshelf/${type}/${id}`;
    }
}
