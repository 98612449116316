import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const ArrowBackIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg viewBox="0 0 54 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 9L17 27.5L35 46" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export default ArrowBackIcon;
