import { FC, useEffect, useState } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';
import { EventsService } from '../../../../../../../services/EventsService/EventsService';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { Category } from '../../../../../../../services/CategoryService/entities/Category';
import { StorageService } from '../../../../../../../services/StorageService/StorageService';
import { StorageKey } from '../../../../../../../services/StorageService/StorageKeys.enum';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';
import { dexieDatabase } from '../../../../../../../services/StorageService/DexieDatabase';

const InteractiveOverlayComponent: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);

    const [, setRenderKey, renderKeyRef] = useStateRef(0);
    const [openCategory, setOpenCategory] = useState<Category>();
    const [storedAssociate, setStoredAssociate] = useState<string>();

    useEffect(() => {
        const int = setInterval(async () => {
            const storedAssociate = await dexieDatabase().getSalesAssociateId();
            setStoredAssociate(storedAssociate);
        }, 250);

        return () => {
            clearInterval(int);
        };
    }, []);

    useEffect(() => {
        const observer = configService.observe();

        const subscription = observer.subscribe(() => {
            setRenderKey(renderKeyRef.current + 1);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const openCategoryObservable = eventsService.observeOpenCategory();
        const subscriber = openCategoryObservable.subscribe(category => {
            setOpenCategory(category);
        });

        return () => {
            subscriber?.unsubscribe();
        };
    }, []);

    const selectedSalesAssistant = configService.config()?.teamMembers.find(member => member.id === storedAssociate);

    return (
        <>
            Open Category Id: {openCategory ? `${openCategory.id} (${openCategory.title})` : 'None Open'}
            <br />
            Should use product animations: {configService.shouldUseProductAnimations ? 'Yes' : 'No'}
            <br />
            <p>Current Sales Associate: {selectedSalesAssistant ? selectedSalesAssistant.displayName : 'None'}</p>
            <br />
            <p>
                Clearing Rule: {configService.config()?.retailerRules.clearSalesPerson}, Naming Rule:{' '}
                {configService.config()?.retailerRules.salesPersonName}
            </p>
        </>
    );
};

export default InteractiveOverlayComponent;
