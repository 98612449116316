import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import { FC } from 'react';

export interface ProductTitleProps {
    content?: string;
    className?: string;
}

const ProductTitle: FunctionalComponentWithChildren<ProductTitleProps> = props => {
    return (
        <StyledText className={props.className} style={TextStyle.Heading} size={TextSize.Medium} align="end">
            {props.content}
        </StyledText>
    );
};

export default ProductTitle;
