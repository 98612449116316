import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const CalendarIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="47" viewBox="0 0 50 47" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.74412 0H12.0232V3.27893H37.1627V0H40.4418V3.27893H42.6279C46.2499 3.27893 49.186 6.21511 49.186 9.83707V40.4417C49.186 44.0637 46.2499 46.9999 42.6279 46.9999H6.55814C2.93618 46.9999 0 44.0637 0 40.4417V9.83707C0 6.21511 2.93618 3.27893 6.55814 3.27893H8.74412V0ZM37.1627 6.558V9.83721H40.4418V6.558H42.6279C44.4389 6.558 45.907 8.02609 45.907 9.83707V13.1165L3.27907 13.1165V9.83707C3.27907 8.02609 4.74716 6.558 6.55814 6.558H8.74412V9.83721H12.0232V6.558H37.1627ZM3.27907 40.4417V16.3956L45.907 16.3956V40.4417C45.907 42.2527 44.4389 43.7208 42.6279 43.7208H6.55814C4.74716 43.7208 3.27907 42.2527 3.27907 40.4417ZM10.9303 20.7676H7.65122V24.0467H10.9303V20.7676ZM7.65122 28.4186H10.9303V31.6977H7.65122V28.4186ZM10.9303 36.0696H7.65122V39.3487H10.9303V36.0696ZM15.3022 20.7676H18.5813V24.0467H15.3022V20.7676ZM18.5813 28.4186H15.3022V31.6977H18.5813V28.4186ZM15.3022 36.0696H18.5813V39.3487H15.3022V36.0696ZM26.2325 20.7676H22.9534V24.0467H26.2325V20.7676ZM22.9534 28.4186H26.2325V31.6977H22.9534V28.4186ZM26.2325 36.0696H22.9534V39.3487H26.2325V36.0696ZM30.6047 20.7676H33.8837V24.0467H30.6047V20.7676ZM41.5349 20.7676H38.2559V24.0467H41.5349V20.7676ZM30.6047 28.4186H33.8837V31.6977H30.6047V28.4186ZM41.5349 28.4186H38.2559V31.6977H41.5349V28.4186ZM30.6047 36.0696H33.8837V39.3487H30.6047V36.0696ZM41.5349 36.0696H38.2559V39.3487H41.5349V36.0696Z"
                fill="black"
            />
        </svg>
    );
};
export default CalendarIcon;
