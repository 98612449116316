import { FC, useEffect, useRef } from 'react';
import useStateRef from 'react-usestateref';
import { LogUtil } from '../../../utils/Logging.Util';
import { SentryUtil } from '../../../utils/Sentry.Util';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ProductFilteringService } from '../../../services/ProductServices/ProductFilteringService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import StyledText, { TextSize, TextStyle } from '../StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';
import { ProductWorkerProgress } from '../../../workers/product/Product.Worker.Types';
import { FilterType } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import _ from 'lodash';
import { productWorker } from '../../../workers/product/Product.Worker.Instance';

export interface ProductsLoaderProps {
    onCachePopulated?: () => void;
    onComplete: () => void;
    explicitHandle?: string;
    loadLocalCache?: boolean;
}

const ProductsLoader: FunctionalComponentWithChildren<ProductsLoaderProps> = ({
    onCachePopulated,
    onComplete,
    explicitHandle,
    loadLocalCache = true,
}) => {
    const isMounted = useRef(false);
    const [preloadComplete, setPreloadComplete, preloadCompleteRef] = useStateRef(false);
    const [percentComplete, setPercentComplete] = useStateRef(0);
    const [progressTranslationKey, setProgressTranslationKey] = useStateRef('');
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    });

    useEffect(() => {
        setPercentComplete(0);
        configurationService.setReloadConfigBlockedReason('Updating Loki Product Cache');

        const cloudshelfConfig = configurationService.config();
        let includeMetafieldKeys: string[] = [];
        const metafieldFilters = cloudshelfConfig?.filters.filter(f => f.type === FilterType.Metadata);
        const pdpBlocks = cloudshelfConfig?.pdpBlocks ?? [];
        const pdpMetafields = pdpBlocks.filter(b => b.__typename === 'PDPMetadataBlock');

        _.map(metafieldFilters ?? [], f => {
            if (f.metafieldKey) {
                includeMetafieldKeys.push(f.metafieldKey);
            }
        });

        _.map(pdpMetafields ?? [], f => {
            if (f.key) {
                includeMetafieldKeys.push(f.key);
            }
        });

        includeMetafieldKeys = _.uniq(includeMetafieldKeys);

        productWorker()
            .setup(
                configurationService.cloudshelfId!,
                includeMetafieldKeys,
                cloudshelfConfig?.filters ?? [],
                cloudshelfConfig?.useOnlineSearch ?? false,
                cloudshelfConfig?.includeProductsOutOfStock ?? false,
                cloudshelfConfig?.includeProductsLimitedAvailability ?? false,
                cloudshelfConfig?.textSearchDescription ?? false,
                cloudshelfConfig?.textSearchTags ?? false,
                cloudshelfConfig?.textSearchMetadata ?? false,
            )
            .then(() => {
                productWorker()
                    .loadProducts(loadLocalCache, explicitHandle ?? undefined, (progress: ProductWorkerProgress) => {
                        if (isMounted.current) {
                            setPercentComplete(progress.percent);
                            setProgressTranslationKey(progress.translationKey);

                            if (progress.hasValidCache) {
                                if (!preloadCompleteRef.current) {
                                    LogUtil.Log('[Products Loader] Worker Thread reported cache was valid.');
                                    setPreloadComplete(true);
                                    filteringService.findAndSetProductCustomiserPriceModifierVariant();
                                }
                            }
                        }
                    })
                    .then(() => {
                        LogUtil.Log('[Products Loader] Worker Thread reported products have been loaded');
                    })
                    .catch(error => {
                        console.error('Worker error:', error);
                    })
                    .finally(() => {
                        filteringService.findAndSetProductCustomiserPriceModifierVariant();
                        configurationService.setReloadConfigBlockedReason(undefined);
                    });
            })
            .catch(error => {
                console.error('Worker error (Setup):', error);
            });
    }, []);

    useEffect(() => {
        if (preloadComplete) {
            onComplete();
        }
    }, [preloadComplete]);

    return (
        <div className={'ProductsLoader__container'}>
            <div className={'ProductsLoader__bar'}>
                <div className={'ProductsLoader__bar__fill'} style={{ width: `${percentComplete}%` }} />
            </div>
            <StyledText style={TextStyle.Subheading} size={TextSize.Small} className={'ProductsLoader__info'}>
                {progressTranslationKey}
            </StyledText>
        </div>
    );
};

export default ProductsLoader;
