export class IFrameEventUtil {
    public static SESSION_END_EVENT = 'session_end';
    public static SESSION_START_EVENT = 'session_start';
    public static sendIFrameEvent(eventName: string, data?: any): void {
        const fullEventName = `cloudshelf:${eventName}`;

        //check if we should send an event to the parent window
        if (window.parent && window.parent !== window) {
            window.parent.postMessage(
                {
                    eventName: fullEventName,
                    data,
                },
                '*',
            );
        }
    }
}
