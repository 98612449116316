import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
const PlusIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.363525" y="13" width="29" height="3" fill="white" />
            <rect x="13.3635" y="29" width="29" height="3" transform="rotate(-90 13.3635 29)" fill="white" />
        </svg>
    );
};
export default PlusIcon;
