import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ContainerModule } from 'inversify';
import DependencyType from '../../dependancyInjection/DependencyType';
import { buildShopifyApolloClient } from './client/ShopifyApolloClientFactory';
import { ShopifyDependencyTypes } from './di/ShopifyDependencyTypes';
import { ShopifyProductAssemblerService } from './product/ShopifyProductAssemblerService';
import { ShopifyStore } from './store/ShopifyStore';
import { ShopifyStoreAssemblerService } from './store/ShopifyStoreAssemblerService';
import { ShopifyStoreService } from './store/ShopifyStoreService';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import { StoreService } from '../../services/StoreService/StoreService';
import { ConfigurationService } from '../../services/ConfigurationService/ConfigurationService';

const shopifyModule = new ContainerModule(async (bind, unbind, isBound) => {
    const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const configuration = configService.providerConfig();

    let domain: string | undefined;
    let storefrontToken: string | undefined;

    console.info(`[Shopify Module] config`, configuration);

    const domainKvp = configuration.find(kvp => kvp.key === 'domain');
    if (domainKvp) {
        if (domainKvp.values.length > 0) {
            domain = domainKvp.values[0];
        }
    }

    const storefrontTokenKvp = configuration.find(kvp => kvp.key === 'storefrontToken');
    if (storefrontTokenKvp) {
        if (storefrontTokenKvp.values.length > 0) {
            storefrontToken = storefrontTokenKvp.values[0];
        }
    }

    if (domain && storefrontToken) {
        const shopifyStore = ShopifyStoreAssemblerService.assembleShopifyStore(domain, storefrontToken);

        if (!isBound(ShopifyDependencyTypes.ShopifyStore)) {
            bind<ShopifyStore>(ShopifyDependencyTypes.ShopifyStore).toConstantValue(shopifyStore);
        }

        if (!isBound(ShopifyDependencyTypes.ShopifyApolloClient)) {
            bind<ApolloClient<NormalizedCacheObject>>(ShopifyDependencyTypes.ShopifyApolloClient).toConstantValue(
                buildShopifyApolloClient(shopifyStore),
            );
        }

        if (!isBound(ShopifyDependencyTypes.ShopifyProductAssemblerService)) {
            bind<ShopifyProductAssemblerService>(ShopifyDependencyTypes.ShopifyProductAssemblerService)
                .to(ShopifyProductAssemblerService)
                .inSingletonScope();
        }

        if (!isBound(DependencyType.StoreService)) {
            bind<StoreService>(DependencyType.StoreService).to(ShopifyStoreService).inSingletonScope();
        }
    }
});

export default shopifyModule;
