import { injectable } from 'inversify';

@injectable()
export class GeoLocationService {
    private _latitude: number | undefined;
    private _longitude: number | undefined;
    private _lastCheck: Date | undefined;

    async geolocation(): Promise<{ latitude: number; longitude: number } | undefined> {
        if (
            this._lastCheck &&
            this._lastCheck.getTime() > Date.now() - 60000 * 5 &&
            this._latitude &&
            this._longitude
        ) {
            return { latitude: this._latitude, longitude: this._longitude };
        }

        try {
            const response = await fetch('https://js.instantgeo.info/json');
            const result = await response.json();

            if (result && result.longitude && result.latitude) {
                this._latitude = parseFloat(result.latitude);
                this._longitude = parseFloat(result.longitude);
                this._lastCheck = new Date();
                return {
                    latitude: this._latitude,
                    longitude: this._longitude,
                };
            }
        } catch {
            return undefined;
        }
    }
}
