import { inject, injectable, LazyServiceIdentifer } from 'inversify';
import { Observable, Subject } from 'rxjs';
import { SessionEvent, SessionEventType } from './SessionEvent';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getDeviceInfo } from '../../hooks/UseDeviceInfo';
import * as Sentry from '@sentry/react';
import { ProductFilteringService } from '../ProductServices/ProductFilteringService';
import { EventsService } from '../EventsService/EventsService';
import { GeoLocationService } from '../GeoLocationService/GeoLocationService';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { StorageService } from '../StorageService/StorageService';
import { StorageKey } from '../StorageService/StorageKeys.enum';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import {
    ClearSalesAssistantRule,
    CloudshelfPayloadStatus,
    CurrencyCode,
    EndSessionDocument,
    EndSessionMutation,
    EndSessionMutationVariables,
    NewSessionDocument,
    NewSessionMutation,
    NewSessionMutationVariables,
    UpdateSessionDocument,
    UpdateSessionMutation,
    UpdateSessionMutationVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { BasketService } from '../BasketService/BasketService';
import { BasketSessionInfo } from '../BasketService/Basket.type';
import { CheckoutSessionInfo } from '../CheckoutService/Checkout.type';
import { CheckoutService } from '../CheckoutService/CheckoutService';
import { dexieDatabase } from '../StorageService/DexieDatabase';

@injectable()
export class SessionManagementService {
    private _sessionId: string | undefined;

    private _startTimeMs: number | undefined;

    private _interactions = 0;

    private readonly _newSessionSubject = new Subject<SessionEvent>();

    constructor(
        @inject(DependencyType.ProductFilteringService)
        private readonly filteringService: ProductFilteringService,
        @inject(DependencyType.ApolloClient) private readonly apolloClient: ApolloClient<InMemoryCache>,
        @inject(DependencyType.ConfigurationService) private readonly configService: ConfigurationService,
        @inject(DependencyType.EventsService) private readonly eventsService: EventsService,
        @inject(DependencyType.GeoLocationService) private readonly geolocationService: GeoLocationService,
        @inject(DependencyType.StorageService) private readonly storageService: StorageService,
        @inject(DependencyType.CheckoutService)
        private readonly checkoutService: CheckoutService,
        @inject(DependencyType.BasketService) private readonly basketService: BasketService,
    ) {}

    get isSessionActive(): boolean {
        return this._sessionId !== undefined;
    }

    /**
     * Get elapsed time in ms
     */
    get elapsedTime(): number {
        if (!this._sessionId || !this._startTimeMs) {
            return 0;
        }

        return Date.now() - this._startTimeMs;
    }

    /**
     * Get current session id
     */
    get currentSessionId(): string | undefined {
        return this._sessionId;
    }

    get sanitizedSessionId(): string | undefined {
        if (this._sessionId === '.' || this._sessionId === 'cached' || this._sessionId === 'preview') {
            return undefined;
        }

        return this._sessionId;
    }

    /**
     * Start new session and return new session id
     */
    async newSession(): Promise<string> {
        console.log('NEW SESSION CALLED!');
        if (this._sessionId) {
            return this._sessionId;
        }

        if (
            this.configService.status() === CloudshelfPayloadStatus.DeviceWithCloudshelf ||
            this.configService.status() === CloudshelfPayloadStatus.Cached ||
            this.configService.status() === CloudshelfPayloadStatus.CloudshelfPreview
        ) {
            if (this.configService.status() === CloudshelfPayloadStatus.Cached) {
                this._sessionId = 'cached';
                this._startTimeMs = Date.now();
                await dexieDatabase().putStoredSessionId(this._sessionId);

                this._newSessionSubject.next({
                    type: SessionEventType.Started,
                    startTime: this._startTimeMs,
                    id: this._sessionId!,
                });
                return 'cached';
            } else if (this.configService.status() === CloudshelfPayloadStatus.CloudshelfPreview) {
                this._sessionId = 'preview';
                this._startTimeMs = Date.now();
                await dexieDatabase().putStoredSessionId(this._sessionId);

                this._newSessionSubject.next({
                    type: SessionEventType.Started,
                    startTime: this._startTimeMs,
                    id: this._sessionId!,
                });
                return 'preview';
            }

            try {
                this._sessionId = '.'; // Prevents calls to newSession while the mutation is running
                this._interactions = 1;
                const deviceInfo = getDeviceInfo();
                const location = await this.geolocationService.geolocation();
                const salesAssistantId = await dexieDatabase().getSalesAssociateId();

                const mutationResult = await this.apolloClient.mutate<NewSessionMutation, NewSessionMutationVariables>({
                    mutation: NewSessionDocument,
                    variables: {
                        deviceId: deviceInfo.id,
                        lat: location?.latitude,
                        long: location?.longitude,
                        salesAssistantId,
                    },
                });

                const newSessionId = mutationResult.data?.newSession.id;
                if (!newSessionId) {
                    this._sessionId = undefined;
                    await dexieDatabase().deleteStoredSessionId();
                    return '';
                }
                this._sessionId = newSessionId;
                this._startTimeMs = Date.now();
                if (this._sessionId) {
                    await dexieDatabase().putStoredSessionId(this._sessionId);
                }
                console.log('NEW SESSION ID', this._sessionId);
                this._newSessionSubject.next({
                    type: SessionEventType.Started,
                    startTime: this._startTimeMs,
                    id: this._sessionId!,
                });

                return this._sessionId!;
            } catch (e) {
                return '';
            }
        } else {
            // If not a device with cloudshelf, don't report sessions
            return '';
        }
    }

    /**
     * End session and return elapsed time in ms
     */
    async endSession(): Promise<number> {
        this.checkoutService.clear();
        this.basketService.empty();
        this.eventsService.setOpenCategory(undefined);
        this.filteringService.clearSelection(true);
        this.filteringService.clearSearchTerm();
        await dexieDatabase().deleteStoredSessionId();

        if (this.configService.config()?.retailerRules.clearSalesPerson === ClearSalesAssistantRule.SessionEnd) {
            await dexieDatabase().deleteSalesAssociateId();
        }

        this.storageService.delete(StorageKey.VIRTUAL_KEYBOARD_LOCATION);

        if (!this._sessionId || !this._startTimeMs) {
            this._newSessionSubject.next({
                type: SessionEventType.Ended,
                startTime: 0,
                endTime: 0,
                duration: 0,
                id: 'cached',
            });
            return 0;
        }

        const elapsed = this.elapsedTime;

        if (this._sessionId !== 'cached' && this._sessionId !== 'preview') {
            try {
                await this.apolloClient.mutate<EndSessionMutation, EndSessionMutationVariables>({
                    mutation: EndSessionDocument,
                    variables: {
                        id: this._sessionId,
                        interactions: this._interactions,
                    },
                });
            } catch (e) {
                Sentry.captureException(e, {
                    extra: {
                        operationName: 'EndSession',
                    },
                });
            }
        }

        this._newSessionSubject.next({
            type: SessionEventType.Ended,
            startTime: this._startTimeMs,
            endTime: Date.now(),
            duration: elapsed,
            id: this._sessionId,
        });

        this._sessionId = undefined;
        this._startTimeMs = undefined;
        this._interactions = 0;

        return elapsed;
    }

    async updateSession(): Promise<void> {
        if (!this._sessionId || !this._startTimeMs) {
            return;
        }

        if (this._sessionId !== 'cached' && this._sessionId !== 'preview') {
            const salesAssistantId = await dexieDatabase().getSalesAssociateId();

            let basketSessionInfo: BasketSessionInfo = {
                // price: 0,
                currencyCode: CurrencyCode.Unknown,
                addedToBasket: false,
            };

            try {
                const basketService = dependenciesContainer.get<BasketService>(DependencyType.BasketService);
                basketSessionInfo = basketService.sessionInfo();
            } catch {
                //We have to wait for the basket service to be initialized, so we will just update it next time
            }

            let checkoutSessionInfo: CheckoutSessionInfo = {
                price: 0,
            };

            try {
                const checkoutService = dependenciesContainer.get<CheckoutService>(DependencyType.CheckoutService);
                checkoutSessionInfo = checkoutService.sessionInfo();
            } catch {
                //We have to wait for the basket service to be initialized, so we will just update it next time
            }

            //Fix for broken store sessionImplementation
            checkoutSessionInfo.price = parseFloat(checkoutSessionInfo.price.toString());

            try {
                await this.apolloClient.mutate<UpdateSessionMutation, UpdateSessionMutationVariables>({
                    mutation: UpdateSessionDocument,
                    variables: {
                        id: this._sessionId,
                        interactions: this._interactions,
                        basketCurrencyCode: basketSessionInfo.currencyCode as CurrencyCode,
                        basketValue: checkoutSessionInfo.price,
                        addedToBasket: basketSessionInfo.addedToBasket,
                        salesAssistantId,
                    },
                });
            } catch (e) {
                Sentry.captureException(e, {
                    extra: {
                        operationName: 'UpdateSession',
                    },
                });
            }
        }
    }

    onInteract(): void {
        this._interactions++;
    }

    interactionCount(): number {
        return this._interactions;
    }

    observe(): Observable<SessionEvent> {
        return this._newSessionSubject.asObservable();
    }
}
