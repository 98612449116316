import { TileSize } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';

export function getRandomTileSize(options: {
    includeSquare: boolean;
    includeHero: boolean;
    includeTall: boolean;
    includeWide: boolean;
}): TileSize {
    const enumValues = Object.values(TileSize);

    // Generate a random index
    const randomIndex = Math.floor(Math.random() * enumValues.length);

    // Retrieve the random value
    const tileSize = enumValues[randomIndex];

    // Check if the random value is allowed, and if not return TileSize.Square
    if (
        (tileSize === TileSize.Square && !options.includeSquare) ||
        (tileSize === TileSize.Hero && !options.includeHero) ||
        (tileSize === TileSize.Tall && !options.includeTall) ||
        (tileSize === TileSize.Wide && !options.includeWide)
    ) {
        //What if they dont want square?
        return TileSize.Square;
    }

    return tileSize;
}
