import { CloudshelfEngineFilter } from '../../services/ConfigurationService/types/filters/CloudshelfEngineFilter';
import { FilterSelection } from '../../services/ProductServices/FilterSelection';
import { ProductsFetchOptions } from '../../services/ProductServices/ProductsFetchOptions';
import {
    ProductWorkerProgress,
    ProductWorkerRequest,
    ProductWorkerResponse,
    ProgressCallback,
} from './Product.Worker.Types';

export class ProductWorkerWrapper {
    private worker: Worker;
    private taskId = 0;
    private promises: Record<number, { resolve: (value: any) => void; reject: (reason: any) => void }> = {};
    private progressCallbacks: Record<number, ProgressCallback | null> = {};

    constructor() {
        this.worker = new Worker(
            new URL(/*webpackChunkName: "ProductWorkerCIRC"*/ './Product.Worker.Thread.ts', import.meta.url),
        );

        this.worker.onmessage = (e: MessageEvent<any>) => {
            const { id, result, progress, error } = e.data;

            if (progress !== undefined) {
                const typedProgress: ProductWorkerProgress = progress as ProductWorkerProgress;
                // Call the progress callback if available
                const callback = this.progressCallbacks[id];
                if (callback) {
                    callback(typedProgress);
                }
                return; // Don't resolve the promise yet, just handle progress
            }

            if (error) {
                this.promises[id].reject(new Error(error));
            } else {
                this.promises[id].resolve(result);
            }
            delete this.promises[id]; // Clean up
            delete this.progressCallbacks[id];
        };

        this.worker.onerror = error => {
            console.error('Worker error:', error);
        };
    }

    setup(
        cloudshelfId: string,
        metadataKeys: string[],
        filtersFromConfig: CloudshelfEngineFilter[],
        shouldUseOnlineSearch: boolean,
        includeProductsOutOfStock: boolean,
        includeProductsLimitedAvailability: boolean,
        textSearchDescription: boolean,
        textSearchTags: boolean,
        textSearchMetadata: boolean,
    ) {
        const request: ProductWorkerRequest = {
            action: 'setup' as const,
            params: {
                cloudshelfId,
                includeMetadataKeys: metadataKeys,
                includeMetadataPartialKeys: ['product_customizer', 'bookthatapp'],
                filtersFromConfig,
                shouldUseOnlineSearch,
                includeProductsOutOfStock,
                includeProductsLimitedAvailability,
                textSearchDescription,
                textSearchTags,
                textSearchMetadata,
            },
        };

        return new Promise((resolve, reject) => {
            const id = this.taskId++;

            this.promises[id] = { resolve, reject };
            this.progressCallbacks[id] = null;

            this.worker.postMessage({ id, request });
        });
    }

    loadProducts(
        loadLocalCache: boolean,
        explicitProductHandle: string | undefined,
        progressCallback: ProgressCallback,
    ): Promise<ProductWorkerResponse<'loadProducts'>> {
        const request: ProductWorkerRequest = {
            action: 'loadProducts' as const,
            params: {
                loadLocalCache,
                explicitProductHandle,
            },
        };

        return new Promise((resolve, reject) => {
            const id = this.taskId++;

            this.promises[id] = { resolve, reject };
            this.progressCallbacks[id] = progressCallback || null;

            this.worker.postMessage({ id, request });
        });
    }

    filterProducts(
        filterReason: string,
        filters: FilterSelection[],
        options: ProductsFetchOptions,
        setMeaningful: boolean,
        excludeHandles?: string[],
        onlineSearchBypass?: boolean,
    ): Promise<ProductWorkerResponse<'filterProducts'>> {
        const request: ProductWorkerRequest = {
            action: 'filterProducts' as const,
            params: {
                filterReason,
                filters,
                options,
                setMeaningful,
                excludeHandles,
                onlineSearchBypass,
            },
        };

        return new Promise((resolve, reject) => {
            const id = this.taskId++;

            this.promises[id] = { resolve, reject };
            this.progressCallbacks[id] = null;

            this.worker.postMessage({ id, request });
        });
    }

    // Optionally: clean up the worker
    terminate() {
        this.worker.terminate();
    }
}
