import React, { ReactElement } from 'react';
import { ProductCustomiserField, ProductCustomiserSelectOption } from '../ProductCustomiserSection';
import { ButtonSize } from '../../../../../../../shared/inputs/Button/Button';
import ButtonSelectable from '../../../../../../../shared/inputs/ButtonSelectable/ButtonSelectable';
import { useInjection } from '../../../../../../../../dependancyInjection/DependencyContext';
import PriceService from '../../../../../../../../services/PriceService/PriceService';
import DependencyType from '../../../../../../../../dependancyInjection/DependencyType';
import StyledText, { TextSize, TextStyle } from '../../../../../../../shared/StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../../../../../../FCWithChildren';

export interface PCSelectProps {
    field: ProductCustomiserField;
    onChange: (value: string, price: number) => void;
    errorText?: string;
}

const PCSelect: FunctionalComponentWithChildren<PCSelectProps> = props => {
    const priceService = useInjection<PriceService>(DependencyType.PriceService);
    const [value, setValue] = React.useState<ProductCustomiserSelectOption>();
    const { field, onChange, errorText } = props;

    const handleChange = (val: ProductCustomiserSelectOption) => {
        if (value?.label === val.label && value?.price === val.price && value?.color === val.color) {
            setValue(undefined);
            onChange('', 0);
        } else {
            setValue(val);
            onChange(val.label, val.price);
        }
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {field.options.map((option, index) => {
                    const isSelected =
                        option.label === value?.label && option.price === value?.price && option.color === value?.color;

                    let swatch: ReactElement | null = null;
                    if (option.color) {
                        swatch = <div className="SelectField__swatch" style={{ backgroundColor: option.color }} />;
                    }

                    return (
                        <ButtonSelectable
                            size={ButtonSize.SM}
                            className="SelectField__option"
                            forId={`ProductCustomiserField-${field.option_id}-${index}`}
                            name={`ProductCustomiserField-${field.option_id}-${index}`}
                            // label={`${option.label} +${priceService.convertLowestDenominationToFull(option.price)}`}
                            imageLabel={null}
                            label={option.label}
                            type="checkbox"
                            value={option.label}
                            onSelected={() => handleChange(option)}
                            selected={isSelected}
                            outlined
                            icon={swatch}
                            suffix={
                                option.price !== 0 ? (
                                    <StyledText
                                        size={TextSize.Small}
                                        style={TextStyle.Body}
                                        noWrap
                                        className={`SelectField__option__price ${
                                            isSelected ? 'SelectField__option__price--selected' : ''
                                        }`}
                                    >
                                        (+{priceService.convertLowestDenominationToFull(option.price)})
                                    </StyledText>
                                ) : null
                            }
                        />
                    );
                })}
            </div>
            {errorText && (
                <div>
                    <span className="TextInput__errorText WeglotTranslate">
                        <i className="fa-solid fa-triangle-exclamation" />
                        {props.errorText}
                    </span>
                </div>
            )}
        </>
    );
};

export default PCSelect;
