import React, { FC, memo, useEffect, useState } from 'react';
import GlassModal from '../GlassModal/GlassModal';
import PendingCircle from '../../icons/pending_circle';
import PurchaseTick from '../../icons/purchase_tick';
import ErrorCircle from '../../icons/error_circle';
import { AnimatePresence } from 'framer-motion';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface PromiseModalProps {
    promise?: Promise<any>;
    round?: boolean;
    finallyRemoveAfter?: number;
}

const PromiseModal: FunctionalComponentWithChildren<PromiseModalProps> = memo(props => {
    const [currentPromise, setCurrentPromise] = useState<Promise<any> | undefined>(undefined);
    const [currentState, setCurrentState] = useState<0 | 1 | 2>(0);

    useEffect(() => {
        if (props.promise) {
            setCurrentPromise(props.promise);
            setCurrentState(0);
            props.promise
                .then(() => {
                    console.log('Promise Modal Resolved');
                    setCurrentState(1);
                })
                .catch(() => {
                    console.log('Promise Modal Rejected');
                    setCurrentState(2);
                })
                .finally(() => {
                    setTimeout(() => {
                        setCurrentPromise(undefined);
                    }, props.finallyRemoveAfter ?? 2500);
                });
        } else {
            setCurrentPromise(undefined);
            setCurrentState(0);
        }
    }, [props.promise]);

    return (
        <AnimatePresence>
            {currentPromise && (
                <GlassModal round={props.round} animate>
                    {currentState === 0 && <PendingCircle animate={true} />}
                    {currentState === 1 && <PurchaseTick animate={true} useBrandPurchaseColor={true} />}
                    {currentState === 2 && <ErrorCircle animate={true} />}
                </GlassModal>
            )}
        </AnimatePresence>
    );
});

export default PromiseModal;
