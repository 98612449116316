import { createElement, CSSProperties, FC } from 'react';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export enum TextStyle {
    Cloudshelf = 'Cloudshelf',
    CloudshelfBold = 'CloudshelfBold',
    Body = 'Body',
    Subheading = 'Subheading',
    Heading = 'Heading',
}

export enum TextSize {
    ExtraSmall = 'ExtraSmall',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    Hero = 'Hero',
}

export interface StyledTextProps {
    style?: TextStyle;
    size?: TextSize;
    className?: string;
    translate?: boolean;
    fontSize?: number;
    noWrap?: boolean;
    superNeverTranslate?: boolean;
    align?: 'left' | 'center' | 'right' | 'start' | 'end';
    useContrastColor?: boolean;
}

const StyledText: FunctionalComponentWithChildren<StyledTextProps> = ({
    style = TextStyle.Body,
    size = TextSize.Small,
    translate = false,
    className,
    fontSize,
    noWrap,
    superNeverTranslate,
    align,
    children,
    useContrastColor = false,
}) => {
    const fontStyle: CSSProperties = {
        fontSize: fontSize + 'px',
        lineHeight: fontSize + 'px',
        whiteSpace: noWrap ? 'nowrap' : 'normal',
    };

    return createElement(
        style === TextStyle.Heading ? 'h1' : 'p',
        {
            className: `StyledText__${style} StyledText__${size} ${className} ${
                translate ? 'WeglotTranslate' : 'NeverTranslate'
            } ${superNeverTranslate && 'SuperNeverTranslate'} ${align ? `StyledText__Align__${align}` : ''} ${
                useContrastColor && 'StyledText__ContrastColor'
            }`,
            style: fontStyle,
        },
        children,
    );
};

export default StyledText;
