import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
const BinIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3359 10H9.24839L9.33777 12.0856L10.7608 45.2898C10.9695 50.1595 14.9782 54 19.8523 54H38.8195C43.6937 54 47.7024 50.1595 47.9111 45.2898L49.3341 12.0856L49.4235 10H47.3359H11.3359Z"
                stroke="#000000"
                stroke-width="4"
            />
            <path
                d="M36.6719 10L38.6719 10L38.6719 8L38.6719 7.54993C38.6719 4.48479 36.1871 2 33.1219 2L25.5551 2C22.49 2 20.0052 4.48479 20.0052 7.54993L20.0052 8L20.0052 10L22.0052 10L36.6719 10Z"
                stroke="#000000"
                stroke-width="4"
            />
            <rect x="0.671875" y="8" width="57.3333" height="4" rx="2" fill="#000000" />
            <rect x="27.3359" y="20" width="4" height="25.3333" rx="2" fill="#000000" />
            <rect x="19.3359" y="20" width="4" height="25.3333" rx="2" fill="#000000" />
            <rect x="35.3359" y="20" width="4" height="25.3333" rx="2" fill="#000000" />
        </svg>
    );
};
export default BinIcon;
