import { inject, injectable } from 'inversify';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ShopifyDependencyTypes } from '../di/ShopifyDependencyTypes';
import {
    ShopifyCloudshelfDocument,
    ShopifyCloudshelfQuery,
    ShopifyCloudshelfQueryVariables,
} from '../graphql/generated/shopify_types';
import * as Sentry from '@sentry/react';
import {
    makePaymentSettings,
    makeStoreSettings,
    StoreService,
    StoreSettings,
} from '../../../services/StoreService/StoreService';
import { CurrencyCode } from '../../../types/CurrencyCode.enum';

@injectable()
export class ShopifyStoreService implements StoreService {
    constructor(
        @inject(ShopifyDependencyTypes.ShopifyApolloClient)
        private readonly client: ApolloClient<NormalizedCacheObject>,
    ) {}

    async fetchStoreSettings(): Promise<StoreSettings> {
        try {
            const { data } = await this.client.query<ShopifyCloudshelfQuery, ShopifyCloudshelfQueryVariables>({
                query: ShopifyCloudshelfDocument,
                fetchPolicy: 'cache-first',
            });

            const shopifyCurrencyCode = data.shop.paymentSettings.currencyCode as unknown;
            const paymentSettings = makePaymentSettings({
                currencyCode: shopifyCurrencyCode as CurrencyCode,
            });

            return makeStoreSettings({
                name: data.shop.name,
                moneyFormat: data.shop.moneyFormat,
                paymentSettings,
            });
        } catch (e) {
            // Fetching settings failed. Uee default currency code.
            Sentry.captureException(e, {
                extra: {
                    operationName: 'fetchStoreSettings',
                },
            });
            return makeStoreSettings();
        }
    }
}
