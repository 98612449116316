import React, { FC, useEffect } from 'react';
import { useScript } from '../../../hooks/UseScript';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { KnownPlugin } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import KeepQuietErrorBoundary from '../KeepQuietErrorBoundry';
import i18n from '../../../services/I18n/I18n';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export const Weglot: FunctionalComponentWithChildren = props => {
    return (
        <KeepQuietErrorBoundary>
            <WeglotInternals />
        </KeepQuietErrorBoundary>
    );
};

const WeglotInternals: FunctionalComponentWithChildren = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const i18Lang = i18n.language;
    const browserLanguage = i18Lang.split('-')[0];

    const weglotScriptStatus = useScript(
        'https://cdn.weglot.com/weglot.min.js',
        {
            onError: () => {
                console.error('[Weglot Plugin] Error loading');
            },
            onLoaded: () => {
                console.info('[Weglot Plugin] Weglot.min.js loaded');
            },
        },
        {
            async: true,
            type: 'text/javascript',
        },
    );

    useEffect(() => {
        if (weglotScriptStatus === 'ready') {
            startWeglot();
        }
    }, [weglotScriptStatus]);

    const startWeglot = () => {
        const anyWindow = window as any;
        let apiKeyToUse: string | undefined;
        let fallbackToUse: string | undefined;
        let supportedLangs: string[] = [];

        const whitelist: { value: string }[] = [];
        const excluded_blocks: { value: string }[] = [
            {
                value: '.SuperNeverTranslate',
            },
        ];
        const dynamics: { value: string }[] = [
            {
                value: '.WeglotTranslate',
            },
            {
                value: '.NeverTranslate',
            },
        ];

        const weglotPluginConfig = configService.getKnownPlugin(KnownPlugin.ThirdPartyWeglot);
        if (weglotPluginConfig) {
            const weglotAPIKey = configService.getKnownPluginVariable(KnownPlugin.ThirdPartyWeglot, 'WEGLOT_API_KEY');
            const weglotSupportedLang = configService.getKnownPluginVariable(
                KnownPlugin.ThirdPartyWeglot,
                'WEGLOT_SUPPORTED_LANG',
            );
            const weglotFallbackLang = configService.getKnownPluginVariable(
                KnownPlugin.ThirdPartyWeglot,
                'WEGLOT_FALLBACK_LANG',
            );

            const weglotSupportedLangs = weglotSupportedLang ? weglotSupportedLang?.split('|') ?? [] : [];

            if (
                weglotAPIKey !== null &&
                weglotFallbackLang !== null &&
                weglotSupportedLang &&
                weglotSupportedLangs.length > 0
            ) {
                apiKeyToUse = weglotAPIKey;
                fallbackToUse = weglotFallbackLang;
                supportedLangs = weglotSupportedLangs;

                let currentWgLang = window.localStorage.getItem('wglang');

                if (currentWgLang !== browserLanguage || currentWgLang === null) {
                    if (supportedLangs.includes(browserLanguage)) {
                        console.warn(
                            'wg lang was not the same as i18n lang, updating',
                            `(${currentWgLang} => ${browserLanguage})`,
                        );
                        window.localStorage.setItem('wglang', browserLanguage);
                        currentWgLang = browserLanguage;
                    } else {
                        console.warn(
                            `wg lang was not the same as i18n lang, but i18n lang is not supported by our Weglot plan, not updating, falling back to ${fallbackToUse}`,
                            `(${currentWgLang} => ${browserLanguage})`,
                        );
                        window.localStorage.setItem('wglang', fallbackToUse);
                        currentWgLang = fallbackToUse;
                    }
                }

                if (anyWindow.Weglot) {
                    if (anyWindow.Weglot.initialized) {
                        if (anyWindow.Weglot.options.api_key !== apiKeyToUse) {
                            console.log('[Weglot Plugin] Weglot API Changed, reloading...');
                            window.location.reload();
                            return;
                        }
                    }

                    anyWindow.Weglot.initialize({
                        api_key: apiKeyToUse,
                        cache: false,
                        auto_switch: true,
                        auto_switch_fallback: fallbackToUse,
                        hide_switcher: true,
                        wait_transition: true,
                        whitelist: whitelist,
                        excluded_blocks: excluded_blocks,
                        dynamics: dynamics,
                        extra_definitions: [
                            {
                                type: 3, // Type of translation, 1 for text, others: https://developers.weglot.com/api/reference#wordtype
                                selector: 'input[name="NameFilterInput"]', // Selector to find element with attribute
                                attribute: 'placeholder', // Name of attribute which is translated
                            },
                        ],
                    });

                    console.log('[Weglot Plugin] Asked Weglot to start with settings', {
                        key: apiKeyToUse,
                        fallback: fallbackToUse,
                        supportedLangs: supportedLangs,
                        whitelist: whitelist,
                        excluded_blocks: excluded_blocks,
                        currentWgLang: currentWgLang,
                        dynamics: dynamics,
                    });
                }
            } else {
                console.info('[Weglot Plugin] Plugin enabled, but missing config! Skipping.');
            }
        } else {
            console.info('[Weglot Plugin] No Weglot Plugin Config found! Skipping.');
        }
    };

    return <div id={'Weglot-Instance'}></div>;
};
