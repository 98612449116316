export enum RouteParams {
    CATEGORY = 'category',
    PRODUCT = 'product',
    DEVICE_CODE = 'device_code',
    POWER_TILE_ID = 'power_tile_id',
}

export interface PowerTileRouteParams {
    [RouteParams.POWER_TILE_ID]: string;
}

export interface CategoryProductsRouteParams {
    [RouteParams.CATEGORY]: string;
}

export interface PairDeviceRouteParams {
    [RouteParams.DEVICE_CODE]: string;
}

export interface CategoryProductRouteParams {
    [RouteParams.CATEGORY]: string;
    [RouteParams.PRODUCT]: string;
}

// export interface ProductRouteParams {
//     [RouteParams.PRODUCT]: string;
// }

export const Routes = {
    BY_CLOUDSHELF: '/by-cloudshelf',
    POWER_TILE: `/power-tile/:${RouteParams.POWER_TILE_ID}`,
    CATEGORIES: `/categories`,
    CATEGORY_PRODUCTS: `/categories/:${RouteParams.CATEGORY}/products`,
    CATEGORY_PRODUCT: `/categories/:${RouteParams.CATEGORY}/products/:${RouteParams.PRODUCT}`,
    // PRODUCTS: `/products`,
    // PRODUCT: `/products/:${RouteParams.PRODUCT}`,
    ROOT: `/`,
    NEW_DEVICE: '/new',
    PAIR_DEVICE: `/pair`,
    DEVICE_ROOT: `/`,
    DEVICE_COMPATIBILITY: `/compatibility`,
};
