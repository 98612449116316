export interface Checkout {
    loading: boolean;
    platformProvidedId?: string;
    appliedCouponCodes: string[];
    subTotal: number;
    total: number;
    discount?: number;
    tax?: number;
}

export interface CheckoutSessionInfo {
    price: number;
}

export enum CheckoutStyle {
    QR = 'QR',
    ON_DEVICE = 'ON_DEVICE',
}
