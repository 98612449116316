import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { ShopifyStore } from '../store/ShopifyStore';

export function buildShopifyApolloClient({
    domain,
    storefrontToken,
}: ShopifyStore): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
        uri: `https://${domain}/api/2023-10/graphql.json`,
        headers: {
            'X-Shopify-Storefront-Access-Token': storefrontToken,
        },
    });

    return new ApolloClient<NormalizedCacheObject>({
        link: httpLink,
        cache: new InMemoryCache({
            // typePolicies: {
            //     Query: {
            //         fields: {
            //             products: relayStylePagination(),
            //         },
            //     },
            // },
        }),
    });
}
