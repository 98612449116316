export enum CheckoutSupportedPaymentMethod {
    AmericanExpress,
    DinersClub,
    Discover,
    JCB,
    Mastercard,
    Visa,
    ShopPay,
    ApplePay,
    GooglePay,
    AndroidPay,
}
