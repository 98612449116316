import React, { FC, useRef, useEffect, useState } from 'react';
import Keyboard, { SimpleKeyboard } from 'react-simple-keyboard';
import useStateRef from 'react-usestateref';
import CircledCrossIcon from '../../icons/circled_cross';
import GrabHandleIcon from '../../icons/grab_handle';
import SearchIcon from '../../icons/search';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { StorageService } from '../../../services/StorageService/StorageService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { StorageKey } from '../../../services/StorageService/StorageKeys.enum';
import layoutEnglish from 'simple-keyboard-layouts/build/layouts/english';
import layoutItalian from 'simple-keyboard-layouts/build/layouts/italian';
import layoutGerman from 'simple-keyboard-layouts/build/layouts/german';
import layoutFrench from 'simple-keyboard-layouts/build/layouts/french';
import layoutSpanish from 'simple-keyboard-layouts/build/layouts/spanish';
import { calculateVirtualKeyboardSize } from '../../../utils/Responsive.Util';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import {
    VirtualKeyboardEventType,
    VirtualKeyboardLanguage,
} from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';

interface VirtualKeyboardInputProps {
    name?: string;
    className?: string;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
    enterKeyHint?: 'search' | 'done' | 'enter' | 'go' | 'next' | 'previous' | 'send';
    autoComplete?: React.HTMLInputAutoCompleteAttribute;
    autoCorrect?: 'off';
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: (newValue: string) => void;
    onClear?: () => void;
    autoFocus?: boolean;
    shouldUseSearchIcon?: boolean;
    initialValue: string;
    isOnScreen: boolean;
    disabled?: boolean;
}

interface OnScreenLocation {
    lastX: number;
    lastY: number;
}

const VirtualKeyboardInput: FC<VirtualKeyboardInputProps> = props => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);
    const keyboard = useRef();
    const [keyboardLayout, setKeyboardLayout] = useState<'default' | 'shift'>('default');
    const [, setOnScreen, onScreenRef] = useStateRef(false);
    const [onScreenLocation, setOnScreenLocation] = useState<OnScreenLocation | undefined>(undefined);
    const [keyboardWidth, setKeyboardWidth] = useState<number>(0);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    let layout:
        | {
              [layoutName: string]: string[];
          }
        | undefined = undefined;
    const layoutCandidates = undefined;

    const config = configService.config()!;
    if (config.virtualKeyboardLanguage === VirtualKeyboardLanguage.Italian) {
        layout = layoutItalian.layout;
        // layoutCandidates = undefined;
    } else if (config.virtualKeyboardLanguage === VirtualKeyboardLanguage.German) {
        layout = layoutGerman.layout;
        // layoutCandidates = undefined;
    } else if (config.virtualKeyboardLanguage === VirtualKeyboardLanguage.French) {
        layout = layoutFrench.layout;
        // layoutCandidates = undefined;
    } else if (config.virtualKeyboardLanguage === VirtualKeyboardLanguage.Spanish) {
        layout = layoutSpanish.layout;
        //  layoutCandidates = undefined;
    } else if (config.virtualKeyboardLanguage === VirtualKeyboardLanguage.English) {
        layout = layoutEnglish.layout;
        // layoutCandidates = undefined;
    }

    let useTouchEvents: boolean | undefined = undefined;
    let useClickEvents: boolean | undefined = undefined;

    if (config.virtualKeyboardEventType === VirtualKeyboardEventType.Auto) {
        useTouchEvents = navigator.maxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches;
        useClickEvents = !useTouchEvents;
        console.log(
            `Virtual Keyboard set to Auto. Using Touch Events: ${useTouchEvents ? 'YES' : 'NO'}, Using Click Events: ${
                useClickEvents ? 'YES' : 'NO'
            }`,
        );
    } else if (config.virtualKeyboardEventType === VirtualKeyboardEventType.Click) {
        useTouchEvents = undefined;
        useClickEvents = true;
    } else if (config.virtualKeyboardEventType === VirtualKeyboardEventType.Touch) {
        useTouchEvents = true;
        useClickEvents = undefined;
    }

    useEffect(() => {
        const keyboardWidth = calculateVirtualKeyboardSize(
            window.innerHeight,
            window.innerWidth,
            configService.config()!.screenSize,
        );
        setKeyboardWidth(keyboardWidth);

        const jsonForStoredLocation = storageService.get(StorageKey.VIRTUAL_KEYBOARD_LOCATION, true);

        if (jsonForStoredLocation) {
            setOnScreenLocation(JSON.parse(jsonForStoredLocation));
        } else {
            setOnScreenLocation({ lastX: 0, lastY: 0 });
        }
    }, []);

    useEffect(() => {
        if (props.initialValue.trim() === '') {
            setInputValue('');
        } else {
            if (!onScreenRef.current) {
                // This is the first time the component is rendered
                setInputValue(props.initialValue);
                setOnScreen(true);
            }
        }
    }, [props.initialValue, keyboard]);

    useEffect(() => {
        setOnScreen(props.isOnScreen);
    }, [props.isOnScreen]);

    const onKeyboardInternalChange = (value: string) => {
        handleAllChanges(value);
    };

    const onKeyboardKeyPress = (button: string, e?: MouseEvent) => {
        if (button === '{shift}' || button === '{lock}') {
            handleShift();
        }

        if (button === '{enter}') {
            setKeyboardVisibility(false);
            e?.stopPropagation();
            e?.preventDefault();
            e?.stopImmediatePropagation();
        }
    };

    const handleShift = () => {
        const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default';
        setKeyboardLayout(newLayoutName);
    };

    const handleAllChanges = (changedValue: string) => {
        setInputValue(changedValue);
        if (keyboard.current) {
            (keyboard.current as any).setInput(changedValue);
        }
        props.onChange?.(changedValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        handleAllChanges(input);
    };

    const handleOnClear = () => {
        handleAllChanges('');
        props.onClear?.();
    };

    const handleOnClickKeyboardBackground = () => {
        handleSetKeyboardVisibility(false);
    };

    const onDragStop = (e: DraggableEvent, data: DraggableData) => {
        console.log(data);

        const dataToSave = {
            lastX: data.lastX,
            lastY: data.lastY,
        };

        setOnScreenLocation(dataToSave);
        storageService.put(StorageKey.VIRTUAL_KEYBOARD_LOCATION, JSON.stringify(dataToSave));
    };

    const handleOnInit = (e: SimpleKeyboard) => {
        e.setInput(props.initialValue);
    };

    useEffect(() => {
        if (props.autoFocus) {
            inputRef?.current?.focus();
        }
    }, []);

    const handleSetKeyboardVisibility = (value: boolean) => {
        if (layout !== undefined) {
            setKeyboardVisibility(value);
        } else if (keyboardVisibility !== false) {
            setKeyboardVisibility(false);
        }
    };
    return (
        <>
            <input
                value={inputValue}
                onChange={handleChange}
                name={props.className}
                className={props.className}
                onKeyUp={props.onKeyUp}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                ref={inputRef}
                type={props.type}
                enterKeyHint={props.enterKeyHint}
                autoComplete={props.autoComplete}
                autoCorrect={props.autoCorrect}
                onClick={() => {
                    handleSetKeyboardVisibility(true);
                }}
                onFocus={() => {
                    handleSetKeyboardVisibility(true);
                }}
                disabled={props.disabled}
            />
            {props.shouldUseSearchIcon && (
                <div className="NameFilterInput__icon" onClick={() => inputRef.current?.focus()}>
                    {inputRef.current?.value === '' && <SearchIcon className="SearchIcon" />}
                    {inputRef.current?.value !== '' && (
                        <CircledCrossIcon className="GreySVGFill" onClick={handleOnClear} />
                    )}
                </div>
            )}
            {keyboardVisibility && onScreenLocation && layout && (
                <div className="VirtualKeyboard">
                    <div className="VirtualKeyboard__Page" onClick={handleOnClickKeyboardBackground}>
                        <Draggable
                            onStop={onDragStop}
                            positionOffset={{ x: '-50%', y: '10%' }}
                            defaultPosition={{ x: onScreenLocation.lastX, y: onScreenLocation.lastY }}
                        >
                            <div
                                className="VirtualKeyboard__KeyboardContainer"
                                onClick={e => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.nativeEvent.stopPropagation();
                                    e.stopPropagation();
                                }}
                                onMouseDown={e => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.nativeEvent.stopPropagation();
                                    e.stopPropagation();
                                }}
                                style={{
                                    position: 'absolute',
                                    bottom: '10%',
                                    left: '50%',
                                    width: `${keyboardWidth}px`,
                                }}
                            >
                                <div className="VirtualKeyboard__KeyboardContainer__Top">
                                    <div className="VirtualKeyboard__KeyboardContainer__Top__GrabHandle">
                                        <GrabHandleIcon />
                                    </div>
                                    <div className="VirtualKeyboard__KeyboardContainer__Top__Close">
                                        <CircledCrossIcon onClick={() => handleSetKeyboardVisibility(false)} />
                                    </div>
                                </div>
                                <div className="VirtualKeyboard__KeyboardContainer__Keyboard">
                                    <Keyboard
                                        useMouseEvents={useClickEvents}
                                        useTouchEvents={useTouchEvents}
                                        autoUseTouchEvents={false}
                                        onInit={handleOnInit}
                                        physicalKeyboardHighlight
                                        disableButtonHold
                                        keyboardRef={r => (keyboard.current = r)}
                                        layoutName={keyboardLayout}
                                        onChange={onKeyboardInternalChange}
                                        onKeyPress={onKeyboardKeyPress}
                                        layout={layout}
                                        layoutCandidates={layoutCandidates}
                                    />
                                </div>
                            </div>
                        </Draggable>
                    </div>
                </div>
            )}
        </>
    );
};

export default VirtualKeyboardInput;
