import React, { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import {
    AttractLoopOrchestratorService,
    AttractLoopScreenDef,
} from '../../../../../services/AttractLoopOrchestratorService/AttractLoopOrchestratorService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import TouchIndicator from '../TouchIndicator/TouchIndicator';

interface AttractLoopScreenProps {
    def: AttractLoopScreenDef;
    playerVisible: boolean;
    // devControls?: boolean;
    animating: boolean;
    miniumTime: number;
}

const AttractLoopScreen: FunctionalComponentWithChildren<AttractLoopScreenProps> = props => {
    const tlRef = useRef<GSAPTimeline | null>(null);
    const container = useRef(null);
    const firstText = props.def.text.length > 0 ? props.def.text[0] : '';
    const attractLoopOrchestratorService = useInjection<AttractLoopOrchestratorService>(
        DependencyType.AttractLoopOrchestratorService,
    );
    const [headingOut, setHeadingOut] = useState(false);
    const [animatedAtLeastOnce, setAnimatedAtLeastOnce] = useState(false);
    const [allowHandleClicks, setAllowHandleClicks] = useState(false);

    const reportBannerAnimatingOut = () => {
        attractLoopOrchestratorService.reportBannerAnimatingOut();
        setHeadingOut(true);
    };

    const finsihedOut = () => {
        setHeadingOut(false);
        setAllowHandleClicks(false);
    };

    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    const isLandscape = screenWidth > screenHeight;

    const InBackgroundImageFromVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
    };

    const InBackgroundImageToVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
        duration: 1,
    };

    const InTextAreaFromVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.75) : screenHeight * 0.75,
    };

    const InTextAreaToVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
        duration: 2,
    };

    const InButtonAreaFromVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
    };

    const InButtonAreaToVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
        duration: 2,
    };

    const OutBackgroundImageFromVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
    };

    const OutBackgroundImageToVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? screenWidth * 0.2 : -(screenHeight * 0.2),
        duration: 1,
    };

    const OutButtonAreaFromVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
    };

    const OutButtonAreaToVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
        duration: 1,
    };

    const OutTextAreaFromVars: GSAPTweenVars = {
        opacity: 1,
        [isLandscape ? 'x' : 'y']: 0,
    };

    const OutTextAreaToVars: GSAPTweenVars = {
        opacity: 0,
        [isLandscape ? 'x' : 'y']: isLandscape ? -(screenWidth * 0.2) : screenHeight * 0.2,
        duration: 1,
    };

    //Would prefer to use useGSAP, but it doesn't work for some readon
    const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
    useIsomorphicLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap
                .timeline({
                    id: props.def.id,
                    defaults: { ease: 'power2.inOut', immediateRender: false },
                    onComplete: () => console.log(`timeline ${props.def.id} complete`),
                })
                .addLabel('in')
                .to({}, { duration: 0.25 })
                .fromTo('.AttractLoopScreen__BackgroundImage', InBackgroundImageFromVars, InBackgroundImageToVars);
            if (props.def.text.length > 0) {
                tl.fromTo('.AttractLoopScreen__TextArea', InTextAreaFromVars, InTextAreaToVars, '-=1');
            }
            if (props.def.button) {
                let pos = '<';
                if (props.def.text.length === 0) {
                    pos = '-=0.50';
                }
                tl.fromTo('.AttractLoopScreen__ButtonArea', InButtonAreaFromVars, InButtonAreaToVars, pos);
            }
            if (props.def.text.length > 1) {
                props.def.text.slice(1).forEach((text: string) => {
                    tl.to({}, { duration: 2 })
                        .to('.AttractLoopScreen__TextArea', { duration: 0.75, text: '' })
                        .to('.AttractLoopScreen__TextArea', { duration: 1.25, text: text });
                });
            }
            //always then wait at least 2 seconds, before doing out.
            tl.to({}, { duration: 2 });
            const ruleFromConfig = props.miniumTime;
            const additionalPause = ruleFromConfig - tl.duration();
            if (additionalPause > 0) {
                tl.to({}, { duration: additionalPause });
            }
            tl.addLabel('out');
            //Tell the serivce that we are going to animate out, so that it can then animate in, and then start the animation.
            tl.call(reportBannerAnimatingOut);
            tl.to({}, { duration: 0.5 });
            tl.fromTo('.AttractLoopScreen__BackgroundImage', OutBackgroundImageFromVars, OutBackgroundImageToVars);
            if (props.def.button) {
                tl.fromTo('.AttractLoopScreen__ButtonArea', OutButtonAreaFromVars, OutButtonAreaToVars, '<');
            }
            if (props.def.text.length !== 0) {
                tl.fromTo('.AttractLoopScreen__TextArea', OutTextAreaFromVars, OutTextAreaToVars, '-=0.50');
            }
            tl.call(finsihedOut);
            tl.addLabel('end');
            tl.pause(0);
            //Finally, we set the timeline reference, so we can pause it later, and then setup dev controls if we need to.
            tlRef.current = tl;
            // if (props.devControls) {
            //     GSDevTools.create({ paused: true });
            // }
        }, container); // <-- scope for selector text
        return () => ctx.revert(); // <-- cleanup
    }, []); // <-- empty dependency Array so it doesn't get called on every render

    useEffect(() => {
        if (!props.playerVisible) {
            console.log('AttractLoopScreen told ParentPlayer is hidden.', tlRef.current);
            tlRef.current?.pause(0);
        }
    }, [props.playerVisible]);

    useEffect(() => {
        if (props.animating) {
            console.log(`AttractLoopScreen '${props.def.id}' asked to animate.`);
            setHeadingOut(false);
            setAnimatedAtLeastOnce(true);
            setAllowHandleClicks(true);
            tlRef.current?.restart(false, true);
        }
    }, [props.animating]);

    const onButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
        if (allowHandleClicks) {
            if (props.def.button?.onClick) {
                props.def.button.onClick();
                attractLoopOrchestratorService.requestStopFromInteraction();
            }
        }
    };

    const onScreenClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
        if (allowHandleClicks) {
            if (props.def.button?.onClick) {
                props.def.button.onClick();
                attractLoopOrchestratorService.requestStopFromInteraction();
            }
        }
    };

    const shouldShowButton = props.def.button && props.def.button.text.trim() !== '';

    return (
        <div
            className={`AttractLoopScreen ${props.animating ? 'AttractLoopScreen__In' : ''} ${
                headingOut ? 'AttractLoopScreen__Out' : ''
            }`}
            ref={container}
            onClick={onScreenClicked}
        >
            <div className={'AttractLoopScreen__Content'}>
                <div
                    className={`AttractLoopScreen__BackgroundImage  ${
                        animatedAtLeastOnce ? '' : 'AttractLoopScreen__AnimationOpacityFix'
                    }`}
                    style={{
                        background: props.def.backgroundColor ?? '#000000',
                    }}
                >
                    <img src={props.def.backgroundImage} className="AttractLoopScreen__BackgroundImage__Blur" />
                    <img src={props.def.backgroundImage} className="AttractLoopScreen__BackgroundImage__Image" />
                </div>

                {firstText !== '' && (
                    <div
                        className={`AttractLoopScreen__TextArea  ${
                            animatedAtLeastOnce ? '' : 'AttractLoopScreen__AnimationOpacityFix'
                        } AttractLoopScreen__TextArea__${
                            props.def.textSize.charAt(0).toUpperCase() + props.def.textSize.slice(1).toLowerCase()
                        }`}
                    >
                        {firstText}
                    </div>
                )}

                <div
                    className={`AttractLoopScreen__ButtonArea ${
                        animatedAtLeastOnce ? '' : 'AttractLoopScreen__AnimationOpacityFix'
                    }`}
                >
                    {shouldShowButton ? (
                        <button
                            className="AttractLoopScreen__ButtonArea__Button AttractLoopScreen__NewButtonStyleNoBackground"
                            onClick={onButtonClick}
                        >
                            {props.def.button?.text}
                        </button>
                    ) : (
                        <div className="AttractLoopRipple">
                            <TouchIndicator />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default AttractLoopScreen;
