import _ from 'lodash';
import { inspect } from 'util';

export enum LogLevel {
    Error = 'error',
    Warn = 'warn',
    Info = 'info',
    Debug = 'debug',
    Trace = 'trace',
}

const levelStyles = {
    [LogLevel.Error]: 'background: #F00; color: #FFF',
    [LogLevel.Warn]: 'background: #EB8C34; color: #000',
    [LogLevel.Info]: 'background: #004fc4; color: #FFF',
    [LogLevel.Debug]: 'background: #FFF; color: #000',
    [LogLevel.Trace]: 'background: #000; color: #FFF',
};

export class LogUtil {
    static LogMany(messageStylePairs: { message: string; style: string }[]) {
        const message = _.map(messageStylePairs, pair => pair.message).join('%c');
        const styles = _.map(messageStylePairs, pair => pair.style);
        if ((process.env.REACT_APP_RELEASE_TYPE ?? '') !== 'production') {
            console.log(`%c${message}`, ...styles);
        }
    }

    static Log(message: string, logLevel: LogLevel = LogLevel.Info) {
        this.LogMany([{ message, style: levelStyles[logLevel] }]);
    }

    static LogObject(obj: any, logLevel: LogLevel = LogLevel.Info) {
        this.LogMany([{ message: inspect(obj, true, 99, false), style: levelStyles[logLevel] }]);
    }

    static LogException(message: string, exception: unknown) {
        this.LogMany([
            {
                message: message + '\n',
                style: 'background: #F00; color: #FFF; font-weight: bold;',
            },
            {
                message: JSON.stringify(exception, Object.getOwnPropertyNames(exception), 2),
                style: 'background: #F00; color: #FFF',
            },
        ]);
    }
}
