export function setSafariIconMask() {
    const maskLink = document.querySelector('#mask-link');
    if (process.env.REACT_APP_RELEASE_TYPE === 'development') {
        maskLink?.setAttribute('color', '#5bbad5');
        maskLink?.setAttribute('href', '/logos/safari-pinned-tab.svg');
    } else {
        maskLink?.setAttribute('color', '#fa3f68');
        maskLink?.setAttribute('href', '/logos/safari-pinned-tab.svg');
    }
}
