import { FC } from 'react';
import { useInjection } from '../../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../../../services/ConfigurationService/ConfigurationService';
import { EngineType } from '../../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

const DisplayModeOverlayComponent: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    let mode = 'unknown';

    if (configService.deviceMode === EngineType.Interactive) {
        mode = 'interactive';
    } else if (configService.deviceMode === EngineType.DisplayOnly) {
        mode = 'display';
    }

    return <p>m: {mode}</p>;
};

export default DisplayModeOverlayComponent;
