import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    GetDeviceInfoDocument,
    GetDeviceInfoQuery,
    GetDeviceInfoQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { LogLevel, LogUtil } from '../../utils/Logging.Util';
import * as Sentry from '@sentry/react';

export class DeviceService {
    constructor(private readonly _apolloClient: ApolloClient<NormalizedCacheObject>) {}

    public async getDeviceInfo(deviceId: string): Promise<DeviceInfo | null> {
        if (!deviceId || deviceId.trim() === '') {
            deviceId = 'gid://cloudshelf/device/01H5SYS9AAAAAAAAAAAAAAAAAA';
        }
        try {
            const queryTuple = await this._apolloClient.query<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>({
                query: GetDeviceInfoDocument,
                variables: {
                    deviceId,
                },
            });

            if (queryTuple.errors || !queryTuple.data) {
                LogUtil.LogObject(['Error getting device info: ', queryTuple], LogLevel.Error);
            }

            const device = queryTuple.data.publicDevice;
            if (!device) {
                return null;
            }

            let id = device.id;
            if (device.platformProvidedId) {
                const castedPlatformProvidedId = device.platformProvidedId as string;
                if (
                    castedPlatformProvidedId &&
                    castedPlatformProvidedId.startsWith('gid://external/cloudshelfV2Device/')
                ) {
                    id = castedPlatformProvidedId;
                }
            }

            return {
                id: id,
                registrationCode: device.registrationCode,
                registered: device.registered,
            };
        } catch (err) {
            Sentry.captureException(err, {
                extra: {
                    operationName: 'getDeviceInfo (deviceService)',
                },
            });
            throw new Error(`Error in getDeviceInfo: ${err}`);
        }
    }
}

export interface DeviceInfo {
    id: string;
    registrationCode: string;
    registered: boolean;
}
