import { List } from 'immutable';
import { injectable } from 'inversify';
import { Category } from './entities/Category';

@injectable()
export class CategoryService {
    constructor(private readonly categories: List<Category>) {}

    allCategories(): List<Category> {
        return this.categories;
    }

    getByHandle(categoryHandle: string): Category | undefined {
        return this.categories.find(({ handle }) => handle === categoryHandle);
    }
}
