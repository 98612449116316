import React, { FC, memo } from 'react';
import { DependencyProvider } from '../../../dependancyInjection/DependencyContext';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import DebugOverlays from '../BaseApp/components/DebugOverlays/DebugOverlays';
import { Router } from 'react-router-dom';
import { History } from 'history';
import { Weglot } from '../../shared/Weglot/Weglot';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface StrippedAppProps {
    history: History;
}

const StrippedApp: FunctionalComponentWithChildren<StrippedAppProps> = memo(props => {
    window.document.title = 'Cloudshelf';

    return (
        <DependencyProvider container={dependenciesContainer}>
            <Weglot />
            <DebugOverlays />
            <Router history={props.history}>{props.children}</Router>
        </DependencyProvider>
    );
});

export default StrippedApp;
