import Dexie, { Table } from 'dexie';
import { gzipCompress, gzipDecompress } from '../../utils/Compression.Util';
import { CloudshelfEngineConfig } from '../ConfigurationService/types/config/CloudshelfEngineConfig';
import { KeyValuePair } from '../ConfigurationService/types/config/KeyValuePair';
import { FilterableProduct } from '../ProductServices/FilterableProductTypes';

export const DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3 = 'PRODUCT_BINARY_V3';
export const DEXIE_DATABASE_PRODUCT_CACHE_CLOUDSHELF_ID = 'PRODUCT_CACHE_CLOUDSHELF_ID';
export const DEXIE_DATABASE_CLOUDSHELF_CONFIG = 'CLOUDSHELF_CONFIG';
export const DEXIE_DATABASE_PROVIDER_CONFIG = 'PROVIDER_CONFIG';
export const DEXIE_DATABASE_SALES_ASSOCIATE_ID = 'SALES_ASSOCIATE_ID';
export const DEXIE_DATABASE_SALES_ASSOCIATE_EXPIRY = 'SALES_ASSOCIATE_EXPIRY';
export const DEXIE_DATABASE_CONFIG_RELOAD_BLOCKED_REASON = 'BLOCKED_REASON';
export const DEXIE_DATABASE_CURRENT_SESSION_ID = 'CURRENT_SESSION_ID';

export class CacheRecord {
    key = '';
    data: FilterableProduct[] = [];
}

export class StoredData {
    key = '';
    data = '';
    compressed = false;
}

class DexieDatabase extends Dexie {
    cache!: Table<CacheRecord>;
    data!: Table<StoredData>;

    constructor() {
        super('CloudshelfDatabase');
        this.version(2).stores({ cache: 'key', data: 'key' });
    }

    public async getCache(): Promise<FilterableProduct[]> {
        const record = await this.cache.get(DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3);
        if (record) {
            return record.data;
        }
        return [];
    }

    public async putCache(cache: FilterableProduct[]) {
        //Clearing is super quick even on super SHIT devices, so we can cheat here.
        this.cache.clear();
        const record = new CacheRecord();
        const key = DEXIE_DATABASE_PRODUCT_BINARY_KEY_v3;
        record.key = key;
        record.data = cache;
        await this.cache.put(record, key);
    }

    public async getData(key: string) {
        const record = await this.data.get(key);
        if (record) {
            if (record.compressed) {
                try {
                    return gzipDecompress(record.data);
                } catch (error) {
                    console.error('Failed to decompress data:', error);
                    return undefined;
                }
            }
            return record.data;
        }
        return undefined;
    }

    public async putData(key: string, data: string, compress: boolean) {
        const record = new StoredData();

        const possiblyCompressedData = compress ? gzipCompress(data) : data;

        record.key = key;
        record.data = possiblyCompressedData;
        record.compressed = compress;
        await this.data.put(record, key);
    }

    public async removeData(key: string) {
        await this.data.delete(key);
    }

    public async getCacheCloudshelfId(): Promise<string | undefined> {
        return this.getData(DEXIE_DATABASE_PRODUCT_CACHE_CLOUDSHELF_ID);
    }

    public async putCacheCloudshelfId(cloudshelfId: string) {
        return this.putData(DEXIE_DATABASE_PRODUCT_CACHE_CLOUDSHELF_ID, cloudshelfId, true);
    }

    //CLOUDSHELF CONFIG START
    public async getCloudshelfConfig(): Promise<CloudshelfEngineConfig | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_CLOUDSHELF_CONFIG);

        if (!resultFromDb) {
            return undefined;
        }

        const result: CloudshelfEngineConfig = JSON.parse(resultFromDb) as CloudshelfEngineConfig;
        return result;
    }

    public async putCloudshelfConfig(config: CloudshelfEngineConfig) {
        const stringified = JSON.stringify(config);
        return this.putData(DEXIE_DATABASE_CLOUDSHELF_CONFIG, stringified, true);
    }

    public async deleteCloudshelfConfig() {
        return this.removeData(DEXIE_DATABASE_CLOUDSHELF_CONFIG);
    }
    //CLOUDSHELF CONFIG END

    //PROVIDER CONFIG START
    public async getCloudshelfProviderConfig(): Promise<KeyValuePair[] | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_PROVIDER_CONFIG);

        if (!resultFromDb) {
            return undefined;
        }

        const result: KeyValuePair[] = JSON.parse(resultFromDb) as KeyValuePair[];
        return result;
    }

    public async putCloudshelfProviderConfig(config: KeyValuePair[]) {
        const stringified = JSON.stringify(config);
        return this.putData(DEXIE_DATABASE_PROVIDER_CONFIG, stringified, true);
    }

    public async deleteCloudshelfProviderConfig() {
        return this.removeData(DEXIE_DATABASE_PROVIDER_CONFIG);
    }
    //PROVIDER CONFIG END

    //SALES ASSISTANT EXPIRY START
    public async getSalesAssociateExpiry(): Promise<string | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_SALES_ASSOCIATE_EXPIRY);

        if (!resultFromDb) {
            return undefined;
        }

        return resultFromDb;
    }

    public async putSalesAssociateExpiry(value: string) {
        return this.putData(DEXIE_DATABASE_SALES_ASSOCIATE_EXPIRY, value, true);
    }

    public async deleteSalesAssociateExpiry() {
        return this.removeData(DEXIE_DATABASE_SALES_ASSOCIATE_EXPIRY);
    }
    //SALES ASSISTANT EXPIRY END

    //SALES ASSISTANT ID START
    public async getSalesAssociateId(): Promise<string | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_SALES_ASSOCIATE_ID);

        if (!resultFromDb) {
            return undefined;
        }

        return resultFromDb;
    }

    public async putSalesAssociateId(value: string) {
        return this.putData(DEXIE_DATABASE_SALES_ASSOCIATE_ID, value, true);
    }

    public async deleteSalesAssociateId() {
        return this.removeData(DEXIE_DATABASE_SALES_ASSOCIATE_ID);
    }
    //SALES ASSISTANT ID END

    //BLOCKED REASON START
    public async getConfigReloadBlockedReason(): Promise<string | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_CONFIG_RELOAD_BLOCKED_REASON);

        if (!resultFromDb) {
            return undefined;
        }

        return resultFromDb;
    }

    public async putConfigReloadBlockedReason(value: string) {
        return this.putData(DEXIE_DATABASE_CONFIG_RELOAD_BLOCKED_REASON, value, true);
    }

    public async deleteConfigReloadBlockedReason() {
        return this.removeData(DEXIE_DATABASE_CONFIG_RELOAD_BLOCKED_REASON);
    }
    //BLOCKED REASON END

    //PREVIEW SCREEN SIZE START
    public async getStoredSessionId(): Promise<string | undefined> {
        const resultFromDb = await this.getData(DEXIE_DATABASE_CURRENT_SESSION_ID);

        if (!resultFromDb) {
            return undefined;
        }

        return resultFromDb;
    }

    public async putStoredSessionId(value: string) {
        return this.putData(DEXIE_DATABASE_CURRENT_SESSION_ID, value, true);
    }

    public async deleteStoredSessionId() {
        return this.removeData(DEXIE_DATABASE_CURRENT_SESSION_ID);
    }
    //PREVIEW SCREEN SIZE END

    public async wipeCache() {
        this.cache.clear();
    }
}

let dexieDatabaseInstance: DexieDatabase | null = null;

export const dexieDatabase = (): DexieDatabase => {
    if (dexieDatabaseInstance === null) {
        dexieDatabaseInstance = new DexieDatabase();
    }
    return dexieDatabaseInstance;
};
