import React, { useEffect } from 'react';
import _ from 'lodash';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from '../../../../../shared/inputs/Button/Button';
import {
    getVariantAvailability,
    ProductVariantAvailability,
} from '../../../../../../services/ProductServices/ProductVariantAvailability';
import { usePurchase } from '../../../../../../hooks/contexts/Purchase/Purchase';
import { BasketItem } from '../../../../../../services/BasketService/Basket.type';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { BasketService } from '../../../../../../services/BasketService/BasketService';
import { KeyValuePair } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ToastService } from '../../../../../../services/ToastService/ToastService';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import {
    FilterableProduct,
    FilterableProductVariant,
} from '../../../../../../services/ProductServices/FilterableProductTypes';

export interface ProductCartOptionsProps {
    className?: string;
    product: FilterableProduct;
    selectedVariant?: FilterableProductVariant;
    allVariantsUnavailable: boolean;
    scroll: (to: 'variants' | 'customiser' | 'images') => void;
    formValid: boolean;
    customOptions: KeyValuePair[];
    productCustomiserPriceModifier: number;
    onCheckoutPressed?: () => void;
    maxQuantity?: number;
    onAddedToBasket?: () => void;
}

const ProductCartOptions: FunctionalComponentWithChildren<ProductCartOptionsProps> = props => {
    const { t } = useTranslation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const [loading, setLoading] = React.useState(false);
    const [, setQuantity, quantityRef] = useStateRef(1);
    const [showAddedLabel, setShowAddedLabel] = React.useState(false);
    const toastService = useInjection<ToastService>(DependencyType.ToastService);

    useEffect(() => {
        const maxAllowed = props.maxQuantity ?? Number.MAX_VALUE;
        if (quantityRef.current > maxAllowed) {
            setQuantity(maxAllowed);
        }
    }, [props.maxQuantity]);

    useEffect(() => {
        if (props.selectedVariant) {
            if (quantityRef.current !== 1) {
                setQuantity(1);
            }
        }
    }, [props.selectedVariant]);

    useEffect(() => {
        //subscribe to basket changes
        const sub = basketService.observeBasket().subscribe(basket => {
            if (basket && basket.lineItems) {
                const lineItem = _.find(
                    basket?.lineItems,
                    li =>
                        li.variant.id === props.selectedVariant?.id &&
                        BasketService.attributesMatch(props.customOptions, li.attributes),
                );

                if (lineItem) {
                    if (quantityRef.current !== lineItem.quantity) {
                        setQuantity(lineItem.quantity);
                    }
                }
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [props.selectedVariant, props.customOptions]);

    //Yes this is needed
    let buttonLabel = t('product_view.buy_buttons.add_to_basket');
    if (showAddedLabel) {
        buttonLabel = '✓ ' + t('product_view.buy_buttons.added');
    }

    const handleProductCustomiserPriceModifier = async (
        realProductDetails: FilterableProduct | undefined,
        realItemVariant: FilterableProductVariant,
        quantity = 1,
        options: KeyValuePair[] = [],
    ) => {
        const itemCustomiserProduct = configService.productCustomiserPriceModifierProduct;
        const itemCustomiserVariant = configService.productCustomiserPriceModifierVariant;
        if (props.productCustomiserPriceModifier <= 0 || !itemCustomiserVariant || !itemCustomiserProduct) {
            return;
        }

        let customisationFor = realItemVariant.title;
        if (props.product) {
            customisationFor = `${props.product.title} (${realItemVariant.title})`;
        }

        customisationFor = `Customisation for ${customisationFor}`;
        await basketService.setItemQuantity(
            itemCustomiserProduct,
            itemCustomiserVariant,
            props.productCustomiserPriceModifier * quantity,
            options,
            customisationFor,
            props.maxQuantity,
        );
    };

    const handleAddToBasket = async () => {
        props.onCheckoutPressed?.();

        if (!props.selectedVariant) {
            props.scroll('variants');
            return;
        }

        if (!props.formValid) {
            props.scroll('customiser');
            return;
        }

        toastService.showPromiseGlass(
            new Promise(async (resolve, reject) => {
                if (!props.selectedVariant) {
                    reject();
                    return;
                }

                setLoading(true);
                const existingQuantity = await basketService.getItemQuantity(
                    props.selectedVariant.id,
                    props.customOptions,
                );
                try {
                    await basketService.setItemQuantity(
                        props.product,
                        props.selectedVariant,
                        existingQuantity + 1,
                        props.customOptions,
                        props.product?.title ?? props.selectedVariant.title,
                        props.maxQuantity,
                    );
                    await handleProductCustomiserPriceModifier(
                        props.product,
                        props.selectedVariant,
                        existingQuantity + 1,
                        props.customOptions,
                    );
                    props.onAddedToBasket?.();
                    resolve(true);
                } catch (e) {
                    reject(e);
                } finally {
                    setLoading(false);
                }
            }),
        );
    };

    const buttonDisabled =
        props.allVariantsUnavailable ||
        !props.selectedVariant ||
        getVariantAvailability(props.selectedVariant) === ProductVariantAvailability.Unavailable;

    return (
        <div className={props.className}>
            <div className={'ProductCardOptions'}>
                {/* <Quantity
                    initialQuantity={quantityRef.current}
                    className={'ProductCardOptions__quantity'}
                    onQuantityChanged={handleQuantityChange}
                    loading={loading}
                    variant={inBasket ? 'numInBasket' : 'inline'}
                    overrideQuantity={quantityRef.current}
                    maxQuantity={props.maxQuantity}
                />
                <div className={'ProductCardOptions__spacer'} /> */}
                <Button
                    className={'ProductCardOptions__buttonBuyNow ButtonOnImage'}
                    text={buttonLabel}
                    size={ButtonSize.MD}
                    onClick={handleAddToBasket}
                    disabled={buttonDisabled}
                    variant={ButtonVariant.GREENFLAT}
                    loading={loading}
                    useTransition
                    translate
                    useContrastColor
                />
            </div>
        </div>
    );
};

export default ProductCartOptions;
