import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng',
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL ?? ''}/locales/{{lng}}.json`,
        },
        load: 'languageOnly',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
