import { StorageKey } from './StorageKeys.enum';
import { injectable } from 'inversify';
import _ from 'lodash';
import { FilterableProduct } from '../ProductServices/FilterableProductTypes';
import { dexieDatabase } from './DexieDatabase';
import { LogUtil } from '../../utils/Logging.Util';
import { gzipCompress, gzipDecompress } from '../../utils/Compression.Util';

@injectable()
export class StorageService {
    private storage: Storage;
    private cache: { [key: string]: string } = {};

    private OLD_KEY_CLOUDSHELF_ID = 'cloudshelfId';
    private SALES_ASSOCIATE_ID = 'salesAssociateId';
    private SALES_ASSOCIATE_EXPIRY = 'salesAssociateExpiry';
    private CLOUDSHELF_CONFIG = 'cloudshelfConfig';
    private CLOUDSHELF_PROVIDER_CONFIG = 'cloudshelfProviderConfigKvps';

    constructor() {
        this.storage = window.localStorage;
    }

    public async setup() {
        //Important to note that STORAGE_VERSION is the only item we store uncompressed
        const storageVersion = this.storage.getItem(StorageKey.STORAGE_VERSION);
        if (storageVersion === null) {
            // Upgrade from v1 to v2
            // In v1 we did not compress data in local storage
            // We are going to do that now from v2 onwards
            for (let storageIndex = 0; storageIndex < this.storage.length; storageIndex++) {
                const keyForIndex = this.storage.key(storageIndex);
                //We only want to compress if the following are true:
                // - The key is not null
                // - The key exists in the StorageKey enum (known cloudshelf keys); this is so we don't
                //      break any packages that may use LocalStorage
                // - The key is not the storage version key
                if (
                    keyForIndex &&
                    _.includes(Object.values(StorageKey), keyForIndex) &&
                    keyForIndex !== StorageKey.STORAGE_VERSION
                ) {
                    const uncompressedItem = this.storage.getItem(keyForIndex);
                    if (uncompressedItem) {
                        const compressed = gzipCompress(uncompressedItem);
                        this.storage.setItem(keyForIndex, compressed);
                        this.cache[keyForIndex] = compressed;
                    }
                }
            }
            this.storage.setItem(StorageKey.STORAGE_VERSION, 'v2');
            window.location.reload(); //Force reload to ensure we don't get any errors
        } else if (storageVersion === 'v2' || storageVersion === 'v3') {
            // v2|v3 (which was never released) -> v4 should go here
            console.log('Upgrading from storage v2/v3 to v4');
            console.log('Wiping product cache');
            await this.wipeProductCache();
            console.log('Wiping Cloudshelf ID for Product Cache');
            this.deleteString(this.OLD_KEY_CLOUDSHELF_ID);
            this.storage.setItem(StorageKey.STORAGE_VERSION, 'v4');
            window.location.reload(); //Force reload to ensure we don't get any errors
        } else if (storageVersion === 'v4') {
            console.log('Upgrading from storage v5');
            console.log(`Deleting old keys`);
            this.deleteString(this.OLD_KEY_CLOUDSHELF_ID);
            this.deleteString(this.SALES_ASSOCIATE_ID);
            this.deleteString(this.SALES_ASSOCIATE_EXPIRY);
            this.deleteString(this.CLOUDSHELF_CONFIG);
            this.deleteString(this.CLOUDSHELF_PROVIDER_CONFIG);
            this.storage.setItem(StorageKey.STORAGE_VERSION, 'v5');
            window.location.reload(); //Force reload to ensure we don't get any errors
        } else if (storageVersion === 'v5') {
            //v5 is the current version, so no upgrade needed
        }
    }

    public get(key: StorageKey, bypassCache?: boolean): string | undefined {
        // console.log(`[StorageService] Get key: ${key}, bypassCache: ${bypassCache}`);
        //get from the cache is bypassCache is false
        if (!bypassCache && this.cache[key]) {
            const cachedValue = this.cache[key];
            // console.log(`[StorageService] Get key(${key}) value from cache: ${cachedValue} `);
            return cachedValue;
        }

        const compressed = this.storage.getItem(key);
        // console.log(`[StorageService] Get key(${key}) value from storage: ${compressed}`);
        if (compressed) {
            const decompressed = gzipDecompress(compressed);
            // console.log(`[StorageService] Get key(${key}) decompressed: ${decompressed}`);
            return decompressed;
        }

        return undefined;
    }

    public put(key: StorageKey, value: string) {
        const compressed = gzipCompress(value);

        const characterCount = compressed.length;

        if (characterCount >= 5200000) {
            console.error(`Tried to save too much data (${characterCount})`, key, value, compressed);
        }

        this.storage.setItem(key, compressed);
        this.cache[key] = value;
    }

    public deleteString(key: string) {
        this.storage.removeItem(key);
    }

    public delete(key: StorageKey) {
        this.storage.removeItem(key);
        delete this.cache[key];
    }

    public async putProductCache(cache: FilterableProduct[]) {
        try {
            await dexieDatabase().putCache(cache);
        } catch (err) {
            LogUtil.LogException('Error in StorageService.putProductCache', err);
        }
    }

    public async getProductCache() {
        try {
            const result = await dexieDatabase().getCache();
            return result;
        } catch (err) {
            LogUtil.LogException('Error in StorageService.getProductCache', err);
        }
        return [];
    }

    public async wipeProductCache() {
        try {
            await dexieDatabase().wipeCache();
        } catch (err) {
            LogUtil.LogException('Error in StorageService.wipeProductCache', err);
        }
    }
}
