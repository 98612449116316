import { Router } from 'react-router-dom';
import { PurchaseProvider } from '../../../hooks/contexts/Purchase/Purchase';
import CloudshelfSettings from './components/CloudshelfSettings/CloudshelfSettings';
import { History } from 'history';
import { DependencyProvider, useInjection } from '../../../dependancyInjection/DependencyContext';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import ProviderModuleLoader from '../../../provider/ProviderModuleLoader';
import DebugOverlays from './components/DebugOverlays/DebugOverlays';
import SampleTextOverlay from './components/SampleTextOverlay/SampleTextOverlay';
import RouteEventHelper from './components/RouteEventHelper/RouteEventHelper';
import { BrandingBar } from './components/BrandingBar/BrandingBar';
import { PreviewBar } from './components/PreviewBar/PreviewBar';
import SampleButtonOverlay from './components/SampleButtonOverlay/SampleButtonOverlay';
import { DOWN, LEFT, RIGHT, SwipeEventData, UP, useSwipeable } from 'react-swipeable';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../services/EventsService/EventsService';
import { Weglot } from '../../shared/Weglot/Weglot';
import CustomCSS from './components/CustomCSS/CustomCSS';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';
import { memo, useEffect, useRef } from 'react';

export interface AppProps {
    history: History;
}

const BaseApp: FunctionalComponentWithChildren<AppProps> = memo(props => {
    const eventsService = dependenciesContainer.get<EventsService>(DependencyType.EventsService);
    const pattern = [DOWN, DOWN, DOWN];
    const pIdx = useRef(0);

    if (window.location.toString().includes('categories/categories')) {
        //reload to route
        window.location.href = '/';
    }
    const handleSwiped = (eventData: SwipeEventData) => {
        if (eventData.dir === pattern[pIdx.current]) {
            // console.log('User swipped in the right direction!');
            // user successfully got to the end of the pattern!
            if (pIdx.current + 1 === pattern.length) {
                pIdx.current = pattern.length;
                eventsService.setSalesPersonAllocationOpen(true);
                pIdx.current = 0;
            } else {
                // user is cont. with the pattern
                pIdx.current = pIdx.current + 1;
            }
        } else {
            // user failed to get to the end of the pattern
            // console.log('User swipped in the wrong direction!, resetting pattern');
            pIdx.current = 0;
        }
    };

    const handlers = useSwipeable({
        onSwiped: handleSwiped,
        // onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
        touchEventOptions: { passive: true },
        preventScrollOnSwipe: false,
        trackMouse: true,
    });

    useEffect(() => {
        const swipeRemovalInterval = setInterval(() => {
            if (pIdx.current > 0) {
                pIdx.current = 0;
            }
        }, 2500);

        return () => {
            clearInterval(swipeRemovalInterval);
        };
    }, []);

    return (
        <DependencyProvider container={dependenciesContainer}>
            <ProviderModuleLoader>
                <Weglot />
                <BrandingBar history={props.history} />
                <div style={{ position: 'relative', flexGrow: '1', flexShrink: '1' }} {...handlers}>
                    <Router history={props.history}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <PurchaseProvider>
                            <RouteEventHelper />
                            <DebugOverlays />
                            <SampleTextOverlay />
                            <SampleButtonOverlay />
                            <CloudshelfSettings />
                            {props.children}
                        </PurchaseProvider>
                    </Router>
                </div>
                <PreviewBar />
                <CustomCSS />
            </ProviderModuleLoader>
        </DependencyProvider>
    );
});

export default BaseApp;
