import i18n from '../I18n/I18n';
import { CurrencyCode } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export const DEFAULT_CURRENCY_CODE = CurrencyCode.Gbp;

export default class CurrencyService {
    private static _currencyCode: CurrencyCode;

    private static _priceFormatter: Intl.NumberFormat;

    static setup() {
        CurrencyService._currencyCode = DEFAULT_CURRENCY_CODE;
        CurrencyService._priceFormatter = new Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: CurrencyService._currencyCode,
        });
    }

    static get currencyCode(): CurrencyCode {
        return this._currencyCode ?? DEFAULT_CURRENCY_CODE;
    }

    static setCurrencyCode(currencyCode: CurrencyCode) {
        if (currencyCode === CurrencyCode.Unknown) {
            console.warn(`[CurrencyService] Ignoring request to set currency code, as the code is unknown`);
            return;
        }
        this._currencyCode = currencyCode;
        this._priceFormatter = new Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: currencyCode,
        });
    }

    static format(price: number): string {
        return this._priceFormatter.format(price);
    }

    static formatRounded(price: number): string {
        return this._priceFormatter
            .formatToParts(price)
            .filter(({ type }) => type !== 'decimal' && type !== 'fraction')
            .map(({ value }) => value)
            .join('');
    }
}
