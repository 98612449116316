import { Observable, Subject } from 'rxjs';
import { injectable } from 'inversify';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import { ProductFilteringService } from '../ProductServices/ProductFilteringService';
import DependencyType from '../../dependancyInjection/DependencyType';
import { Category } from '../CategoryService/entities/Category';

@injectable()
export class EventsService {
    private _openCategory: Category | undefined;
    private _openProduct: string | undefined;
    private _openProductSubject: Subject<string | undefined> = new Subject<string | undefined>();
    private _openCategorySubject: Subject<Category | undefined> = new Subject<Category | undefined>();
    private _routeChanged: Subject<string> = new Subject<string>();
    private _loadingComplete: Subject<void> = new Subject<void>();
    private _filterPanelOpen: Subject<boolean> = new Subject<boolean>();
    private _bannerShouldClose: Subject<void> = new Subject<void>();

    private _salesPersonAllocationOpen: Subject<boolean> = new Subject<boolean>();

    public requestBannersClose() {
        this._bannerShouldClose.next();
    }

    public observeBannerShouldClose(): Observable<void> {
        return this._bannerShouldClose.asObservable();
    }

    public setSalesPersonAllocationOpen(isOpen: boolean) {
        this._salesPersonAllocationOpen.next(isOpen);
    }

    public observeSalesPersonAllocationOpen(): Observable<boolean> {
        return this._salesPersonAllocationOpen.asObservable();
    }

    public setIsFilterPanelOpen(isOpen: boolean) {
        this._filterPanelOpen.next(isOpen);
    }

    public observeIsFilterPanelOpen(): Observable<boolean> {
        return this._filterPanelOpen.asObservable();
    }

    public loadingComplete() {
        this._loadingComplete.next();
    }

    public observeLoadingComplete(): Observable<void> {
        return this._loadingComplete.asObservable();
    }

    public routeChanged(route: string) {
        this._routeChanged.next(route);
    }

    public observeRouteChanged(): Observable<string> {
        return this._routeChanged.asObservable();
    }

    public get openProduct(): string | undefined {
        return this._openProduct;
    }

    public setOpenProduct(productHandle: string | undefined) {
        this._openProduct = productHandle;
        this._openProductSubject.next(productHandle);
    }

    public observeOpenProduct(): Observable<string | undefined> {
        return this._openProductSubject.asObservable();
    }

    public get openCategory(): Category | undefined {
        return this._openCategory;
    }

    public setOpenCategory(category: Category | undefined) {
        this._openCategory = category;

        try {
            const filterService = dependenciesContainer.get<ProductFilteringService>(
                DependencyType.ProductFilteringService,
            );
            if (filterService && (!category || (category && category.handle !== 'INTERNAL_ALL'))) {
                filterService.clearPrice();
            }
        } catch {}

        this._openCategorySubject.next(category);
    }

    public observeOpenCategory(): Observable<Category | undefined> {
        return this._openCategorySubject.asObservable();
    }
}
