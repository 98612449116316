import React, { CSSProperties, forwardRef } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';

export interface CallToActionProps {
    className?: string;
    title?: string;
}

export const CallToAction = forwardRef<HTMLHeadingElement, CallToActionProps>(({ className = '', title }, ref) => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const styleOverrides: CSSProperties = {};

    if (!configService.config()?.theme.attractScreen.displayFrame) {
        styleOverrides.color = '#ffffff';
    }

    if (!title) {
        return null;
    }

    return (
        <h1
            className={`CallToAction CallToAction--${
                configService.config()?.theme.attractScreen.callToActionSize
            } ${className} NeverTranslate`}
            style={styleOverrides}
        >
            {title}
        </h1>
    );
});

CallToAction.displayName = 'CallToAction';
