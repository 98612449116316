import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const CardPaymentIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="334" height="298" viewBox="0 0 334 298" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="32.6841"
                y="70.0563"
                width="264"
                height="171"
                rx="21"
                transform="rotate(-15.1128 32.6841 70.0563)"
                fill="white"
                stroke="black"
                stroke-width="2"
            />
            <rect
                x="215.992"
                y="104.454"
                width="66"
                height="30"
                rx="7"
                transform="rotate(-15.1128 215.992 104.454)"
                stroke="black"
                stroke-width="2"
            />
            <rect
                x="41.9922"
                y="100.689"
                width="264"
                height="28"
                transform="rotate(-15.1128 41.9922 100.689)"
                fill="black"
            />
            <path d="M230.054 110.497L241.871 119.735" stroke="black" stroke-width="2" />
            <path d="M238.742 108.15L233.182 122.081" stroke="black" stroke-width="2" />
            <path d="M245.983 106.195L257.801 115.433" stroke="black" stroke-width="2" />
            <path d="M254.672 103.848L249.112 117.78" stroke="black" stroke-width="2" />
            <path d="M262.396 101.762L274.213 111.001" stroke="black" stroke-width="2" />
            <path d="M271.084 99.4155L265.524 113.347" stroke="black" stroke-width="2" />
            <rect x="1" y="125.819" width="264" height="171" rx="21" fill="white" stroke="black" stroke-width="2" />
            <path d="M50 191.819V200.319" stroke="black" stroke-width="2" />
            <rect x="29" y="170.819" width="43" height="30" rx="4" stroke="black" stroke-width="2" />
            <rect x="41" y="179.819" width="19" height="13" stroke="black" stroke-width="2" />
            <path d="M50 171.319V179.819" stroke="black" stroke-width="2" />
            <path d="M41 185.819H30" stroke="black" stroke-width="2" />
            <path d="M71 185.819H60" stroke="black" stroke-width="2" />
            <path
                d="M234 173.819C236.5 177.986 240 188.819 234 198.819"
                stroke="black"
                stroke-width="3.4"
                stroke-linecap="round"
            />
            <path
                d="M228.5 176.819C228.5 176.819 235 185.819 228.5 195.819"
                stroke="black"
                stroke-width="3.4"
                stroke-linecap="round"
            />
            <path
                d="M223.5 179.819C225 182.319 226 187.819 223.5 193.319"
                stroke="black"
                stroke-width="3.4"
                stroke-linecap="round"
            />
            <path
                d="M219 182.819C219 182.819 221 185.819 219 189.319"
                stroke="black"
                stroke-width="3.4"
                stroke-linecap="round"
            />
            <path d="M28 233.819H237" stroke="black" stroke-width="2" />
            <path d="M28 257.819H237" stroke="black" stroke-width="2" />
        </svg>
    );
};
export default CardPaymentIcon;
