import { FC, ReactElement } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

export interface ConditionalWrapperProps {
    existenceCondition: boolean;
    wrapperCondition: boolean;
    wrapper: (children: ReactElement | ReactElement[] | null) => ReactElement;
    children: ReactElement | ReactElement[] | null;
}
export const ConditionalWrapper: FunctionalComponentWithChildren<ConditionalWrapperProps> = props => {
    if (!props.existenceCondition) {
        return null;
    } else {
        return props.wrapperCondition ? props.wrapper(props.children) : <>{props.children}</>;
    }
};
