import { ProductWorkerWrapper } from './Product.Worker';

let productWorkerInstance: ProductWorkerWrapper | null = null;

export const productWorker = () => {
    if (productWorkerInstance === null) {
        productWorkerInstance = new ProductWorkerWrapper();
    }
    return productWorkerInstance;
};
