import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import NonResponsiveStyledText, {
    NonResponsiveTextSize,
    NonResponsiveTextStyle,
} from '../StyledText/NonResponsiveStyledText';
import BaseServiceScreen from '../ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface ErrorPageProps {
    title?: string;
    subtitle?: string;
    name?: string;
    variant:
        | 'notFoundCloudshelf'
        | 'deviceNoCloudshelf'
        | 'noLocation'
        | 'custom'
        | 'unknown'
        | 'noConnection'
        | 'frozen'
        | 'maintenance'
        | 'unknownError';
    exception?: Error;
}

const FullPageErrorComponent: FunctionalComponentWithChildren<ErrorPageProps> = props => {
    const { t } = useTranslation();

    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    if (props.variant === 'unknownError') {
        return (
            <BaseServiceScreen>
                <div className={'FullPageError_error_title'}>
                    {!_.isEmpty(configService.config()?.device?.owner.name ?? '') && (
                        <div className={'FullPageError_error_retailer'}>
                            <NonResponsiveStyledText
                                style={NonResponsiveTextStyle.Cloudshelf}
                                size={NonResponsiveTextSize.Small}
                                center
                                className={'FullPageError_white'}
                            >
                                {configService.config()?.device?.owner.name}
                            </NonResponsiveStyledText>
                        </div>
                    )}

                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.CloudshelfBold}
                        size={NonResponsiveTextSize.Large}
                        center
                        translate
                        className={'FullPageError_white'}
                    >
                        Oops
                    </NonResponsiveStyledText>

                    <br />
                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.Cloudshelf}
                        size={NonResponsiveTextSize.ExtraSmall}
                        center
                        translate
                        className={'FullPageError_white'}
                    >
                        {props.subtitle ? (
                            <span dangerouslySetInnerHTML={{ __html: props.subtitle }}></span>
                        ) : (
                            <>
                                An unknown error occurred.
                                <br />
                                Please restart this screen.
                            </>
                        )}
                    </NonResponsiveStyledText>
                    {props.exception && (
                        <>
                            <br />
                            <NonResponsiveStyledText
                                style={NonResponsiveTextStyle.Cloudshelf}
                                size={NonResponsiveTextSize.ExtraExtraSmall}
                                center
                                translate
                                className={'FullPageError_white'}
                            >
                                {props.exception.name} : {props.exception.message}
                            </NonResponsiveStyledText>
                        </>
                    )}
                </div>
            </BaseServiceScreen>
        );
    }

    let title = '';
    const subtitles: string[] = [];
    if (props.variant === 'noLocation') {
        // title = t('error_page.titleNoLocation');
        // subtitles.push(t('error_page.subtitleNoLocation'));
        title = 'Specify Location';
        subtitles.push('Allocate this device to a location via the manager app');
    } else if (props.variant === 'notFoundCloudshelf') {
        title = t('error_page.titleNotFound');
        subtitles.push(t('error_page.subtitleNotFound'));
    } else if (props.variant === 'deviceNoCloudshelf') {
        // title = t('error_page.titleDeviceNoCloudshelf');
        // subtitles.push(t('error_page.subtitleDeviceNoCloudshelf'));
        title = 'Choose Cloudshelf';
        subtitles.push('Choose a Cloudshelf to allocate to this device via the manager app');
    } else if (props.variant === 'noConnection') {
        title = t('error_page.titleNoConnection');
        subtitles.push(t('error_page.subtitleNoConnection'));
    } else if (props.variant === 'custom') {
        title = props.title ?? '';
        subtitles.push(props.subtitle ?? '');
    } else if (props.variant === 'frozen') {
        title = t('error_page.titleFrozen');
        subtitles.push(t('error_page.subtitleFrozen'));
    } else if (props.variant === 'maintenance') {
        title = t('error_page.titleMaintenance');
        subtitles.push(t('error_page.subtitleMaintenance'));
        subtitles.push(t('error_page.subtitle2Maintenance'));
    } else {
        title = t('error_page.title');
        subtitles.push(t('error_page.subtitle'));
    }

    return (
        <BaseServiceScreen>
            <div className={'FullPageError_error_title'}>
                {!_.isEmpty(configService.config()?.device?.owner.name ?? '') && (
                    <div className={'FullPageError_error_retailer'}>
                        <br />
                        <NonResponsiveStyledText
                            style={NonResponsiveTextStyle.Cloudshelf}
                            size={NonResponsiveTextSize.ExtraExtraSmall}
                            center
                            className={'FullPageError_white'}
                        >
                            {configService.config()?.device?.owner.name}
                        </NonResponsiveStyledText>
                    </div>
                )}

                <NonResponsiveStyledText
                    style={NonResponsiveTextStyle.CloudshelfBold}
                    size={NonResponsiveTextSize.Small}
                    center
                    translate
                    className={'FullPageError_white'}
                >
                    <span dangerouslySetInnerHTML={{ __html: title }}></span>
                </NonResponsiveStyledText>

                {_.map(subtitles, subtitle => (
                    <NonResponsiveStyledText
                        style={NonResponsiveTextStyle.Cloudshelf}
                        size={NonResponsiveTextSize.ExtraSmall}
                        center
                        translate
                        className={'FullPageError_white'}
                        key={subtitle}
                    >
                        <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
                    </NonResponsiveStyledText>
                ))}
            </div>
        </BaseServiceScreen>
    );
};

export default FullPageErrorComponent;
