import { inject, injectable } from 'inversify';
import DependencyType from '../../dependancyInjection/DependencyType';
import { SessionManagementService } from './SessionManagementService';
import * as Sentry from '@sentry/react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LogUtil } from '../../utils/Logging.Util';
import { getDeviceInfo } from '../../hooks/UseDeviceInfo';
import _ from 'lodash';
import { DeviceService } from '../ConfigurationService/DeviceService';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';

@injectable()
export class TileClicksService {
    constructor(
        @inject(DependencyType.DeviceService)
        private deviceService: DeviceService,
        @inject(DependencyType.SessionManagementService)
        private sessionManagementService: SessionManagementService,
        @inject(DependencyType.ApolloClient) private readonly apolloClient: ApolloClient<NormalizedCacheObject>,
        @inject(DependencyType.ConfigurationService) private configService: ConfigurationService,
    ) {}

    public async reportTileClicks(powerTileId: string): Promise<boolean> {
        return false;
        //
        // const sessionId = this.sessionManagementService.currentSessionId;
        //
        // if (_.includes(['cached', '.', undefined], sessionId)) {
        //     return false;
        // }
        //
        // if (this.configService.status() === CloudshelfConfigStatus.DeviceWithCloudshelf) {
        //     const deviceInfo = getDeviceInfo();
        //     const deviceId = deviceInfo.id;
        //
        //     LogUtil.Log('Reporting tile click');
        //     this.apolloClient
        //         .mutate<TileClick>({
        //             mutation: ReportTileClicksDocument,
        //             variables: { deviceId, sessionId, powerTileId },
        //         })
        //         .catch(e => {
        //             Sentry.captureException(e, {
        //                 extra: {
        //                     operationName: 'reportTileClicks',
        //                 },
        //             });
        //         });
        // }
        // return true;
    }
}
