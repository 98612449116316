import React, { useEffect } from 'react';
import makeTrashable from 'trashable';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import CurrencyService, { DEFAULT_CURRENCY_CODE } from '../../../../../services/CurrencyService/CurrencyService';
import * as Sentry from '@sentry/react';
import { StoreService } from '../../../../../services/StoreService/StoreService';
import { convertCurrencyCode } from '../../../../../utils/convertCurrencyCode';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { ECommercePlatform } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { dependenciesContainer } from '../../../../../dependancyInjection/DependenciesInitializer';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const CloudshelfSettings: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    if (configService.config()?.eCommercePlatform !== ECommercePlatform.Shopify) {
        return <></>;
    }

    const shopifyStoreService = dependenciesContainer.get<StoreService>(DependencyType.StoreService);

    const currencyService = new CurrencyService();
    useEffect(() => {
        const promise = makeTrashable(shopifyStoreService.fetchStoreSettings());

        promise
            .then(settings => {
                CurrencyService.setCurrencyCode(convertCurrencyCode(settings.paymentSettings.currencyCode));
            })
            .catch(err => {
                Sentry.captureException(err, {
                    extra: {
                        operationName: 'cloudshelfSettings useEffect',
                    },
                });
                CurrencyService.setCurrencyCode(DEFAULT_CURRENCY_CODE);
            });

        return promise.trash;
    }, [currencyService, shopifyStoreService]);

    return <></>;
};

export default CloudshelfSettings;
