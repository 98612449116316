import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import DependencyType from '../../../dependancyInjection/DependencyType';
import {
    UpsertDraftOrdersDocument,
    UpsertDraftOrdersMutation,
    UpsertDraftOrdersMutationVariables,
} from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import CurrencyService from '../../CurrencyService/CurrencyService';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { Basket } from '../../BasketService/Basket.type';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';

export async function reportBasketToCloudshelf(
    existingCloudshelfOrderId: string | undefined,
    platformProvidedId: string | undefined,
    basket: Basket,
): Promise<string | null> {
    const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const apolloClient = dependenciesContainer.get<ApolloClient<InMemoryCache>>(DependencyType.ApolloClient);
    const sessionService = dependenciesContainer.get<SessionManagementService>(DependencyType.SessionManagementService);

    let sessionId = sessionService.sanitizedSessionId;

    if (sessionId === 'cached') {
        sessionId = undefined;
    }

    const mutationResult = await apolloClient.mutate<UpsertDraftOrdersMutation, UpsertDraftOrdersMutationVariables>({
        mutation: UpsertDraftOrdersDocument,
        variables: {
            cloudshelfId: configService.cloudshelfId,
            input: [
                {
                    id: existingCloudshelfOrderId,
                    thirdPartyId: platformProvidedId,
                    sessionId,
                    lines: basket.lineItems.map(item => {
                        return {
                            currencyCode: CurrencyService.currencyCode,
                            productID: item.product.id,
                            productVariantID: item.variant.id,
                            quantity: item.quantity,
                            price: item.variant.price,
                        };
                    }),
                },
            ],
        },
    });

    if ((mutationResult.data?.upsertDraftOrders.orders ?? []).length > 0) {
        const order = mutationResult.data?.upsertDraftOrders.orders[0];
        if (order) {
            return order.id;
        }
    }

    return null;
}
