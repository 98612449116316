import ReactDOM from 'react-dom';
import { injectable } from 'inversify';
import PromiseModal from '../../components/shared/PromiseModal/PromiseModal';

@injectable()
export class ToastService {
    showPromiseGlass(promiseToGlass: Promise<any>) {
        const promiseModalRoot = document.getElementById('promise-modal');

        ReactDOM.render(<PromiseModal round promise={promiseToGlass} />, promiseModalRoot);
    }
}
