import { FC, useEffect } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { useLocation } from 'react-router';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const RouteEventHelper: FunctionalComponentWithChildren = () => {
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const location = useLocation();

    useEffect(() => {
        eventsService.routeChanged(location.pathname);
    }, [location]);

    useEffect(() => {
        eventsService.routeChanged(window.location.pathname);
    }, []);

    return null;
};

export default RouteEventHelper;
