import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const HomeIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 36L12 28.8889M12 28.8889L40 4L68 28.8889M12 28.8889V64.4444C12 66.4081 13.7909 68 16 68H28C30.2091 68 32 66.4081 32 64.4444V47.2C32 45.2363 33.7909 43.6444 36 43.6444H44C46.2091 43.6444 48 45.2363 48 47.2V64.4444C48 66.4081 49.7909 68 52 68H64C66.2091 68 68 66.4081 68 64.4444V28.8889M68 28.8889L76 36"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default HomeIcon;
