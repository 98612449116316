import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const ShrinkIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_122_3487)">
                <path
                    d="M43.2598 9.64272C42.4029 8.78578 41.014 8.78578 40.1571 9.64258L34.8058 14.9946L32.7451 12.9339C32.3252 12.514 31.7641 12.2917 31.1937 12.2917C30.9109 12.2917 30.6262 12.3458 30.3539 12.4583C29.5333 12.7979 29 13.5977 29 14.4848V23.7465C29.0008 24.7179 29.7376 25.4514 30.646 25.4514H39.9692C40.8563 25.4514 41.6577 24.9169 41.9957 24.0975C42.3363 23.2769 42.1478 22.3342 41.52 21.707L39.4593 19.6462L44.8106 14.2942C45.6675 13.4379 45.6675 12.049 44.8107 11.1921L43.2598 9.64272Z"
                    fill="white"
                />
                <path
                    d="M11.1933 44.8085C12.0503 45.6654 13.4392 45.6654 14.2961 44.8086L19.6473 39.4565L21.7081 41.5173C22.1279 41.9372 22.6891 42.1594 23.2594 42.1594C23.5422 42.1594 23.827 42.1053 24.0992 41.9928C24.9198 41.6533 25.4531 40.8535 25.4531 39.9664V30.7046C25.4524 29.7333 24.7155 28.9997 23.8072 28.9997H14.4839C13.5968 28.9997 12.7954 29.5343 12.4575 30.3537C12.1168 31.1743 12.3053 32.1169 12.9331 32.7442L14.9938 34.805L9.64253 40.157C8.78561 41.0133 8.78561 42.4022 9.64239 43.2591L11.1933 44.8085Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_122_3487"
                    x="-3.81304"
                    y="0"
                    width="61.6261"
                    height="61.6261"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.81304" />
                    <feGaussianBlur stdDeviation="1.90652" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_122_3487" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_122_3487" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
export default ShrinkIcon;
