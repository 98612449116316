import { injectable } from 'inversify';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getDeviceInfo } from '../../hooks/UseDeviceInfo';
import { getCloudshelfInfo } from '../../hooks/UseCloudshelfInfo';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { getFileFromDataURL, getMimetypeFromDataUrl } from '../../utils/DataURL.Util';
import { CloudflareImageService } from '../CloudflareImageService/CloudflareImageService';
import {
    CancelHandoffDocument,
    CancelHandoffMutation,
    CancelHandoffMutationVariables,
    CreateMobileHandoffDocument,
    CreateMobileHandoffMutation,
    CreateMobileHandoffMutationVariables,
    GetMobileHandoffDocument,
    GetMobileHandoffQuery,
    GetMobileHandoffQueryVariables,
    MobileHandoff,
    SetHandoffImageUrlDocument,
    SetHandoffImageUrlMutation,
    SetHandoffImageUrlMutationVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export type EngineHandoff = Omit<MobileHandoff, 'cloudshelf'>;

@injectable()
export class HandoffService {
    constructor(
        private readonly apolloClient: ApolloClient<InMemoryCache>,
        private readonly cloudflareImageService: CloudflareImageService,
        private readonly configService: ConfigurationService,
    ) {}

    async createHandoff(cloudshelfId: string, productHandle: string, productOptionId: number): Promise<EngineHandoff> {
        const { data, errors } = await this.apolloClient.mutate<
            CreateMobileHandoffMutation,
            CreateMobileHandoffMutationVariables
        >({
            mutation: CreateMobileHandoffDocument,
            variables: {
                cloudshelfId,
                productHandle,
                productOptionId,
            },
        });

        if ((errors && Array.isArray(errors)) || !data?.createMobileHandoff) {
            throw new Error('Failed to create handoff');
        }

        return data.createMobileHandoff;
    }

    async getHandoff(id: string): Promise<EngineHandoff> {
        const { data, errors, error } = await this.apolloClient.query<
            GetMobileHandoffQuery,
            GetMobileHandoffQueryVariables
        >({
            query: GetMobileHandoffDocument,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
        });

        if (!!error || (errors && Array.isArray(errors)) || !data.getMobileHandoff) {
            throw new Error('Failed to get handoff');
        }

        return data.getMobileHandoff;
    }

    async setHandoffImageUrl(id: string, imageUrl: string): Promise<EngineHandoff> {
        const { data, errors } = await this.apolloClient.mutate<
            SetHandoffImageUrlMutation,
            SetHandoffImageUrlMutationVariables
        >({
            mutation: SetHandoffImageUrlDocument,
            variables: {
                id,
                imageUrl,
            },
        });

        if ((errors && Array.isArray(errors)) || !data?.setHandoffImageUrl) {
            throw new Error('Failed to set handoff image');
        }

        return data.setHandoffImageUrl;
    }

    async uploadImageForHandoff(dataUrl: string): Promise<string> {
        const handoffId = this.getHandoffId();
        const mimeType = getMimetypeFromDataUrl(dataUrl);
        const presignedUrl = await this.cloudflareImageService.getPresignedCustomisationUrl(mimeType);
        if (!presignedUrl) {
            throw new Error('Failed to get presigned url');
        }

        const file = getFileFromDataURL(dataUrl);

        const success = await this.cloudflareImageService.putFile(presignedUrl, file);
        if (!success) {
            throw new Error('Failed to upload image to object storage');
        }

        let trimmedUrl = presignedUrl.split('?')[0];
        trimmedUrl = trimmedUrl.replace('//upload.', '//');
        trimmedUrl = trimmedUrl + '/public';

        await this.setHandoffImageUrl(handoffId, trimmedUrl);
        return trimmedUrl;
    }

    async cancelHandoff(id: string): Promise<EngineHandoff> {
        const { data, errors } = await this.apolloClient.mutate<CancelHandoffMutation, CancelHandoffMutationVariables>({
            mutation: CancelHandoffDocument,
            variables: {
                id,
            },
        });

        if ((errors && Array.isArray(errors)) || !data?.cancelHandoff) {
            throw new Error('Failed to cancel handoff');
        }

        return data.cancelHandoff;
    }

    getHandoffId(): string {
        const handoffId = getCloudshelfInfo(getDeviceInfo()).cloudshelfOrDeviceId;
        if (!handoffId) {
            throw new Error('No handoff id!');
        }
        return atob(handoffId);
    }
}
