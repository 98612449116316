import i18n from '../services/I18n/I18n';
import * as Locales from 'date-fns/locale';
import { format } from 'date-fns';

export class DateUtils {
    static getLocale() {
        const i18Lang = i18n.language;
        const browserLanguage = i18Lang.split('-')[0];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Locales[browserLanguage] ?? Locales.enUS;
    }
    static format(date: Date, _format: string) {
        return format(date, _format, { locale: this.getLocale() });
    }
}
