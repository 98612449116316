import { CheckoutSupportedPaymentMethod } from '../Checkout.Supported.Payment.Method.enum';
import { BaseCheckoutService } from './BaseCheckoutService';
import { PossibleTrackedURL } from '../../TrackedURLService/TrackedURL.type';
import { Basket } from '../../BasketService/Basket.type';
import { Checkout, CheckoutStyle } from '../Checkout.type';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import { ShopifyDependencyTypes } from '../../../provider/shopify/di/ShopifyDependencyTypes';
import {
    CardBrand,
    CartAddLineItemDocument,
    CartAddLineItemMutation,
    CartAddLineItemMutationVariables,
    CartCreateDocument,
    CartCreateMutation,
    CartCreateMutationVariables,
    CartDetailsFragment,
    CartRemoveLineItemDocument,
    CartRemoveLineItemMutation,
    CartRemoveLineItemMutationVariables,
    CartUpdateDiscountCodesDocument,
    CartUpdateDiscountCodesMutation,
    CartUpdateDiscountCodesMutationVariables,
    CartUpdateLineItemDocument,
    CartUpdateLineItemMutation,
    CartUpdateLineItemMutationVariables,
    DigitalWallet,
    GetPaymentMethodsDocument,
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables,
} from '../../../provider/shopify/graphql/generated/shopify_types';
import { ShopifyCheckoutMode } from './ShopifyCheckoutMode.type';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { BasketService } from '../../BasketService/BasketService';
import * as _ from 'lodash';
import { LogUtil } from '../../../utils/Logging.Util';
import { CloudshelfTrackedURLService } from '../../TrackedURLService/CloudshelfTrackedURLService';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';
import { reportBasketToCloudshelf } from '../helpers/ReportToCloudshelf';
import toast, { Themes } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/failure.css';
import { StorageKey } from '../../StorageService/StorageKeys.enum';
import { StorageService } from '../../StorageService/StorageService';
import { OrderAttributeUtils } from '../../../utils/OrderAttribute.Utils';
import { QrCheckoutDestination } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export class ShopifyBasketCheckoutService extends BaseCheckoutService {
    private existingCloudshelfOrderId: string | undefined = undefined;
    private _cartInfo: CartDetailsFragment | undefined = undefined;
    private _shopifyCheckoutMode: ShopifyCheckoutMode = ShopifyCheckoutMode.Permalink;
    private readonly ITEMS_PLACEHOLDER = '{{items}}';

    constructor(
        _basketService: BasketService,
        _configurationService: ConfigurationService,
        _storageService: StorageService,
    ) {
        super(_basketService, _configurationService, CheckoutStyle.QR, _storageService);
    }

    clear() {
        super.clear();
        this._cartInfo = undefined;
        this.existingCloudshelfOrderId = undefined;
    }

    get supportsCoupons() {
        return true;
    }

    async setCouponCode(couponCode: string): Promise<boolean> {
        if (this._shopifyCheckoutMode !== ShopifyCheckoutMode.Cart || !this._cartInfo) {
            return false;
        }

        const newCheckout: Checkout = {
            ...this.checkout,
        };

        const shopifyClient = dependenciesContainer.get<ApolloClient<InMemoryCache>>(
            ShopifyDependencyTypes.ShopifyApolloClient,
        );

        const { data, errors } = await shopifyClient.mutate<
            CartUpdateDiscountCodesMutation,
            CartUpdateDiscountCodesMutationVariables
        >({
            mutation: CartUpdateDiscountCodesDocument,
            variables: {
                cartId: this._cartInfo.id,
                discountCodes: [couponCode],
            },
        });

        if (errors || !data?.cartDiscountCodesUpdate?.cart?.discountCodes) {
            return false;
        }

        const cart = data.cartDiscountCodesUpdate.cart;
        const discounts = cart.discountCodes;

        const codeWasAccepted = discounts.length > 0 && discounts[0].applicable;

        await this.syncCheckoutWithCart(cart, newCheckout);
        return codeWasAccepted;
    }

    async syncCheckoutWithCart(cart: CartDetailsFragment, checkout?: Checkout): Promise<void> {
        const checkoutToUpdate = checkout ?? this.checkout;
        this._cartInfo = cart;

        const discountCodes = cart.discountCodes;
        if (discountCodes.length > 0) {
            const discount = discountCodes[0];
            if (discount.applicable) {
                checkoutToUpdate.appliedCouponCodes = [discount.code];
            } else {
                checkoutToUpdate.appliedCouponCodes = [];
            }
        } else {
            checkoutToUpdate.appliedCouponCodes = [];
        }

        checkoutToUpdate.total = parseFloat(cart.estimatedCost.totalAmount.amount);
        checkoutToUpdate.subTotal = parseFloat(cart.estimatedCost.subtotalAmount.amount);
        checkoutToUpdate.tax = parseFloat(cart.estimatedCost.totalTaxAmount?.amount ?? '0');

        const tax = checkoutToUpdate.tax ?? 0;
        let diffBetweenTotals = checkoutToUpdate.subTotal + tax - checkoutToUpdate.total;

        if (tax !== 0) {
            const totalWithoutTax = parseFloat((checkoutToUpdate.total - tax).toFixed(2));
            const subTotalWithoutTax = checkoutToUpdate.subTotal;

            diffBetweenTotals = totalWithoutTax - subTotalWithoutTax;
        }
        checkoutToUpdate.discount = diffBetweenTotals;
        checkoutToUpdate.loading = false;

        this.propagateChanges(checkoutToUpdate);
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        if (!basket) {
            return;
        }

        if (this.configurationService.isUsingCachedConfig) {
            this._shopifyCheckoutMode = ShopifyCheckoutMode.Permalink;
        } else {
            this._shopifyCheckoutMode = ShopifyCheckoutMode.Cart;
        }

        console.log('[ShopifyBasketCheckoutService] CheckoutMode => ', this._shopifyCheckoutMode);
        if (this._shopifyCheckoutMode === ShopifyCheckoutMode.Permalink) {
            await this.handleBasketChangeForPermalink(basket);
        } else if (this._shopifyCheckoutMode === ShopifyCheckoutMode.Cart) {
            await this.handleBasketChangeForCart(basket);
        }

        this.existingCloudshelfOrderId =
            (await reportBasketToCloudshelf(
                this.existingCloudshelfOrderId,
                this._cartInfo ? this._cartInfo.id : undefined,
                basket,
            )) ?? undefined;
    }

    async handleBasketChangeForPermalink(basket: Basket): Promise<void> {
        //With permalinks, we have no way of knowing if the prices are correct, so we just have to that the ones in the basket are
        const newCheckout: Checkout = {
            ...this.checkout,
            loading: true,
        };
        this.propagateChanges(newCheckout);

        //add up the price of the basket
        let subTotal = 0;
        let total = 0;
        basket.lineItems.forEach(item => {
            subTotal += item.quantity * item.variant.price;
            total += item.quantity * item.variant.price;
        });

        newCheckout.loading = false;
        newCheckout.subTotal = subTotal;
        newCheckout.total = total;
        //We can't handle tax or discounts with permalinks
        newCheckout.tax = 0;
        newCheckout.discount = 0;
        this.propagateChanges(newCheckout);
    }

    async handleBasketChangeForCart(basket: Basket): Promise<void> {
        const newCheckout: Checkout = {
            ...this.checkout,
            loading: true,
        };
        this.propagateChanges(newCheckout);

        console.log('creating cart if needed');
        await this.createCartIfNeeded(basket);
        console.log('Cart created, processing basket', this._cartInfo);
        console.log('processing basket into cart');
        const cart = await this.processBasketIntoCart(basket);
        console.log('basket processed into cart', cart);
        if (!cart) {
            return;
        }
        await this.syncCheckoutWithCart(cart, newCheckout);
    }

    async createCartIfNeeded(basket: Basket): Promise<void> {
        if (this._cartInfo) {
            return;
        }

        const sessionService = dependenciesContainer.get<SessionManagementService>(
            DependencyType.SessionManagementService,
        );

        if (!sessionService) {
            throw new Error('Session service not found');
        }

        const orderExtraDetails = await OrderAttributeUtils.getOrderExtraDetails(
            this.configurationService.config()!,
            this.storageService,
            sessionService,
        );

        const shopifyClient = dependenciesContainer.get<ApolloClient<InMemoryCache>>(
            ShopifyDependencyTypes.ShopifyApolloClient,
        );

        const { errors, data } = await shopifyClient.mutate<CartCreateMutation, CartCreateMutationVariables>({
            mutation: CartCreateDocument,
            variables: {
                input: {
                    attributes: orderExtraDetails.attributes,
                    note: orderExtraDetails.note,
                },
            },
        });

        if (errors || !data?.cartCreate?.cart) {
            console.error('error creating cart', errors);
            return;
        }

        this._cartInfo = data.cartCreate.cart;
    }

    async processBasketIntoCart(basket: Basket): Promise<CartDetailsFragment | undefined> {
        if (!this._cartInfo) {
            return undefined;
        }
        let returnableCartInfo = this._cartInfo;

        const shopifyClient = dependenciesContainer.get<ApolloClient<InMemoryCache>>(
            ShopifyDependencyTypes.ShopifyApolloClient,
        );

        //find all items that are NOT in the cartInfo lines but ARE in the basket, so that we can add them
        const itemsToAdd = basket.lineItems.filter(basketLineItem => {
            const cartLineItem = returnableCartInfo?.lines.nodes.find(lineItem => {
                return (
                    basketLineItem.variant.eCommercePlatformProvidedId === lineItem.merchandise.id &&
                    BasketService.attributesMatchArr(basketLineItem.attributes, lineItem.attributes)
                );
            });

            return cartLineItem === undefined;
        });

        if (itemsToAdd.length > 0) {
            const { data, errors } = await shopifyClient.mutate<
                CartAddLineItemMutation,
                CartAddLineItemMutationVariables
            >({
                mutation: CartAddLineItemDocument,
                variables: {
                    cartId: returnableCartInfo.id,
                    lines: itemsToAdd.map(item => {
                        return {
                            attributes: item.attributes.map(attr => {
                                return {
                                    key: attr.key,
                                    value: attr.value,
                                };
                            }),
                            merchandiseId: item.variant.eCommercePlatformProvidedId,
                            quantity: item.quantity,
                        };
                    }),
                },
            });

            if (data?.cartLinesAdd?.cart) {
                returnableCartInfo = data.cartLinesAdd.cart;
            }

            const cartLinesAddErrors = data?.cartLinesAdd?.userErrors ?? [];
            if (cartLinesAddErrors.length > 0) {
                let niceErrorMessage = '';

                niceErrorMessage = cartLinesAddErrors.map(error => error.message).join(' ');

                toast(niceErrorMessage, { position: 'center', theme: 'failure' });
                await this.forceUpdateLocalBasket(data?.cartLinesAdd?.cart ?? undefined);
            }
        }

        //find all items that ARE in the cartInfo lines but are NOT in the basket, so that we can remove them
        const itemsToRemove = returnableCartInfo.lines.nodes.filter(lineItem => {
            const basketLineItem = basket.lineItems.find(basketLineItem => {
                return (
                    basketLineItem.variant.eCommercePlatformProvidedId === lineItem.merchandise.id &&
                    BasketService.attributesMatchArr(basketLineItem.attributes, lineItem.attributes)
                );
            });

            return basketLineItem === undefined;
        });

        if (itemsToRemove.length > 0) {
            const { data, errors } = await shopifyClient.mutate<
                CartRemoveLineItemMutation,
                CartRemoveLineItemMutationVariables
            >({
                mutation: CartRemoveLineItemDocument,
                variables: {
                    cartId: returnableCartInfo.id,
                    lineIds: itemsToRemove.map(item => item.id),
                },
            });

            if (data?.cartLinesRemove?.cart) {
                returnableCartInfo = data.cartLinesRemove.cart;
            }
        }

        //Find all items that are in the basket AND are in the cartInfo lines, so we can update the qty
        const existingLineItems = _.compact(
            returnableCartInfo.lines.nodes.map(lineItem => {
                const basketLineItem = basket.lineItems.find(basketLineItem => {
                    return (
                        basketLineItem.variant.eCommercePlatformProvidedId === lineItem.merchandise.id &&
                        BasketService.attributesMatchArr(basketLineItem.attributes, lineItem.attributes)
                    );
                });

                //we update the line item here so we can use it directly below for ease
                if (!basketLineItem) {
                    return null;
                } else {
                    return {
                        ...lineItem,
                        quantity: basketLineItem.quantity,
                        attributes: basketLineItem.attributes.map(attr => {
                            return {
                                key: attr.key,
                                value: attr.value,
                            };
                        }),
                    };
                }
            }),
        );

        if (existingLineItems.length > 0) {
            const { data, errors } = await shopifyClient.mutate<
                CartUpdateLineItemMutation,
                CartUpdateLineItemMutationVariables
            >({
                mutation: CartUpdateLineItemDocument,
                variables: {
                    cartId: returnableCartInfo.id,
                    lines: existingLineItems.map(item => {
                        return {
                            attributes: item.attributes.map(attr => {
                                return {
                                    key: attr.key,
                                    value: attr.value,
                                };
                            }),
                            merchandiseId: item.merchandise.id,
                            id: item.id,
                            quantity: item.quantity,
                        };
                    }),
                },
            });

            if (data?.cartLinesUpdate?.cart) {
                returnableCartInfo = data.cartLinesUpdate.cart;
            }

            const cartLinesUpdateErrors = data?.cartLinesUpdate?.userErrors ?? [];
            if (cartLinesUpdateErrors.length > 0) {
                let niceErrorMessage = '';

                niceErrorMessage = cartLinesUpdateErrors.map(error => error.message).join(' ');

                toast(niceErrorMessage, { position: 'center', theme: 'failure' });
                await this.forceUpdateLocalBasket(data?.cartLinesUpdate?.cart ?? undefined);
            }
        }

        return returnableCartInfo;
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        const paymentMethods: CheckoutSupportedPaymentMethod[] = [];
        const shopifyClient = dependenciesContainer.get<ApolloClient<InMemoryCache>>(
            ShopifyDependencyTypes.ShopifyApolloClient,
        );
        const { data } = await shopifyClient.query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>({
            query: GetPaymentMethodsDocument,
            variables: {},
        });

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.AmericanExpress)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.AmericanExpress);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.DinersClub)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.DinersClub);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Discover)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.Discover);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Jcb)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.JCB);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Mastercard)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.Mastercard);
        }

        if (data.shop.paymentSettings.acceptedCardBrands.includes(CardBrand.Visa)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.Visa);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.ShopifyPay)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.ShopPay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.ApplePay)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.ApplePay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.GooglePay)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.GooglePay);
        }

        if (data.shop.paymentSettings.supportedDigitalWallets.includes(DigitalWallet.AndroidPay)) {
            paymentMethods.push(CheckoutSupportedPaymentMethod.AndroidPay);
        }

        return paymentMethods;
    }

    async generateURLForOffloadingQR(fulfilmentMethod: string): Promise<PossibleTrackedURL | undefined> {
        //we need to generate a URL that will be used to offload the QR code

        if (
            this._shopifyCheckoutMode === ShopifyCheckoutMode.Permalink ||
            this.configurationService.isUsingCachedConfig ||
            !this._cartInfo
        ) {
            const permalink = this.generatePermalink(this.basketService.basket);
            if (!permalink) {
                return undefined;
            } else {
                LogUtil.Log('[Shopify Basket Checkout Service] Untracked permalink URL: ' + permalink);
                return {
                    url: permalink,
                    isTracked: false,
                };
            }
        } else {
            //we have a cart, and internet connection
            let untrackedCheckoutURL = this._cartInfo.checkoutUrl;

            //Possible change the checkout url:
            const cFlow = this.configurationService.checkoutFlow();

            if (cFlow.paymentQRCodeDestination === QrCheckoutDestination.ShopifyCustom) {
                //https://decathlon.mq/cart/c/Z2NwLWV1cm9wZS13ZXN0MTowMUo2N05IUE1TRlhTQTRWVE02NzRXM0pCWQ?key=7d8cc2657ed6a339da59c4a559629ad4
                const url = new URL(untrackedCheckoutURL);
                const customValue = url.pathname.split('/').pop() + url.search;

                untrackedCheckoutURL = cFlow.paymentQRCodeDestinationShopifyCustomURL?.replace('{key}', customValue);
            }

            const trackedUrlService = dependenciesContainer.get<CloudshelfTrackedURLService>(
                DependencyType.CloudshelfTrackedURLService,
            );
            const sessionService = dependenciesContainer.get<SessionManagementService>(
                DependencyType.SessionManagementService,
            );
            const trackedURL = await trackedUrlService.generateTrackedURL(
                untrackedCheckoutURL,
                sessionService.sanitizedSessionId,
                fulfilmentMethod,
            );

            if (trackedURL) {
                LogUtil.Log('[Shopify Basket Checkout Service] Cart Tracked URL: ' + trackedURL.url);

                return {
                    url: trackedURL.url,
                    isTracked: true,
                    id: trackedURL.id,
                };
            } else {
                LogUtil.Log('[Shopify Basket Checkout Service] Cart Untracked URL: ' + untrackedCheckoutURL);

                return {
                    url: untrackedCheckoutURL,
                    isTracked: false,
                };
            }
        }
    }

    generatePermalink(basket: Basket | undefined): string | undefined {
        if (!basket || basket.lineItems.length === 0) {
            return undefined;
        }

        const configKvps = this.configurationService.providerConfig();
        let domain: string | undefined;
        const domainKvp = configKvps?.find(kvp => kvp.key === 'domain');

        if (domainKvp && domainKvp.values.length > 0) {
            domain = domainKvp.values[0];
        }

        if (!domain) {
            return undefined;
        }

        let storefrontToken: string | undefined;
        const storefrontTokenKvp = configKvps?.find(kvp => kvp.key === 'storefrontToken');

        if (storefrontTokenKvp && storefrontTokenKvp.values.length > 0) {
            storefrontToken = storefrontTokenKvp.values[0];
        }

        if (!storefrontToken) {
            return undefined;
        }

        const cartLink = `https://${domain}/cart/${this.ITEMS_PLACEHOLDER}?access_token=${storefrontToken}`;

        console.log('generatePermalink', basket?.lineItems);
        const itemsPathParam = basket?.lineItems
            .map(
                lineItem =>
                    `${this.extractVariantId(lineItem.variant.eCommercePlatformProvidedId ?? '')}:${lineItem.quantity}`,
            )
            .join(',');
        const url = cartLink.replace(this.ITEMS_PLACEHOLDER, itemsPathParam);
        LogUtil.Log(`[Permalink Gen] Url: ${url}`);
        return url;
    }

    private extractVariantId(variantStorefrontId: string): string {
        LogUtil.Log(variantStorefrontId);
        return variantStorefrontId.substring(variantStorefrontId.lastIndexOf('/') + 1);
    }

    private async forceUpdateLocalBasket(cart: CartDetailsFragment | undefined) {
        if (!cart) {
            this.basketService.empty();
            return;
        }

        let basket = this.basketService.basket;

        if (!basket) {
            return;
        }

        for (let i = 0; i < cart.lines.nodes.length; i++) {
            const line = cart.lines.nodes[i];

            basket = await this.basketService.forceUpdateQuantityOrRemoveWithoutPropagate(
                basket,
                line.merchandise.id,
                _.compact(
                    line.attributes.map(attr => {
                        if (attr.value === null || attr.value === undefined) {
                            return null;
                        }
                        return {
                            key: attr.key,
                            value: attr.value,
                        };
                    }),
                ),
                line.quantity,
            );
        }

        await this.basketService.forcePropagateChanges(basket);
    }

    async getBasketId(): Promise<string | undefined> {
        return this.existingCloudshelfOrderId;
    }
}
