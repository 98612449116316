import DOMPurify from 'dompurify';
import _ from 'lodash';

export class StringUtils {
    static clean(value: string, removeThemeShortcodes?: boolean) {
        let desc = value ?? '';
        if (removeThemeShortcodes) {
            desc = _.replace(desc, /(\[.*?\])/g, '');
        }

        return desc;
    }

    static domSanitize(value: string, removeThemeShortcodes?: boolean) {
        const cleaned = DOMPurify.sanitize(StringUtils.clean(value, removeThemeShortcodes));

        if (_.isEmpty(cleaned)) {
            return null;
        }

        return cleaned;
    }

    static validateEmail(email: string): boolean {
        // Regular expression for validating an Email
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Test the email against the regex
        return regex.test(email);
    }
}
