export function getMimetypeFromDataUrl(dataUrl: string): string {
    return dataUrl.split(',')[0].split(':')[1].split(';')[0];
}

export function getFileFromDataURL(dataURL: string): File {
    const arr = dataURL.split(',');
    const parts = arr[0].match(/:(.*?);/);
    let mime: string;
    if (parts && parts.length >= 1) {
        mime = parts[1];
    } else {
        throw new Error('Invalid data URL');
    }
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'file', { type: mime });
}
