import {
    ChangedConfigCallback,
    ConfigWorkerChangedConfigPayload,
    ConfigWorkerRequest,
    ConfigWorkerSetupParams,
} from './Config.Worker.Types';

export class ConfigWorkerWrapper {
    private worker: Worker;
    private taskId = 0;
    private promises: Record<number, { resolve: (value: any) => void; reject: (reason: any) => void }> = {};
    private changedConfigCallbacks: Record<number, ChangedConfigCallback | null> = {};

    constructor() {
        this.worker = new Worker(
            new URL(/*webpackChunkName: "ConfigWorker"*/ './Config.Worker.Thread.ts', import.meta.url),
        );

        this.worker.onmessage = (e: MessageEvent<any>) => {
            const { id, result, payload, error } = e.data;

            if (payload !== undefined) {
                const typedProgress: ConfigWorkerChangedConfigPayload = payload as ConfigWorkerChangedConfigPayload;
                // Call the progress callback if available
                const callback = this.changedConfigCallbacks[id];
                if (callback) {
                    callback(typedProgress);
                }
                return; // Don't resolve the promise yet, just handle progress
            }

            if (error) {
                this.promises[id].reject(new Error(error));
            } else {
                this.promises[id].resolve(result);
            }

            delete this.promises[id];
            // Note that we don't clean up changedConfigCallbacks as we want these to fire for the lifetime of the app,
            // unlike in the products one where its just to show a loading bar
        };

        this.worker.onerror = error => {
            console.error('Worker error:', error);
        };
    }

    setup(params: ConfigWorkerSetupParams, changedConfigCallback: ChangedConfigCallback) {
        const request: ConfigWorkerRequest = {
            action: 'setup' as const,
            params: {
                ...params,
            },
        };

        return new Promise((resolve, reject) => {
            const id = this.taskId++;

            this.promises[id] = { resolve, reject };
            this.changedConfigCallbacks[id] = changedConfigCallback;

            this.worker.postMessage({ id, request });
        });
    }

    // Optionally: clean up the worker
    terminate() {
        this.worker.terminate();
    }
}
