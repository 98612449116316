import axios from 'axios';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { injectable } from 'inversify';
import {
    GetPresignedCustomisationUrlDocument,
    GetPresignedCustomisationUrlQuery,
    GetPresignedCustomisationUrlQueryVariables,
} from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';

@injectable()
export class CloudflareImageService {
    constructor(private readonly apolloClient: ApolloClient<InMemoryCache>) {}

    public async getPresignedCustomisationUrl(type: string): Promise<string | undefined> {
        try {
            const result = await this.apolloClient.query<
                GetPresignedCustomisationUrlQuery,
                GetPresignedCustomisationUrlQueryVariables
            >({
                query: GetPresignedCustomisationUrlDocument,
                variables: {
                    type,
                },
                fetchPolicy: 'no-cache',
            });

            if (result.error || result.errors || !result.data) {
                return undefined;
            }

            return result.data.getPresignedCustomisationUrl;
        } catch {
            return undefined;
        }
    }

    public async putFile(url: string, file: File): Promise<boolean> {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const result = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return result.status === 200;
        } catch (e) {
            return false;
        }
    }

    public isCloudflareUrl(url: string): boolean {
        return url.startsWith('https://imagedelivery.net');
    }
}
