import React, { CSSProperties, FC, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    PowerTileBackgroundType,
    ContentType,
    ImageAnchor,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import { TileConfig } from '../../../../../services/ConfigurationService/types/config/TileConfig';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface TileItem extends TileConfig {
    onClicked: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface ProductGroupCardProps {
    originalItem: TileItem;
    index: number;
    textSizeModifier?: number;
    imageAnchor: ImageAnchor;
    tileSize: number;
}

const ProductGroupCard: FunctionalComponentWithChildren<ProductGroupCardProps> = props => {
    const [primaryItem, setPrimaryItem] = useState<TileItem | undefined>(undefined);
    const cardClassNames: string[] = ['ProductGroupCard__card'];
    const cardClassStyle: CSSProperties = {};
    const frontClassStyle: CSSProperties = {};
    let primaryItemBranding: JSX.Element | undefined = undefined;
    let primaryItemCTA: JSX.Element | undefined = undefined;
    const frontClassNames: string[] = ['ProductGroupCard__content', 'ProductGroupCard__content__front'];

    const ctaFontStyle: CSSProperties = {
        fontSize: `${(props.textSizeModifier ?? 50) * 0.12}px`,
        lineHeight: `${(props.textSizeModifier ?? 50) * 0.12}px`,
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        primaryItem?.onClicked(e);
    };

    useEffect(() => {
        //set the original primary item
        if (props.originalItem.id !== primaryItem?.id) {
            setPrimaryItem(props.originalItem);
        }
    }, []);

    useEffect(() => {
        setPrimaryItem(props.originalItem);
    }, [props.originalItem]);

    if (primaryItem) {
        if (primaryItem.backgroundType === PowerTileBackgroundType.SolidColour) {
            frontClassStyle.backgroundColor = primaryItem.backgroundPrimaryColor;
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Gradient) {
            frontClassStyle.background = `linear-gradient(${primaryItem.backgroundPrimaryColor}, ${primaryItem.backgroundSecondaryColor})`;
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Image) {
            if (primaryItem.backgroundImage) {
                const url = getSizedImageURL(
                    primaryItem.backgroundImage,
                    props.tileSize,
                    props.tileSize,
                    props.imageAnchor,
                );
                frontClassStyle.backgroundImage = `url(${url})`;
            } else {
                frontClassStyle.backgroundColor = 'var(--primary-color)';
            }
        } else if (primaryItem.backgroundType === PowerTileBackgroundType.Transparent) {
            frontClassStyle.backgroundColor = 'transparent';
        }

        if (primaryItem.type === ContentType.ProductGroup) {
            primaryItemCTA = <>{primaryItem.title}</>;
        } else if (primaryItem.type === ContentType.PowerTile) {
            if (primaryItem.useIcon) {
                primaryItemBranding = <i className={`ProductGroupCard__branding__fontAwesome ${primaryItem.icon}`} />;
            } else {
                primaryItemBranding = <>{primaryItem.title}</>;
            }
            primaryItemCTA = <div className="ProductGroupCard__cta__button">{primaryItem.callToAction}</div>;
        }
    }

    return (
        <div className="ProductGroupCard" onClick={handleClick}>
            <div className={_.join(cardClassNames, ' ')} style={cardClassStyle}>
                <div className={_.join(frontClassNames, ' ')} style={frontClassStyle}>
                    {primaryItemBranding && <div className="ProductGroupCard__branding">{primaryItemBranding}</div>}
                    <div className="ProductGroupCard__overlay" />
                    <div className="ProductGroupCard__ctaContainer">
                        <span className="ProductGroupCard__cta NeverTranslate" style={ctaFontStyle}>
                            {primaryItemCTA}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductGroupCard;
