import React, { FC, useEffect, useRef, useState, ReactElement, CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import { BackButtonMode, MenuService, MenuStyle } from '../../../../../../services/MenuService/MenuService';
import BrandLogo from '../../../../../shared/BrandLogo/BrandLogo';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { TileClicksService } from '../../../../../../services/SessionManagementService/TileClicksService';
import { CategoryService } from '../../../../../../services/CategoryService/CategoryService';
import { Category } from '../../../../../../services/CategoryService/entities/Category';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';
import _ from 'lodash';
import { BackgroundsSwitcher } from '../../BackgroundSwitcher/BackgroundsSwitcher';
import ResponsiveGrid, {
    generateGridProperties,
    ResponsiveGridItemWithKey,
} from '../../../../../shared/ResponsiveGrid/ResponsiveGrid';
import { CallToAction } from '../../CallToAction/CallToAction';
import TouchIndicator from '../../TouchIndicator/TouchIndicator';
import { useComputedStyle } from '../../../../../../hooks/UseComputedStyle';
import { getDeviceDPI } from '../../../../../../index';
import { getMenuHeight } from '../../Menu/Menu';
import { Alignment } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ContentType } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import ProductGroupCard from '../../ProductGroupCard/ProductGroupCard';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import { ProductFilteringService } from '../../../../../../services/ProductServices/ProductFilteringService';
import { NAME_FILTER_ID } from '../../../../../../provider/cloudshelf/filter/CloudshelfFilters';

const NewCategoriesPage: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const categoriesService = useInjection<CategoryService>(DependencyType.CategoryService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const tileClicksService = useInjection<TileClicksService>(DependencyType.TileClicksService);
    const filteringService = useInjection<ProductFilteringService>(DependencyType.ProductFilteringService);
    const history = useHistory();
    const [tileItems, setTileItems] = useState<ResponsiveGridItemWithKey[]>([]);
    // const [firstItemRef, { width: singleRowHeight }] = useMeasure<HTMLDivElement>();
    const gridParentDivRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const pageRef = useRef<HTMLDivElement>(null);
    const [hasSlidIn, setHasSlidIn] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [forceAlwaysOpen, setForceAlwaysOpen] = useState(false);
    const pageHeight = useComputedStyle(pageRef, 'height');
    const gridSizes = generateGridProperties(
        configService.config()!,
        document.body.clientWidth,
        getDeviceDPI(),
        false,
        2,
        10,
        configService.config()?.theme.collectionGridTileModifier,
    );
    const numberRows = tileItems.length / gridSizes.numGridColumns;
    const numberRowsRounded = Math.ceil(numberRows);

    const singleRowHeight = gridSizes.columnSize;
    const expandedSizeGridArea =
        numberRowsRounded * singleRowHeight + Math.ceil(numberRowsRounded + 1) * gridSizes.gutterSize;

    const gridWindowSpace = window.innerHeight * 0.1;

    const expandedRulePartOne = pageHeight - getMenuHeight() - (headerRef.current?.clientHeight ?? 0) - gridWindowSpace;

    let expandedRule = ``;

    if (expandedSizeGridArea >= expandedRulePartOne) {
        expandedRule = `${expandedRulePartOne}px`;
    } else {
        expandedRule = `${expandedSizeGridArea}px`;
    }

    const shouldBeExpanded = forceAlwaysOpen ? true : isExpanded;

    const gridStyles: CSSProperties = {
        height: !shouldBeExpanded ? `${singleRowHeight}px` : expandedRule,
        overflow: shouldBeExpanded ? 'scroll' : 'hidden',
        paddingBottom: shouldBeExpanded ? '2.2vw' : '0px',
    };

    const drawerStyle: CSSProperties = {
        transform: hasSlidIn ? 'unset' : 'translateY(100%)',
        padding: `calc(var(--responsive-reference-point)* .15) calc(var(--responsive-reference-point)* .15)`,
        // padding: !shouldBeExpanded ? '0px 2.2vw 2.2vw' : '0px 2.2vw',
    };

    if (!configService.config()?.theme.attractScreen.displayFrame) {
        drawerStyle.boxShadow = 'none';
        drawerStyle.background = 'transparent';
    }

    useEffect(() => {
        if (singleRowHeight !== 0 && !hasSlidIn) {
            setHasSlidIn(true);
        }
        // setHasSlidIn(true);
    }, [singleRowHeight, hasSlidIn]);

    useEffect(() => {
        menuService.setBackButtonMode(BackButtonMode.NONE);
        menuService.setMenuStyle(MenuStyle.BUTTON_EXPANDABLE_SEARCH);
        menuService.setSearchVisible(true);
        menuService.setFilterButtonVisible(true);
    }, [menuService]);

    useEffect(() => {
        const config = configService.config();
        if (config?.attractLoop.includeBanners || config?.attractLoop.includeProductGroups) {
            setForceAlwaysOpen(true);
        } else {
            setForceAlwaysOpen(false);
        }
    }, [configService]);

    useEffect(() => {
        const cardItems = _.compact(
            _.map(configService.powerTiles, tile => {
                if (tile.type === ContentType.ProductGroup) {
                    if (tile.productGroupIsUpsellContent) {
                        return null;
                    }
                    const category: Category | undefined = categoriesService.getByHandle(tile.handle ?? '');
                    return {
                        ...tile,
                        onClicked: async (e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            await tileClicksService.reportTileClicks(tile.id);

                            filteringService.clearSearchTerm();
                            if (category) {
                                const categoryProductsRoute = RoutesHelperService.toCategoryProducts(category);
                                history.push(categoryProductsRoute);
                            }
                        },
                    };
                } else if (tile.type === ContentType.PowerTile) {
                    return {
                        ...tile,
                        onClicked: async (e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            await tileClicksService.reportTileClicks(tile.id);
                            const powerTileRoute = RoutesHelperService.toPowerTile(tile);
                            history.push(powerTileRoute);
                        },
                    };
                }
            }),
        );

        const items = cardItems.map((powerTile, index) => {
            return {
                element: (
                    <ProductGroupCard
                        key={`categories-list-item-${powerTile.id}`}
                        originalItem={powerTile}
                        index={index}
                        textSizeModifier={singleRowHeight}
                        imageAnchor={configService.imageAnchor}
                        tileSize={gridSizes.columnSize * 2}
                    />
                ),
                size: powerTile.tileSize,
            };
        });

        setTileItems(items);
    }, [singleRowHeight, gridSizes.numGridColumns, tileClicksService, history, configService, isExpanded]);

    const handleToggleExpanded = () => {
        let ruleToUse = expandedRulePartOne;

        if (expandedSizeGridArea < ruleToUse) {
            ruleToUse = expandedSizeGridArea;
        }

        if (ruleToUse > singleRowHeight) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(false);
        }
    };
    return (
        <div className={'CategoriesPage'} onClick={handleToggleExpanded} ref={pageRef}>
            <BrandLogo />
            <BackgroundsSwitcher />
            <div className={'CategoriesPage__drawer'} style={drawerStyle}>
                <div className="CategoriesPage__touchIndicatorWrapper">
                    <TouchIndicator
                    // temp remove the check for hiding the touch indicator only when it was hand pointed
                    // isHidden={
                    //     numberRows <= 1 ||
                    //     (configService.config()?.theme.attractScreen.touchIndicator ===
                    //         TouchIndicatorType.ClassicHandPointer &&
                    //         isExpanded)
                    // }
                    // Never hide the touch indicator, leaving this commented, as I think we will end up reverting it.
                    // isHidden={numberRows <= 1 || shouldBeExpanded}
                    />
                </div>
                <div className="CategoriesPage__drawer__header" ref={headerRef}>
                    <CallToAction
                        className={`CategoriesPage__drawer__header__alignment__${
                            configService.config()?.theme.attractScreen.callToActionAlignment
                        }`}
                        title={configService.config()?.theme.attractScreen.callToAction}
                    />
                </div>
                <div className="CategoriesPage__drawer__content" ref={gridParentDivRef} style={gridStyles}>
                    <ResponsiveGrid
                        additionalClassName="CategoriesPage__GridItem"
                        gridSizes={gridSizes}
                        // firstNoneHeroItemRef={firstItemRef}
                        items={tileItems}
                        setHomeRowHeight
                    />
                </div>
            </div>
        </div>
    );
};

export default NewCategoriesPage;
