export const insertEveryNth = <T>(array: T[], item: T, n: number): T[] => {
    if (n <= 0) {
        console.warn('n must be a positive integer, unable to run... returning original array');
        return array;
    }

    const result: T[] = [];

    for (let i = 0; i < array.length; i++) {
        result.push(array[i]);

        if ((i + 1) % n === 0) {
            result.push(item);
        }
    }

    return result;
};
