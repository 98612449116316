import React, { ReactElement } from 'react';
import Button, { ButtonSize, ButtonVariant } from '../Button/Button';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

export interface IconButtonProps {
    size: ButtonSize;
    icon: ReactElement;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
    disabled?: boolean;
    variant?: ButtonVariant;
}

const IconButton: FunctionalComponentWithChildren<IconButtonProps> = ({
    size,
    icon,
    className,
    onClick,
    loading,
    disabled,
    variant = ButtonVariant.NONE,
}) => {
    return (
        <Button
            disabled={disabled}
            loading={loading}
            size={size}
            className={className}
            onClick={onClick}
            variant={variant}
        >
            <span className={`ButtonIcon ButtonIcon--${size}`}>{icon}</span>
        </Button>
    );
};

export default IconButton;
