import React, { FC, memo } from 'react';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

const GridLoader: FunctionalComponentWithChildren = memo(props => {
    return (
        <div className="GridLoader">
            {[...Array(9)].map((_, i) => (
                <div key={i} className="GridLoader__ball"></div>
            ))}
        </div>
    );
});

export default GridLoader;
