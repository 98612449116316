import { CurrencyCode } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CurrencyCode as ShopifyCurrencyCode } from '../provider/shopify/graphql/generated/shopify_types';

export const convertCurrencyCode = (currencyCode: string | null | undefined): CurrencyCode => {
    //As far as I know, they use the same codes as us... but just in case, ive wrapped this in a util function
    if (!currencyCode) {
        return CurrencyCode.Unknown;
    }

    return currencyCode as CurrencyCode;
};

export const convertCodeToShopify = (currencyCode: CurrencyCode): ShopifyCurrencyCode => {
    if (!currencyCode || currencyCode === CurrencyCode.Unknown) {
        return ShopifyCurrencyCode.Xxx;
    }

    if (currencyCode === CurrencyCode.Gbp) {
        return ShopifyCurrencyCode.Gbp;
    } else if (currencyCode === CurrencyCode.Eur) {
        return ShopifyCurrencyCode.Eur;
    } else if (currencyCode === CurrencyCode.Usd) {
        return ShopifyCurrencyCode.Usd;
    } else if (currencyCode === CurrencyCode.Cad) {
        return ShopifyCurrencyCode.Cad;
    }

    return ShopifyCurrencyCode.Xxx;
};
