import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Routes } from '../../../services/RoutesService/Routes';
import PairDeviceComponent from './components/PairDevice';
import CheckCompatibilityComponent from './components/CheckCompatibility';
import { clearDeviceInfo } from '../../../hooks/UseDeviceInfo';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

const PairingApp: FunctionalComponentWithChildren = () => {
    const pathName = window && window.location ? window.location.pathname : '';
    const cloudshelfOrDeviceName =
        pathName.indexOf('/', 1) === -1 ? pathName : pathName.substring(1, pathName.indexOf('/', 1));

    if (cloudshelfOrDeviceName && (cloudshelfOrDeviceName === '/new' || cloudshelfOrDeviceName === 'new')) {
        const didWipe = clearDeviceInfo();

        if (didWipe) {
            window.location.href = '/';

            return null;
        }
    }

    return (
        <Switch>
            <Route exact path={Routes.DEVICE_COMPATIBILITY}>
                <CheckCompatibilityComponent />
            </Route>
            <Route exact path={Routes.NEW_DEVICE}>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
            <Route exact path={Routes.PAIR_DEVICE}>
                <PairDeviceComponent />
            </Route>
            <Route exact path={Routes.ROOT}>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
            <Route>
                <Redirect to={Routes.DEVICE_COMPATIBILITY} />
            </Route>
        </Switch>
    );
};

export default PairingApp;
