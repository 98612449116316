import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { EventsService } from '../../../../../../services/EventsService/EventsService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import useStateRef from 'react-usestateref';
import { CategoryProductRouteParams } from '../../../../../../services/RoutesService/Routes';
import { RoutesHelperService } from '../../../../../../services/RoutesService/RoutesHelperService';

const ProductOpenerUtil = () => {
    const { product: handle } = useParams<CategoryProductRouteParams>();
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const [openProductHandle, setOpenProductHandle, openProductHandleRef] = useStateRef<string | undefined>(handle);
    const history = useHistory();

    useEffect(() => {
        const openProductObservable = eventsService.observeOpenProduct();

        const subscription = openProductObservable.subscribe(handle => {
            console.log('Observed new product handle');
            setOpenProductHandle(handle);
        });

        const openCategoryObservable = eventsService.observeOpenCategory();

        const subscription2 = openCategoryObservable.subscribe(category => {
            console.log('Observed new collection handle');
            const categoryProductsRoute = RoutesHelperService.toCategoryProducts(category);
            history.push(categoryProductsRoute);
        });

        console.log('ProductOpenerUtil SUBSCRIBED');
        return () => {
            subscription.unsubscribe();
            subscription2.unsubscribe();
        };
    }, []);

    useEffect(() => {
        eventsService.setOpenProduct(openProductHandleRef.current);
    }, [openProductHandle]);

    return null;
};

export default ProductOpenerUtil;
