import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigOverlayComponent from './components/ConfigOverlay/ConfigOverlay';
import VersionOverlayComponent from './components/VersionOverlay/VersionOverlay';
import DisplayModeOverlayComponent from './components/DisplayModeOverlay/DisplayModeOverlay';
import { SpringConfigs } from '../../../../../types/SpringConfigs';
import DeviceOverlayComponent from './components/DeviceOverlay/DeviceOverlay';
import InteractiveOverlayComponent from './components/InteractiveOverlay/InteractiveOverlay';
import OtherOverlayComponent from './components/OtherOverlay/OtherOverlay';
import DisplayOnlyOverlayComponent from './components/DisplayOnlyOverlay/DisplayOnlyOverlay';
import ResponsiveOverlayComponent from './components/ResponsiveOverlay/ResponsiveOverlay';
import ProductBinaryOverlay from './components/ProductBinaryOverlay/ProductBinaryOverlay';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const DebugOverlays: FunctionalComponentWithChildren = () => {
    // const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const [debugOpen, setDebugOpen] = useState(false);
    const releaseType = process.env.REACT_APP_RELEASE_TYPE ?? '';
    const history = useHistory();
    //listen to changes to the window size
    const [windowSize] = useState({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
    });

    const parsedQuery = new URLSearchParams(window.location.search);
    // if parsedQuery has any parameters, we want to redirect to the home page
    if (parsedQuery.get('appearStiffness') !== null) {
        SpringConfigs.appearStiffness = parseFloat(parsedQuery.get('appearStiffness') ?? '100');
        SpringConfigs.appearDamping = parseFloat(parsedQuery.get('appearDamping') ?? '10');
        SpringConfigs.exitStiffness = parseFloat(parsedQuery.get('exitStiffness') ?? '180');
        SpringConfigs.exitDamping = parseFloat(parsedQuery.get('exitDamping') ?? '10');
        SpringConfigs.moveStiffness = parseFloat(parsedQuery.get('moveStiffness') ?? '150');
        SpringConfigs.moveDamping = parseFloat(parsedQuery.get('moveDamping') ?? '10');
        SpringConfigs.staggerSpeed = parseFloat(parsedQuery.get('staggerSpeed') ?? '0.001');
        history.replace('/');
    }

    if (
        releaseType !== 'production' ||
        parsedQuery.get('debugOverlay') !== null
        // ||
        // configService.config()?.forceDebugOverlay ||
        // configService.config()?.device?.debugMode
    ) {
        return (
            <div
                className={`DebugOverlays ${debugOpen ? 'DebugOverlays_darken' : ''}`}
                onClick={() => setDebugOpen(!debugOpen)}
            >
                <div className={'DebugOverlays_inside'}>
                    {debugOpen && (
                        <div className={'DebugOverlays_inside__open'}>
                            <p>
                                Window: {windowSize.innerWidth} x {windowSize.innerHeight}
                            </p>
                            <hr />
                            <ProductBinaryOverlay />
                            <hr />
                            <ResponsiveOverlayComponent />
                            <hr />
                            <DeviceOverlayComponent />
                            <hr />
                            <ConfigOverlayComponent />
                            <hr />
                            <InteractiveOverlayComponent />
                            <hr />
                            <DisplayOnlyOverlayComponent />
                            {/*{configService.deviceMode === EngineType.Interactive ? (*/}
                            {/*    <InteractiveOverlayComponent />*/}
                            {/*) : (*/}
                            {/*    <DisplayOnlyOverlayComponent />*/}
                            {/*)}*/}
                            <hr />
                            <OtherOverlayComponent />
                            <hr />
                            <p>Backend Host: {process.env.REACT_APP_BACKEND_HOST}</p>
                            <hr />
                            <p>User Agent: {navigator.userAgent}</p>
                            <hr />
                        </div>
                    )}
                    <div className={'DebugOverlays_inside__bottom'}>
                        <VersionOverlayComponent />
                        <span />
                        <DisplayModeOverlayComponent />
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default DebugOverlays;
