const DependencyType = {
    AnalyticsService: Symbol.for('AnalyticsService'),
    ApolloClient: Symbol.for('ApolloClient'),
    CategoryAssemblerService: Symbol.for('CategoryAssemblerService'),
    CategoryService: Symbol.for('CategoryService'),
    CloudshelfProductFilterAssemblerService: Symbol.for('CloudshelfProductFilterAssemblerService'),
    FiltersRepository: Symbol.for('FiltersRepository'),
    LocalisationService: Symbol.for('LocalisationService'),
    PriceService: Symbol.for('PriceService'),
    ProductVariantHelperService: Symbol.for('ProductVariantHelperService'),
    StoreService: Symbol.for('StoreService'),
    SessionManagementService: Symbol.for('SessionManagementService'),
    ConfigurationService: Symbol.for('ConfigurationService'),
    DeviceService: Symbol.for('DeviceService'),
    CloudshelfTrackedURLService: Symbol.for('CloudshelfTrackedURLService'),
    ProductFilteringService: Symbol.for('ProductFilteringService'),
    StorageService: Symbol.for('StorageService'),
    EventsService: Symbol.for('EventsService'),
    CloudflareImageService: Symbol.for('CloudflareImageService'),
    TileClicksService: Symbol.for('TileClicksService'),
    HandoffService: Symbol.for('HandoffService'),
    GeoLocationService: Symbol.for('GeoLocationService'),
    MenuService: Symbol.for('MenuService'),
    BasketService: Symbol.for('BasketService'),
    CheckoutService: Symbol.for('CheckoutService'),
    ToastService: Symbol.for('ToastService'),
    PaymentDemoService: Symbol.for('PaymentDemoService'),
    AttractLoopOrchestratorService: Symbol.for('AttractLoopOrchestratorService'),
};

export default DependencyType;
