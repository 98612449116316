import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
const MinusIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="29" height="4" viewBox="0 0 29 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.125" width="29" height="3" fill="white" />
        </svg>
    );
};
export default MinusIcon;
