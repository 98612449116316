import { ulid } from 'ulidx';
import { Alignment, Size } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CloudshelfPrinterBlock } from './types/config/CloudshelfEngineConfig';

export const temp_hardcoded_receipt: CloudshelfPrinterBlock[] = [
    {
        id: ulid(),
        position: 0,
        printerBlockDiscriminator: 'FREE_TEXT',
        size: Size.Large,
        textContent: 'Decathlon',
    },
    {
        id: ulid(),
        position: 1,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: 'IKABAM - DECATHLON MARTINIQUE',
    },
    {
        id: ulid(),
        position: 2,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: '172 Chemin de Californie',
    },
    {
        id: ulid(),
        position: 3,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: '97232 LE LAMENTIN',
    },
    {
        id: ulid(),
        position: 4,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: 'Tel: 05.96.50.00.84',
    },
    {
        id: ulid(),
        position: 5,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 6,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: 'Siret: 42814331700017',
    },
    {
        id: ulid(),
        position: 7,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 8,
        printerBlockDiscriminator: 'FREE_TEXT',
        size: Size.Large,
        textContent: 'Facture',
    },
    {
        id: ulid(),
        position: 9,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 10,
        printerBlockDiscriminator: 'FULFILMENT_DETAILS',
    },
    {
        id: ulid(),
        position: 11,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 12,
        printerBlockDiscriminator: 'FREE_TEXT',
        textContent: ensureSpacing('------------------------------------------------------------', 48),
    },
    {
        id: ulid(),
        position: 13,
        printerBlockDiscriminator: 'ORDER_DETAILS',
    },
    {
        id: ulid(),
        position: 14,
        printerBlockDiscriminator: 'PAYMENT_DETAILS',
    },
    {
        id: ulid(),
        position: 15,
        printerBlockDiscriminator: 'TIMESTAMP',
    },
    {
        id: ulid(),
        position: 16,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 17,
        printerBlockDiscriminator: 'LOCATION_DETAILS',
    },
    {
        id: ulid(),
        position: 18,
        printerBlockDiscriminator: 'DEVICE_DETAILS',
    },
    {
        id: ulid(),
        position: 19,
        printerBlockDiscriminator: 'CLOUDSHELF_DETAILS',
    },
    {
        id: ulid(),
        position: 20,
        printerBlockDiscriminator: 'SALES_ASSISTANT_DETAILS',
    },
    {
        id: ulid(),
        position: 21,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 22,
        printerBlockDiscriminator: 'ORDER_REFERENCE',
    },
    {
        id: ulid(),
        position: 23,
        printerBlockDiscriminator: 'PRECUT',
    },
];

export const temp_hardcoded_collection_slip: CloudshelfPrinterBlock[] = [
    {
        id: ulid(),
        position: 0,
        printerBlockDiscriminator: 'FREE_TEXT',
        size: Size.Large,
        textContent: 'Decathlon',
    },
    {
        id: ulid(),
        position: 1,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 2,
        printerBlockDiscriminator: 'FREE_TEXT',
        size: Size.Large,
        textContent: 'Ticket de collection',
    },
    {
        id: ulid(),
        position: 3,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 4,
        printerBlockDiscriminator: 'LOCATION_DETAILS',
    },
    {
        id: ulid(),
        position: 5,
        printerBlockDiscriminator: 'TIMESTAMP',
    },
    {
        id: ulid(),
        position: 6,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 7,
        printerBlockDiscriminator: 'ORDER_DETAILS',
    },
    {
        id: ulid(),
        position: 8,
        printerBlockDiscriminator: 'SPACER',
    },
    {
        id: ulid(),
        position: 9,
        printerBlockDiscriminator: 'ORDER_REFERENCE',
    },
    {
        id: ulid(),
        position: 10,
        printerBlockDiscriminator: 'PRECUT',
    },
];

export function ensureSpacing(value: string, length: number, frontSpacing = false): string {
    //either trim the value or add spaces to the end to ensure it is the correct length
    if (value.length > length) {
        return value.substring(0, length);
    }

    if (!frontSpacing) {
        return value + ' '.repeat(length - value.length);
    } else {
        return ' '.repeat(length - value.length) + value;
    }
}
