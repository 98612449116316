import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import React, { FC } from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { useTranslation } from 'react-i18next';
import { EngineType } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export const PreviewBar: FunctionalComponentWithChildren = props => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const { t } = useTranslation();
    const isPreview = configService.isInPreviewMode;
    const shouldShowPreviewOverlay =
        process.env.REACT_APP_RELEASE_TYPE === 'development' || process.env.REACT_APP_RELEASE_TYPE === 'production';

    const openCloudshelfWebsite = () => {
        window.open('https://cloudshelf.ai', '_blank');
    };

    return isPreview && shouldShowPreviewOverlay ? (
        <div className={`previewBar ${configService.deviceMode === EngineType.DisplayOnly && 'previewMode__bottom'}`}>
            <StyledText
                className={'previewBar__previewText'}
                style={TextStyle.CloudshelfBold}
                size={TextSize.Large}
                translate
            >
                {t('previewOverlay')}
            </StyledText>
            <div className={'previewLogoArea'} onClick={openCloudshelfWebsite}>
                <img
                    src={'/logos/pink-logo.svg'}
                    alt="Cloudshelf"
                    // height={25}
                    className={'previewBar__logoImage'}
                />
                <StyledText
                    style={TextStyle.CloudshelfBold}
                    size={TextSize.Large}
                    className={'previewBar__logoText'}
                    superNeverTranslate
                >
                    Cloudshelf
                </StyledText>
            </div>
        </div>
    ) : null;
};
