import { v4 } from 'uuid';

export function setManifest(isDevice: boolean, startUrl?: string, themeColor?: string) {
    document.querySelector('#manifest-link')?.setAttribute('href', getManifestBlobUrl(isDevice, startUrl, themeColor));
}

export function getManifestBlobUrl(isDevice: boolean, startUrl?: string, themeColor?: string) {
    const manifest = {
        lang: navigator.languages ? navigator.languages[0] : navigator.language,
        short_name: 'Cloudshelf',
        name: 'Cloudshelf',
        icons: [
            {
                src: `${window.location.origin}/logos/favicon.ico`,
                sizes: '48x48',
                type: 'image/x-icon',
            },
            {
                src: `${window.location.origin}/logos/logo192.png`,
                type: 'image/png',
                sizes: '192x192',
            },
            {
                src: `${window.location.origin}/logos/logo512.png`,
                type: 'image/png',
                sizes: '512x512',
            },
        ],
        start_url: isDevice ? window.location.origin : startUrl ?? '/',
        display: 'standalone',
        theme_color: themeColor ?? '#000000',
        background_color: '#ffffff',
        dynamic: v4(),
    };
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
}
