import React, { FC } from 'react';
import BasketPane from './BasketPane/BasketPane';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { useLocation } from 'react-router';
import { CheckoutExperience } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const SidePane: FunctionalComponentWithChildren = props => {
    const location = useLocation();
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const checkoutExperience = configService.config()?.checkoutExperience;

    if (
        !checkoutExperience ||
        checkoutExperience === CheckoutExperience.None ||
        checkoutExperience === CheckoutExperience.Classic
    ) {
        return null;
    }

    let shouldBeShown = false;

    if (
        location.pathname.endsWith('/products') ||
        (location.pathname.includes('/products/') && location.pathname.startsWith('/categories'))
    ) {
        shouldBeShown = true;
    }

    if (!shouldBeShown) {
        return null;
    }

    //Only let the basket side pane be visible if the useBasketSidePane is true and we are on a certain route

    return (
        <div className={'sidePane'}>
            <div className={'sidePane__content'}>
                {checkoutExperience === CheckoutExperience.Basket && <BasketPane />}
            </div>
        </div>
    );
};

export default SidePane;
