import { FC } from 'react';
import BaseServiceScreen from '../ServiceScreens/BaseServiceScreen/BaseServiceScreen';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export type LoaderVariant = 'light' | 'dark' | 'stars';

export interface LoaderProps {
    variant?: LoaderVariant;
    className?: string;
    text?: string;
    text2?: string;
}

const Loader: FunctionalComponentWithChildren<LoaderProps> = ({ variant = 'light', className = '', text, text2 }) => {
    return variant === 'stars' ? (
        <BaseServiceScreen>
            <div className={`Loader Loader--light ${className}`}>
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
                <div className="Loader__block" />
            </div>
            {text && <p className={'Loader__text'}>{text}</p>}
            {text2 && <p className={'Loader__text'}>{text2}</p>}
        </BaseServiceScreen>
    ) : (
        <div className={`Loader Loader--${variant} ${className}`}>
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
            <div className="Loader__block" />
        </div>
    );
};

export default Loader;
