import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const CSServiceScreenBackground: FunctionalComponentWithChildren = props => {
    return (
        <svg viewBox="0 0 662 730" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M202.5 375.773C229.021 402.425 263.688 417.169 300.439 417.169H515.64C553.906 417.169 590.088 401.669 617.746 373.505C646.351 344.396 662.264 304.323 661.317 263.306C659.801 189.588 615.094 129.668 554.664 114.169C540.266 48.9564 480.973 0 410.123 0C339.463 0 280.359 49.3344 266.151 114.925C204.773 130.802 158.93 192.801 158.93 266.33C158.361 307.348 174.274 347.231 202.5 375.773ZM297.408 179.759H331.886V145.357C331.886 97.5347 371.667 68.8036 410.123 68.8036C448.958 68.8036 488.929 97.1567 488.929 144.601V179.003H523.407C564.893 179.003 591.414 223.423 592.172 264.629C592.551 287.501 584.216 309.049 568.303 325.305C553.716 340.048 534.962 348.176 515.64 348.176H300.439C282.253 348.176 265.014 340.804 251.564 327.195C236.22 311.695 227.506 289.769 227.885 266.519C227.885 218.697 258.952 179.759 297.408 179.759Z"
                fill="url(#paint0_linear_1_500)"
            />
            <path
                d="M586.867 509.224C574.175 504.499 560.915 502.041 547.843 502.041C547.654 502.041 547.654 502.041 547.465 502.041H298.734C298.355 502.041 297.786 502.041 297.408 502.041C212.54 502.041 133.923 454.975 96.983 382.013C95.4675 378.989 93.952 375.964 92.626 372.751C43.3723 265.387 72.7351 137.42 163.665 61.8119C178.252 49.7145 180.335 27.9772 168.022 13.2335C155.898 -1.32106 134.113 -3.40029 119.337 8.88606C4.72711 104.531 -32.2131 265.954 29.9223 401.482C31.6272 405.452 33.5216 409.232 35.4159 413.012C66.2942 474.066 118.958 521.132 181.661 547.595C175.221 556.101 169.916 565.741 165.938 576.137C155.33 604.68 156.466 635.49 169.159 663.276C181.851 691.062 204.583 712.232 233.188 722.818C246.07 727.543 259.52 730 272.97 730C289.262 730 305.364 726.598 320.519 719.604C348.366 706.94 369.583 684.257 380.192 655.715C390.8 626.984 389.285 596.741 377.918 570.845H442.137C441.38 572.546 440.622 574.436 440.054 576.137C429.445 604.68 430.582 635.49 443.274 663.276C455.966 691.062 478.699 712.232 507.304 722.818C520.186 727.543 533.636 730 547.086 730C563.377 730 579.479 726.598 594.634 719.604C622.482 706.94 643.699 684.257 654.307 655.715C676.092 596.93 645.972 531.15 586.867 509.224ZM315.404 631.898C311.236 643.051 302.901 652.124 291.724 657.038C280.737 662.142 268.424 662.52 257.057 658.361C245.881 654.203 236.788 645.886 231.673 634.734C226.558 623.771 226.179 611.484 230.347 600.143C237.166 581.997 254.405 570.656 272.781 570.656C278.085 570.656 283.389 571.601 288.504 573.491C312.184 582.375 324.118 608.46 315.404 631.898ZM589.52 631.898C585.352 643.051 577.017 652.124 565.84 657.038C554.853 662.142 542.539 662.52 531.173 658.361C519.996 654.203 510.903 645.886 505.788 634.734C500.674 623.771 500.295 611.484 504.462 600.143C508.63 588.991 516.965 579.918 528.142 574.814C534.204 571.979 540.645 570.656 547.086 570.656C552.39 570.656 557.694 571.601 562.809 573.491C586.299 582.375 598.234 608.46 589.52 631.898Z"
                fill="url(#paint1_linear_1_500)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1_500"
                    x1="304.907"
                    y1="83.7496"
                    x2="427.132"
                    y2="466.253"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.02" />
                    <stop offset="1" stopColor="white" stopOpacity="0.13" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1_500"
                    x1="192.178"
                    y1="147.233"
                    x2="457.108"
                    y2="771.65"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.02" />
                    <stop offset="1" stopColor="white" stopOpacity="0.13" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default CSServiceScreenBackground;
