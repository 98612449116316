import { injectable } from 'inversify';
import { makeProduct, Product } from '../../../services/ProductServices/Product';
import { ProductListEdgeFragment, ProductListItemFragment } from '../graphql/generated/shopify_types';
import _ from 'lodash';

@injectable()
export class ShopifyProductAssemblerService {
    constructor() {}

    assembleProductsFromShopifyEdge(productEdges?: ProductListEdgeFragment[]): Product[] {
        if (!productEdges || productEdges.length === 0) {
            return [];
        }

        return productEdges.map(({ node: product, cursor }) => {
            return this.assembleProduct(product, cursor);
        });
    }

    assembleProduct(shopifyProduct: ProductListItemFragment, cursor?: string | number): Product {
        const {
            images: { edges: images },
            handle,
            id,
            title,
            priceRange,
            compareAtPriceRange,
            availableForSale,
        } = shopifyProduct;

        // To check availability for current filter selection we need to get the current filter selection (done above)
        // and then find all variants which match that selection. If ANY of those are unavailable, we consider
        // the product to have limited availability. If ALL are unavailable, we consider the product to be out
        // of stock.
        const limitedAvailability = !_.every(shopifyProduct.variants.edges, v => v.node.availableForSale);
        const orderOnly = _.every(shopifyProduct.variants.edges, v => v.node.currentlyNotInStock);

        return makeProduct({
            handle,
            id,
            title,
            minPrice: priceRange.minVariantPrice.amount,
            maxPrice: priceRange.maxVariantPrice.amount,
            minPriceOriginal: compareAtPriceRange.minVariantPrice.amount,
            maxPriceOriginal: compareAtPriceRange.maxVariantPrice.amount,
            images: [],
            // _.map(images, image => ({
            //
            //     src: image.node.url as string,
            //     alt: image.node.altText ?? '',
            // })),
            cursor: cursor !== undefined ? cursor : id,
            availableForSale: availableForSale,
            limitedAvailability: limitedAvailability,
            productType: shopifyProduct.productType,
            orderOnly,
        });
    }
}
