import { CSSProperties, FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { MenuService } from '../../../services/MenuService/MenuService';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import { Size } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

const BrandLogo: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const menuService = useInjection<MenuService>(DependencyType.MenuService);
    const [logoVisible, setLogoVisible] = useState(true);

    const logoStyles: CSSProperties = {
        opacity: logoVisible ? 1 : 0,
        transition: '0.5s opacity ease',
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'flex-start',
    };

    useEffect(() => {
        const sub = menuService.observeExpandedSearchChange().subscribe(expanded => {
            setLogoVisible(!expanded);
        });

        return () => {
            sub.unsubscribe();
        };
    }, [menuService]);

    if (configService.config()?.theme.logoSize === Size.Hidden || _.isEmpty(configService.config()?.theme.logoUrl)) {
        return null;
    }

    const sizeClass = `BrandLogoWrapper__BrandLogo__${configService.config()?.theme.logoSize.toLowerCase()} ${
        configService.config()?.theme.logoForceWhite ? 'BrandLogoWrapper__BrandLogo__White' : ''
    }`;

    return (
        <div className="BrandLogoWrapper">
            <div
                className={`BrandLogoWrapper__Sized BrandLogoWrapper__Sized__${configService
                    .config()
                    ?.theme.logoSize.toLowerCase()}`}
            >
                <div style={logoStyles}>
                    <img src={configService.config()?.theme.logoUrl} alt="logo" className={sizeClass} />
                </div>
            </div>
        </div>
    );
};

export default BrandLogo;
