import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
const ChevronIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5L14 12L9 19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default ChevronIcon;
