import { useEffect } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

const CustomCSS: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    useEffect(() => {
        const observer = configService.observe();

        const subscription = observer.subscribe(() => {
            updateCSS();
        });
        updateCSS();
        return () => subscription.unsubscribe();
    }, []);

    const updateCSS = () => {
        const config = configService.config();

        if (!config?.allowCustomCSS) {
            return;
        }

        const customCSS = config?.customCSS || '';
        const style = document.getElementById('cloudshelf-custom-css');
        if (style) {
            style.innerHTML = customCSS;
        } else {
            const styleElement = document.createElement('style');
            styleElement.id = 'cloudshelf-custom-css';
            styleElement.innerHTML = customCSS;
            document.head.appendChild(styleElement);
        }
    };
    return null;
};

export default CustomCSS;
