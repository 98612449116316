import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { TouchRipple } from './components/TouchRipple/TouchRipple';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface TouchIndicatorProps {
    className?: string;
    isHidden?: boolean;
    rotate?: number;
}

const TouchIndicator: FunctionalComponentWithChildren<TouchIndicatorProps> = ({
    className = '',
    isHidden = false,
    rotate,
}) => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    if (isHidden) {
        return null;
    }

    return <TouchRipple className={className} />;
};

export default TouchIndicator;
