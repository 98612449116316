import React, { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

const ExpandIcon: FunctionalComponentWithChildren = props => {
    return (
        <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_122_3485)">
                <path
                    d="M23.71 28.7415C22.8531 27.8846 21.4642 27.8845 20.6073 28.7413L15.256 34.0934L13.1953 32.0327C12.7754 31.6128 12.2143 31.3905 11.6439 31.3905C11.3611 31.3905 11.0763 31.4446 10.8041 31.5571C9.98354 31.8966 9.4502 32.6965 9.4502 33.5836V42.8453C9.45097 43.8167 10.1878 44.5502 11.0962 44.5502H20.4194C21.3065 44.5502 22.1079 44.0157 22.4459 43.1962C22.7865 42.3756 22.598 41.433 21.9702 40.8057L19.9095 38.745L25.2608 33.3929C26.1177 32.5367 26.1177 31.1478 25.2609 30.2908L23.71 28.7415ZM42.8432 9.4502H33.52C32.6329 9.4502 31.8315 9.98472 31.4935 10.8042C31.1529 11.6247 31.3414 12.5674 31.9691 13.1947L34.0299 15.2554L28.6786 20.6075C27.8217 21.4644 27.8217 22.8533 28.6786 23.7103L30.2293 25.261C31.0862 26.1179 32.4751 26.1179 33.332 25.261L38.6833 19.9089L40.744 21.9697C41.3716 22.5963 42.3118 22.7813 43.1344 22.4452C44.0155 22.1054 44.5502 21.3033 44.5502 20.3572V11.0338C44.5502 10.1865 43.8167 9.4502 42.8432 9.4502Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_122_3485"
                    x="-3.81304"
                    y="0"
                    width="61.6261"
                    height="61.6261"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.81304" />
                    <feGaussianBlur stdDeviation="1.90652" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_122_3485" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_122_3485" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
export default ExpandIcon;
