import React, { CSSProperties, useMemo } from 'react';
import Loader from '../../Loader/Loader';
import TextTransition from 'react-text-transition';
import { FunctionalComponentWithChildren } from '../../../../FCWithChildren';

export enum ButtonSize {
    SM = 'small',
    MD = 'medium',
    LG = 'large',
    AUTO = 'auto',
}

export enum ButtonCorners {
    SQUARED = 'squaredCorners',
    ROUNDED = 'roundedCorners',
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GREY = 'grey',
    DARKGREY = 'darkgrey',
    WHITE = 'white',
    GREENFLAT = 'greenflat',
    BLUE = 'blue',
    NONE = 'none',
}

export enum ButtonOutlineVariant {
    PRIMARY = 'primary',
    CLEARALL = 'clearall',
    GREENFLAT = 'greenflat',
    NONE = 'none',
}

export type OnClicked = () => void;

export interface ButtonProps {
    text?: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
    corners?: ButtonCorners;
    disabled?: boolean;
    className?: string;
    outline?: ButtonOutlineVariant;
    onClick?: OnClicked;
    style?: CSSProperties;
    loading?: boolean;
    showLoader?: boolean;
    extraPadding?: boolean;
    useTransition?: boolean;
    translate?: boolean;
    useContrastColor?: boolean;
}

const Button: FunctionalComponentWithChildren<ButtonProps> = ({
    text = '',
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.MD,
    corners = ButtonCorners.ROUNDED,
    disabled = false,
    className = '',
    outline = ButtonOutlineVariant.NONE,
    onClick,
    children,
    style,
    loading,
    showLoader,
    extraPadding = false,
    useTransition = false,
    translate = false,
    useContrastColor = false,
}) => {
    const buttonClasses = useMemo(() => {
        const classes = [];
        if (outline !== ButtonOutlineVariant.NONE) {
            classes.push(`Button--outline--${outline}`);
        }
        classes.push(`Button--${size}`);
        classes.push(`Button--${corners}`);
        classes.push(`Button--${variant}`);
        if (extraPadding) {
            classes.push('Button--extraPadding');
        }

        if (translate) {
            classes.push('WeglotTranslate');
        } else {
            classes.push('NeverTranslate');
        }

        classes.push(className);
        return classes.join(' ');
    }, [size, corners, className, outline, variant]);

    return (
        <button
            className={`Button ${buttonClasses}`}
            onClick={onClick}
            type="button"
            disabled={disabled || loading}
            style={style}
        >
            {showLoader && loading && (
                <div style={{ position: 'absolute' }}>
                    <Loader variant={variant === ButtonVariant.PRIMARY ? 'light' : 'dark'} />
                </div>
            )}

            {children}
            {text && useTransition && (
                <TextTransition className={`Button__text ${useContrastColor ? 'StyledText__ContrastColor' : ''}`}>
                    {text}
                </TextTransition>
            )}
            {text && !useTransition && (
                <span className={`Button__text ${useContrastColor ? 'StyledText__ContrastColor' : ''}`}>{text}</span>
            )}
        </button>
    );
};

export default Button;
