import _ from 'lodash';

export function canSmoothScrollIntoView() {
    const webkitVersion = (window as any).webkitVersion;
    const isIOSChrome = window.navigator.userAgent.match('CriOS');
    const isIos =
        _.includes(['iPhone', 'iPad', 'iPod'], window.navigator.platform) ||
        (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints === 5);
    const isStandalone =
        (window.navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;

    const isMobileSafari = () => {
        const hasSafariUserAgent = /^((?!chrome|android|edg).)*safari/i.test(navigator.userAgent);
        const hasApplePay = typeof (window as any).ApplePaySession !== 'undefined';
        return hasSafariUserAgent && hasApplePay && isIos;
    };

    if ((isIOSChrome && isIos) || (isIos && isStandalone) || (isIos && isMobileSafari())) {
        if (webkitVersion !== undefined) {
            return webkitVersion >= '605.2';
        } else {
            return false;
        }
    } else {
        //We can assume all other browser are supporting smooth scroll because they are not created by apple...
        return true;
    }
}

export function smoothScrollWrapper(element: HTMLElement | undefined | null, options: ScrollIntoViewOptions) {
    if (element !== undefined && element !== null) {
        if (canSmoothScrollIntoView()) {
            try {
                element.scrollIntoView(options);
            } catch {
                //Any browsers other than apple ones that do not support scrollIntoView options will throw an error,
                //and then we can scroll into view using the default old-style method.
                element.scrollIntoView(true);
            }
        } else {
            element.scrollIntoView(true);
        }
    }
}
