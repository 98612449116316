import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';
export interface ErrorCircleProps {
    animate?: boolean;
}

const circleVariants = {
    start: {
        pathLength: 0,
        fill: 'argb(255, 255, 255, 0)',
    },
    end: {
        pathLength: 1,
        fill: '#7C0A02',
        transition: {
            fill: { duration: 0.5, ease: 'easeInOut', delay: 0.85 },
            pathLength: { duration: 1, ease: 'easeInOut' },
        },
    },
};

const crossVariants = {
    start: {
        pathLength: 0,
        fill: 'argb(255, 255, 255, 0)',
    },
    end: {
        pathLength: 1,
        fill: '#FFFFFF',
        transition: {
            pathLength: { duration: 1, ease: 'easeInOut' },
            fill: { duration: 0.5, ease: 'easeInOut', delay: 0.85 },
        },
    },
};

const ErrorCircle: FunctionalComponentWithChildren<ErrorCircleProps> = props => {
    if (props.animate) {
        return (
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 170 170"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth={4}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                overflow="inherit"
            >
                <motion.rect
                    width="170"
                    height="170"
                    rx="85"
                    fill="#7C0A02"
                    variants={circleVariants}
                    initial="start"
                    animate="end"
                />
                <motion.path
                    d="M42.5 127.5L127.5 42.5M42.5 42.5L127.5 127.5"
                    variants={crossVariants}
                    initial="start"
                    animate="end"
                    strokeWidth={12}
                />
            </motion.svg>
        );
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" fill="none">
            <rect width="170" height="170" rx="85" fill="#7C0A02" />
            <path
                d="M42.5 127.5L127.5 42.5M42.5 42.5L127.5 127.5"
                stroke="#FFF"
                strokeWidth="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ErrorCircle;
