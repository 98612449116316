import { FC, memo } from 'react';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router';
import { PageViewTracker } from '../../../services/Analytics/components/PageViewTracker';
import { CategoryProductsPage } from './components/pages/products/CategoryProductsPage';
import { PowerTilePage } from './components/pages/powertile/PowerTilePage';
import { FiltersView } from '../../../services/ProductServices/filters/FiltersView';
import ProductDetailsView from './components/ProductDetailsView/ProductDetailsView';
import * as Sentry from '@sentry/react';
import { Routes } from '../../../services/RoutesService/Routes';
import { SessionManager } from '../../../services/SessionManagementService/components/SessionManager';
import SetupWrapper from './components/SetupWrapper/SetupWrapper';
import { BrandingPage } from './components/pages/brandingPage/BrandingPage';
import { Menu } from './components/Menu/Menu';
import AttractSearch from './components/AttractSearch/AttractSearch';
import NewCategoriesPage from './components/pages/categories/CategoriesPage';
import SalesPersonPanelNew from './components/SalesPersonPanel/SalesPersonPanelNew';
import { BarcodeInput } from '../../shared/BarcodeInput/BarcodeInput';
import SidePane from './components/SidePane/SidePane';
import { useInjection } from '../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { CheckoutExperience } from '../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import NewBannerPlayer from './components/NewBannerPlayer/NewBannerPlayer';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';
import ProductOpenerUtil from './components/ProductDetailsView/utils/ProductViewOpener';

const InteractiveApp: FunctionalComponentWithChildren = memo(() => {
    const Route = Sentry.withSentryRouting(ReactRouterRoute);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const isCatalogMode = configService.config()?.checkoutExperience === CheckoutExperience.None;

    return (
        <SetupWrapper>
            <div className={'InteractiveApp__container'}>
                {/*<PromiseModal*/}
                {/*    promise={new Promise((resolve, reject) => setTimeout(() => reject(1), 5000))}*/}
                {/*    round={true}*/}
                {/*/>*/}
                <>
                    {/*Here we place modals that we want to use the full screen as their parent*/}
                    <FiltersView />
                    <SalesPersonPanelNew />
                </>
                <div className={'InteractiveApp__container__pageContent'}>
                    {/*Here we place the content that should respect any possible side panels*/}
                    <Menu />
                    <ProductDetailsView hideCartOptions={isCatalogMode} />
                    <SessionManager>
                        <PageViewTracker>
                            <Switch>
                                <Route exact path={Routes.BY_CLOUDSHELF}>
                                    <BrandingPage />
                                </Route>
                                <Route exact path={Routes.POWER_TILE}>
                                    <PowerTilePage />
                                </Route>
                                <Route exact path={Routes.CATEGORIES}>
                                    <NewCategoriesPage />
                                </Route>
                                <Route exact path={[Routes.CATEGORY_PRODUCTS, Routes.CATEGORY_PRODUCT]}>
                                    <CategoryProductsPage />
                                </Route>
                                <Route exact path={Routes.ROOT}>
                                    <Redirect to={Routes.CATEGORIES} />
                                </Route>
                            </Switch>
                        </PageViewTracker>
                        <NewBannerPlayer />
                        <AttractSearch />
                        <BarcodeInput />
                        <ProductOpenerUtil />
                    </SessionManager>
                </div>
                <SidePane />
            </div>
        </SetupWrapper>
    );
});

export default InteractiveApp;
