import { useEffect, FC, useState } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import useStateRef from 'react-usestateref';
import { EventsService } from '../../../../../services/EventsService/EventsService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import { getSizedImageURL } from '../../../../../utils/ImageURL.Util';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export const BackgroundsSwitcher: FunctionalComponentWithChildren = () => {
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const eventsService = useInjection<EventsService>(DependencyType.EventsService);
    const [onScreenImageIndex, setOnScreenImageIndex] = useState(0);
    const [backgrounds, setBackgrounds] = useState<string[]>([]);
    const [, setTimeouts, timeoutsRef] = useStateRef<NodeJS.Timeout[]>([]);

    useEffect(() => {
        const openCategoryObservable = eventsService.observeOpenCategory();
        const subscriber = openCategoryObservable.subscribe(category => {
            if (category !== undefined) {
                if (category.isInternalAllCategory && !category.useImageOverride) {
                    setBackgrounds([]);
                } else {
                    let imageForOpenCollection = _.compact([category.image]);
                    imageForOpenCollection = _.map(imageForOpenCollection, img => `${getSizedImageURL(img)}`);
                    setBackgrounds(imageForOpenCollection);
                }
            } else {
                let bgs = _.compact(
                    _.map(configService.categories, collection =>
                        collection.isInternalAllCategory && !collection.useImageOverride ? null : collection.image,
                    ),
                );
                bgs = _.map(bgs, img => `${getSizedImageURL(img)}`);
                setBackgrounds(bgs);
            }
        });

        return () => {
            subscriber?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        //Setup the background images if we need them
        if (backgrounds.length === 0) {
            let images: string[] = _.compact(
                _.map(configService.categories, collection =>
                    collection.isInternalAllCategory && !collection.useImageOverride ? null : collection.image,
                ),
            );
            images = _.map(images, img => `${getSizedImageURL(img)}`);
            setBackgrounds(images);
        }

        return () => {
            //Cleanup the timeouts
            _.forEach(timeoutsRef.current, timeout => {
                clearTimeout(timeout);
            });
        };
    }, []);

    const showNextItem = () => {
        //Cleanup the timeouts
        _.forEach(timeoutsRef.current, timeout => {
            clearTimeout(timeout);
        });

        const timeout = setTimeout(() => {
            updateImageIndex();
        }, 8000);

        setTimeouts([timeout]);
    };

    const updateImageIndex = () => {
        let nextOnScreenImageIndex = onScreenImageIndex + 1;
        if (nextOnScreenImageIndex + 1 > backgrounds.length) {
            nextOnScreenImageIndex = 0;
        }
        setOnScreenImageIndex(nextOnScreenImageIndex);
    };

    if (backgrounds.length === 0) {
        return null;
    }

    return (
        <div className="BackgroundSwitcher">
            {backgrounds.map((imageUrl, index) => (
                <CSSTransition
                    key={`BackgroundSwitcher-Animated-Background-${index}`}
                    in={index === onScreenImageIndex}
                    timeout={{
                        exit: 500,
                        enter: 500,
                    }}
                    appear
                    unmountOnExit
                    mountOnEnter
                    classNames="BackgroundSwitcher__Item BackgroundSwitcher__Item"
                    onEntered={showNextItem}
                >
                    <div
                        className={`BackgroundSwitcher__Item__Image ${
                            backgrounds.length === 1 ? 'BackgroundSwitcher__Item__Image__Static' : ''
                        }`}
                        style={{
                            background: `url(${imageUrl})`,
                        }}
                    />
                </CSSTransition>
            ))}
        </div>
    );
};
