import React from 'react';
import { ProductVariantAvailability } from '../../../../../services/ProductServices/ProductVariantAvailability';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import AutoSizedStyledText, { AutoSizedValues } from '../../../../shared/StyledText/AutoSizedStyledText';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';

export interface StockLabelProps {
    availability?: ProductVariantAvailability;
    enforcedTextSize?: AutoSizedValues;
}

const StockLabel: FunctionalComponentWithChildren<StockLabelProps> = props => {
    const configurationService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const limited = _.trim(configurationService.config()?.limitedAvailabilityLabel || '');
    const inStock = _.trim(configurationService.config()?.inStockLabel || '');
    const soldOut = _.trim(configurationService.config()?.soldOutLabel || '');
    const onOrder = _.trim(configurationService.config()?.outOfStockLabel || '');
    const defaultLabelSoldOut = 'Sold Out';
    const defaultLabelLimited = 'Limited Selection';
    const defaultLabelOnOrder = 'On Order';
    const defaultLabelInStock = 'In Stock';
    let label;
    const classNames = ['StockLabel'];
    let isCustomLabel = false;

    if (props.enforcedTextSize) {
        classNames.push(`StockLabel__inheritFont`);
    }
    if (props.availability === ProductVariantAvailability.Unavailable) {
        if (configurationService.config()?.displaySoldOutLabel) {
            label = soldOut || defaultLabelSoldOut;
            if (label !== defaultLabelSoldOut) {
                isCustomLabel = true;
            }
            classNames.push('StockLabel__backOrder');
        }
    } else if (props.availability === ProductVariantAvailability.LimitedAvailability) {
        if (configurationService.config()?.displayLimitedLabel) {
            label = limited || defaultLabelLimited;
            if (label !== defaultLabelLimited) {
                isCustomLabel = true;
            }
            classNames.push('StockLabel__limited');
        }
    } else if (props.availability === ProductVariantAvailability.OnOrder) {
        if (configurationService.config()?.displayOutOfStockLabel) {
            label = onOrder || defaultLabelOnOrder;
            if (label !== defaultLabelOnOrder) {
                isCustomLabel = true;
            }
            classNames.push('StockLabel__inStock');
        }
    } else if (props.availability === ProductVariantAvailability.InStock) {
        if (configurationService.config()?.displayInStockLabel) {
            label = inStock || defaultLabelInStock;
            if (label !== defaultLabelInStock) {
                isCustomLabel = true;
            }
            classNames.push('StockLabel__inStock');
        }
    }

    if (label === undefined || label.trim() === '') {
        return null;
    }

    if (props.enforcedTextSize) {
        return (
            <AutoSizedStyledText
                style={TextStyle.Subheading}
                enforce={props.enforcedTextSize}
                enforcedOnly={true}
                translate={!isCustomLabel}
            >
                <span className={classNames.join(' ')}>
                    <i className="fa-solid fa-cart-shopping" />
                    <span>{label}</span>
                </span>
            </AutoSizedStyledText>
        );
    } else {
        return (
            <StyledText
                style={TextStyle.Subheading}
                size={TextSize.Small}
                className={classNames.join(' ')}
                translate={!isCustomLabel}
            >
                <i className="fa-solid fa-cart-shopping" />
                <span>{label}</span>
            </StyledText>
        );
    }
};

export default StockLabel;
