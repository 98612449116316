import * as Sentry from '@sentry/react';
import { Transaction } from '@sentry/types';
import { Span } from '@sentry/tracing';
import '@sentry/tracing';

export class SentryUtil {
    static StartInfoTransaction(
        transactionName: string,
        data?: string,
    ): { newTransaction: Transaction; parentSpan?: Span } {
        return SentryUtil.StartTransaction(transactionName, false, transactionName, data);
    }

    static StartTransaction(
        transactionName: string,
        endExisting = true,
        op = 'api request',
        data?: any,
    ): { newTransaction: Transaction; parentSpan?: Span } {
        // End existing if requested
        const hub = Sentry.getCurrentHub();
        const existing = hub.getScope()?.getTransaction();
        let traceId: string | undefined = undefined;
        let parentId: string | undefined = undefined;
        if (endExisting) {
            SentryUtil.EndExisting();
        } else if (existing) {
            traceId = existing.traceId;
            parentId = existing.spanId;
        }

        // Start new
        const trans = Sentry.startTransaction({
            name: transactionName,
            op: op,
            traceId,
            parentSpanId: parentId,
            data: data,
        });
        SentryUtil.SetCurrent(trans);

        return {
            newTransaction: trans,
            parentSpan: existing,
        };
    }

    static EndExisting() {
        Sentry.getCurrentHub().getScope()?.getTransaction()?.finish();
        SentryUtil.SetCurrent(undefined);
    }

    static EndSpan(span: Span) {
        if (!span) {
            return;
        }
        span?.finish();
        const hub = Sentry.getCurrentHub();
        if (span && span.spanId && hub.getScope()?.getTransaction()?.spanId === span.spanId) {
            hub.configureScope(scope => scope.setSpan(undefined));
        }
    }

    static SetCurrent(span: Span | undefined) {
        const hub = Sentry.getCurrentHub();
        hub.configureScope(scope => scope.setSpan(span));
    }
}
