import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import _ from 'lodash';
import { FC } from 'react';
import DOMPurify from 'dompurify';
import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';

export interface ProductDescriptionProps {
    content?: string;
}

const ProductDescription: FunctionalComponentWithChildren<ProductDescriptionProps> = props => {
    return (
        <div className={'ProductDescription'}>
            <StyledText
                style={TextStyle.Body}
                size={TextSize.Small}
                className={'ProductDescription__AllowDefaultStyles'}
            >
                <span dangerouslySetInnerHTML={{ __html: props.content ?? '' }} />
            </StyledText>
        </div>
    );
};

export default ProductDescription;
