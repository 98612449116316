export function getYoutubeVideoId(url: string): { isYoutube: boolean; videoId: string } {
    const splits = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (splits[2] === undefined) {
        //This will lead to false positives, but it's better than nothing
        if (url.length === 11) {
            return { isYoutube: true, videoId: url };
        } else {
            return { isYoutube: false, videoId: '' };
        }
    } else {
        return { isYoutube: true, videoId: splits[2].split(/[^0-9a-z_\-]/i)[0] };
    }
}
