import React from 'react';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import ButtonIcon from '../../../../shared/inputs/ButtonIcon/ButtonIcon';
import { ButtonSize, ButtonVariant } from '../../../../shared/inputs/Button/Button';
import CloseIcon from '../../../../icons/close';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import ArrowBackIcon from '../../../../icons/arrow_back';

export type ButtonCallback = () => void;

export interface PurchaseHeaderProps {
    bottomBorder?: boolean;
    className?: string;
    onBackButtonClicked?: ButtonCallback;
    onCloseButtonClicked?: ButtonCallback;
    title?: string;
    translate?: boolean;
    leftAlignTitle?: boolean;
    textSize?: TextSize;
}

export const PanelHeader: FunctionalComponentWithChildren<PurchaseHeaderProps> = ({
    onBackButtonClicked,
    onCloseButtonClicked,
    className,
    bottomBorder = false,
    title,
    leftAlignTitle,
    children,
    textSize,
}) => {
    return (
        <div className={`PanelHeader ${className || ''} ${bottomBorder ? 'PanelHeader--withBorder' : ''}`}>
            <div className={`PanelHeader__body ${leftAlignTitle && 'PanelHeader__body__leftAlign'}`}>
                {title ? (
                    <StyledText
                        style={TextStyle.Subheading}
                        size={textSize ?? TextSize.Hero}
                        className="PanelHeader__title"
                        translate
                    >
                        {title}
                    </StyledText>
                ) : (
                    children
                )}
            </div>
            {onBackButtonClicked && (
                <ButtonIcon
                    className="PanelHeader__backButton"
                    icon={<ArrowBackIcon />}
                    size={ButtonSize.AUTO}
                    onClick={onBackButtonClicked}
                />
            )}
            {onCloseButtonClicked && (
                <ButtonIcon
                    className="PanelHeader__closeButton"
                    icon={<CloseIcon />}
                    size={ButtonSize.AUTO}
                    onClick={onCloseButtonClicked}
                />
            )}
        </div>
    );
};
