import { AnalyticsService } from './AnalyticsService';

/**
 * This analytics service does not record anything and is used as a placeholder
 * if no trackers were provided for the active cloudshelf.
 */
export class DisabledAnalyticsService implements AnalyticsService {
    recordEvent(): void {}

    recordPageView(): void {}
}
