import { CSSProperties, FC, memo, useEffect, useRef, useState } from 'react';
import StyledText, { TextSize, TextStyle } from '../StyledText/StyledText';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface AccordionProps {
    title: string;
    titleTextStyle?: TextStyle;
    openByDefault: boolean;
    partialClose?: boolean;
}

const Accordion: FunctionalComponentWithChildren<AccordionProps> = memo(props => {
    const [isOpen, setIsOpen] = useState(props.openByDefault);
    const divRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const panelStyle: CSSProperties = {
        // minHeight: '50px',
        maxHeight: isOpen
            ? height === 0
                ? props.partialClose
                    ? 'calc(var(--responsive-reference-point) * 2)'
                    : 'unset'
                : height
            : props.partialClose
            ? 'calc(var(--responsive-reference-point) * 2)'
            : '0px',
    };

    useEffect(() => {
        recalcHeight();
    }, [divRef]);

    const recalcHeight = () => {
        if (!divRef.current) {
            return;
        }

        if (divRef.current.clientHeight === 0) {
            return;
        }

        const newHeight = divRef.current.clientHeight + 20;
        setHeight(newHeight);
    };

    const handleOpenClose = () => {
        if (height === 0) {
            recalcHeight();
            setTimeout(() => {
                setIsOpen(prev => !prev);
            }, 100);
        } else {
            setIsOpen(prev => !prev);
        }
    };

    return (
        <div className="Accordion">
            <button className={`Accordion__button`} onClick={handleOpenClose}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <StyledText style={props.titleTextStyle ?? TextStyle.Subheading} size={TextSize.Medium}>
                        {props.title}
                    </StyledText>
                    <StyledText style={props.titleTextStyle ?? TextStyle.Subheading} size={TextSize.Medium}>
                        {isOpen ? '-' : '+'}
                    </StyledText>
                </div>
            </button>
            <div className={`Accordion__panel ${isOpen ? 'Accordion__panel__open' : ''}`} style={panelStyle}>
                <div ref={divRef}>{props.children}</div>
            </div>
        </div>
    );
});

export default Accordion;
