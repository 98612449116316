import { EventArgs } from 'react-ga';
import { Observable, Subject } from 'rxjs';
import { CloudshelfEngineConfig } from '../../ConfigurationService/types/config/CloudshelfEngineConfig';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';
import { SessionEventType } from '../../SessionManagementService/SessionEvent';

export class GoogleMetadataService {
    private _configuration: CloudshelfEngineConfig;

    private _sessionManagementService: SessionManagementService;

    private _sessionId: string | undefined;

    private _metadataSubject: Subject<Partial<EventArgs>>;

    constructor(configuration: CloudshelfEngineConfig, sessionManagementService: SessionManagementService) {
        this._configuration = configuration;
        this._sessionManagementService = sessionManagementService;
        sessionManagementService.observe().subscribe(sessionEvent => {
            if (sessionEvent.type === SessionEventType.Started) {
                this._sessionId = sessionEvent.id;
            } else {
                this._sessionId = undefined;
            }
            this._metadataSubject.next({
                ...this.globalMetadata(),
                ...this.sessionMetadata(),
            });
        });
        this._metadataSubject = new Subject<Partial<EventArgs>>();
    }

    private globalMetadata(): Partial<EventArgs> {
        return {
            dimension1: this._configuration.ownerId,
            dimension2: this._configuration.name,
        };
    }

    private sessionMetadata(): Partial<EventArgs> {
        return {
            dimension3: this._sessionId,
        };
    }

    public observe(): Observable<Partial<EventArgs>> {
        return this._metadataSubject.asObservable();
    }
}
