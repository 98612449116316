import React, { FC, memo } from 'react';
import { motion } from 'framer-motion';
import { FunctionalComponentWithChildren } from '../../../FCWithChildren';

export interface GlassModalProps {
    round?: boolean;
    animate?: boolean;
}

const GlassModal: FunctionalComponentWithChildren<GlassModalProps> = memo(props => {
    const classNames = ['GlassModal'];
    if (props.round) {
        classNames.push('GlassModal--round');
    }

    if (props.animate) {
        return (
            <motion.div
                className={classNames.join(' ')}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <motion.div className={'GlassModal__content'}>{props.children}</motion.div>
            </motion.div>
        );
    }
    return (
        <div className={classNames.join(' ')}>
            <div className={'GlassModal__content'}>{props.children}</div>
        </div>
    );
});

export default GlassModal;
