import { ConfigWorkerWrapper } from './Config.Worker';

let configWorkerInstance: ConfigWorkerWrapper | null = null;

export const configWorker = () => {
    if (configWorkerInstance === null) {
        configWorkerInstance = new ConfigWorkerWrapper();
    }
    return configWorkerInstance;
};
