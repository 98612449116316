import { FunctionalComponentWithChildren } from '../../../../../../FCWithChildren';
import StyledText, { TextSize, TextStyle } from '../../../../../shared/StyledText/StyledText';
import { FC } from 'react';

export interface ProductVendorProps {
    content?: string;
}

const ProductVendor: FunctionalComponentWithChildren<ProductVendorProps> = props => {
    return (
        <StyledText style={TextStyle.Body} size={TextSize.Small}>
            {props.content}
        </StyledText>
    );
};

export default ProductVendor;
