import { FC } from 'react';
import { FunctionalComponentWithChildren } from '../../../../../../../FCWithChildren';

export interface TouchRippleProps {
    className?: string;
    bottomPadding?: number;
}

export const TouchRipple: FunctionalComponentWithChildren<TouchRippleProps> = props => {
    return (
        <div className={`${props.className} TouchRipple`} style={{ paddingBottom: '0.75em' }}>
            <div className={'pulse-circle'} />
        </div>
    );
};
