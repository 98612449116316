import React from 'react';

export interface TextInputProps {
    variant: 'dashed' | 'solid';
    initialValue?: string;
    endIconName?: string;
    placeholder?: string;
    disabled?: boolean;
    showSuccessState?: boolean;
    errorText?: string;
    classNames?: string[];
    onChange?: (val: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onClear?: () => void;
    maxLength?: number;
    suffixLabel?: string;
    multiline?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>((props, ref) => {
    const [value, setValue] = React.useState(props.initialValue ?? '');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    };

    const handleClear = () => {
        setValue('');
        props.onClear?.();
        if (props.onChange) {
            props.onChange('');
        }
    };

    return (
        <div className="TextInput">
            {!props.multiline && (
                <input
                    type="text"
                    placeholder={props.placeholder}
                    className={`TextInput__input TextInput__input__${props.variant} ${
                        props.errorText && 'TextInput__input__error'
                    } ${props.showSuccessState && 'TextInput__input__success'} WeglotTranslate`}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    value={value}
                    onChange={handleChange}
                    disabled={props.disabled}
                    ref={ref as React.Ref<HTMLInputElement>}
                    maxLength={props.maxLength}
                />
            )}
            {props.multiline && (
                <textarea
                    placeholder={props.placeholder}
                    className={`TextInput__input TextInput__input__multiline TextInput__input__${props.variant} ${
                        props.errorText && 'TextInput__input__error'
                    } ${props.showSuccessState && 'TextInput__input__success'}`}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    value={value}
                    onChange={handleChange}
                    disabled={props.disabled}
                    ref={ref as React.Ref<HTMLTextAreaElement>}
                    maxLength={props.maxLength}
                />
            )}
            {props.onClear && value !== '' && (
                <span className="TextInput__icon">
                    <i className="fa-regular fa-circle-xmark" onClick={handleClear} />
                </span>
            )}
            {props.endIconName && value === '' && (
                <span className="TextInput__icon">
                    <i className={props.endIconName} />
                </span>
            )}
            {props.errorText && (
                <span className="TextInput__errorText WeglotTranslate">
                    <i className="fa-solid fa-triangle-exclamation" />
                    {props.errorText}
                </span>
            )}
            {props.suffixLabel && <div className="TextInput__suffixLabel">{props.suffixLabel}</div>}
        </div>
    );
});

export default TextInput;
