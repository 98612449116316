import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCallback, PanelHeader } from '../PanelHeader/PanelHeader';
import { PurchaseQrCode } from './PurchaseQrCode';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { CheckoutService } from '../../../../../services/CheckoutService/CheckoutService';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import CardPaymentIcon from '../../../../icons/card_payment';
import {
    AcquisitionType,
    PaymentGenericPayload,
    ThirdPartyOrder,
} from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import {
    GetPaymentTokenResponse,
    PaymentDemoService,
} from '../../../../../services/PaymentDemoService/PaymentDemoService';
import useStateRef from 'react-usestateref';
import { PurchaseStep, usePurchase } from '../../../../../hooks/contexts/Purchase/Purchase';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { AcquisitionOptionUtil } from '../../../../../utils/AcquisitionOption.Util';
import CurrencyService from '../../../../../services/CurrencyService/CurrencyService';
import Button from '../../../../shared/inputs/Button/Button';
import PhoneScanQRIcon from '../../../../icons/phone_scan_qr';
import GridLoader from '../../../../shared/GridLoader/GridLoader';
import { StringUtils } from '../../../../../utils/String.Utils';
import FailedPersonIllustration from '../../../../illustrations /failed_person';
import SuccessPersonIllustration from '../../../../illustrations /success_person';
import _ from 'lodash';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { BasketService } from '../../../../../services/BasketService/BasketService';
import {
    CloudPaymentData,
    CloudshelfBridge,
    RequestPaymentPayload,
    RequestPaymentResponseCallbackPayload,
} from '../../../../../utils/CloudshelfBridge.Utils';
import { StorageService } from '../../../../../services/StorageService/StorageService';
import { StorageKey } from '../../../../../services/StorageService/StorageKeys.enum';
import { GlobalIDUtils } from '../../../../../utils/GlobalID.Util';
import { OrderAttributeUtils } from '../../../../../utils/OrderAttribute.Utils';
import VirtualKeyboardInput from '../../../../shared/VirtualKeyboardInput/VirtualKeyboardInput';
import { dexieDatabase } from '../../../../../services/StorageService/DexieDatabase';

export interface PurchaseViewProps {
    onCloseButtonClicked: ButtonCallback;
}

export const NewPurchaseView: FunctionalComponentWithChildren<PurchaseViewProps> = ({ onCloseButtonClicked }) => {
    const { t } = useTranslation();
    const [hasScannedQrCode, setHasScannedQrCode] = React.useState(false);
    const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);
    const basketService = useInjection<BasketService>(DependencyType.BasketService);
    const paymentDemoService = useInjection<PaymentDemoService>(DependencyType.PaymentDemoService);
    const sessionManagementService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const storageService = useInjection<StorageService>(DependencyType.StorageService);
    const [paymentAttemptId, setPaymentAttemptId, paymentAttemptIdRef] = useStateRef<string | undefined>(undefined);
    const [vivaPaymentToken, setVivaPaymentToken] = useState<GetPaymentTokenResponse | undefined>(undefined);
    const [checkInterval, setCheckInterval, checkIntervalRef] = useStateRef<NodeJS.Timeout | undefined>(undefined);
    const purchaseContext = usePurchase();
    const [paymentType, setPaymentType] = useState<'card' | 'qr' | 'unknown' | undefined>(undefined);
    const [emailForReceipt, setEmailForReceipt] = useState<string | undefined>(undefined);
    const [emailForReceiptSaved, setEmailForReceiptSaved] = useState<boolean>(false);
    const [cardPaymentStatus, setCardPaymentStatus] = useState<
        'not_ready' | 'ready' | 'pending' | 'success' | 'failed' | undefined
    >('not_ready');
    const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);
    const [getToken, setGetToken] = useState<number>(0);
    const [usingOnDevicePayments, setUsingOnDevicePayments] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const paymentSession = sessionManagementService.sanitizedSessionId;

            if (paymentSession === undefined) {
                setCardPaymentStatus('failed');
                setPaymentErrorMessage(t('cardPayments.unableStartInvalidSession'));
                return;
            }

            const checkoutFlow = configService.checkoutFlow();
            if (CloudshelfBridge.isAvailable() && CloudshelfBridge.canUseOnDevicePayments()) {
                //We dont need a payment token as its on device
                setCardPaymentStatus('ready');
                setPaymentErrorMessage(undefined);
                setUsingOnDevicePayments(true);
            } else {
                if (
                    !checkoutFlow.paymentViaCardsProviderSecret ||
                    (checkoutFlow.paymentViaCardsProviderSecret ?? '').trim() === ''
                ) {
                    setCardPaymentStatus('failed');
                    setPaymentErrorMessage(t('cardPayments.unableStartInvalidPaymentProvier'));
                    return;
                } else {
                    const tokenResponse = await paymentDemoService.getPaymentToken(
                        sessionManagementService.sanitizedSessionId!,
                    );
                    setVivaPaymentToken(tokenResponse);

                    console.log('token', tokenResponse.token);
                    if (!tokenResponse.success) {
                        setCardPaymentStatus('failed');
                        setPaymentErrorMessage(tokenResponse.message);
                    } else {
                        setCardPaymentStatus('ready');
                        setPaymentErrorMessage(undefined);
                    }
                }
            }
        })();
    }, [configService, paymentDemoService, getToken]);

    const printReceiptIfPossible = async (
        providerTransactionId: string,
        thirdPartyOrder: ThirdPartyOrder,
        cloudPaymentData?: CloudPaymentData,
    ) => {
        try {
            const flow = configService.checkoutFlow();
            if (flow.purchaseReceiptPrinterBlocks.length > 0) {
                const receiptBlocks = CloudshelfBridge.buildPrinterPayload(
                    cloudPaymentData,
                    flow.purchaseReceiptPrinterBlocks,
                    configService.config()!,
                    await dexieDatabase().getSalesAssociateId(),
                    purchaseContext.checkoutAcquisitionOption!,
                    basketService.basket!,
                    totalPrice,
                    acquisitionCost !== 0,
                    acquisitionCost.toFixed(2),
                    providerTransactionId,
                    thirdPartyOrder,
                );

                if (
                    flow.collectionSlipPrinterBlocks.length > 0 &&
                    purchaseContext.checkoutAcquisitionOption?.acquisitionType === AcquisitionType.StoreCollection
                ) {
                    const slipBlocks = CloudshelfBridge.buildPrinterPayload(
                        cloudPaymentData,
                        flow.collectionSlipPrinterBlocks,
                        configService.config()!,
                        await dexieDatabase().getSalesAssociateId(),
                        purchaseContext.checkoutAcquisitionOption!,
                        basketService.basket!,
                        totalPrice,
                        acquisitionCost !== 0,
                        acquisitionCost.toFixed(2),
                        providerTransactionId,
                        thirdPartyOrder,
                    );
                    receiptBlocks.lines.push(...[...slipBlocks.lines]);
                }

                CloudshelfBridge.printReceipt(receiptBlocks);
            }
        } catch (e) {
            console.error('Error printing receipt', e);
        }
    };

    useEffect(() => {
        const options = configService.checkoutFlow();

        if (purchaseContext.checkoutAcquisitionOption) {
            //we need it.

            const acquisitionType = purchaseContext.checkoutAcquisitionOption.acquisitionType;

            let canUseQR = false;
            let canUseCard = false;

            if (options.allowPaymentsViaCards) {
                for (const option of options.paymentViaCardsAvailableForAcquisitionTypes) {
                    if (option === acquisitionType) {
                        canUseCard = true;
                    }
                }
            }

            if (options.allowPaymentsViaQRCode) {
                for (const option of options.paymentViaQRCodeAvailableForAcquisitionTypes) {
                    if (option === acquisitionType) {
                        canUseQR = true;
                    }
                }
            }

            //todo: should we force use QR?
            // if (!canUseCard) {
            //     //we might still want to force QR
            //     if (
            //         options.qrPaymentAvailability.includes('always') ||
            //         options.qrPaymentAvailability.includes('if_no_other_option')
            //     ) {
            //         canUseQR = true;
            //     }
            // }

            if (canUseCard && canUseQR) {
                setPaymentType('unknown');
            } else if (canUseCard && !canUseQR) {
                setPaymentType('card');
            } else if (canUseQR && !canUseCard) {
                setPaymentType('qr');
            }
        }
    }, [configService, purchaseContext.checkoutAcquisitionOption]);

    console.log('paymentType', paymentType);
    if (!purchaseContext.checkoutAcquisitionOption) {
        //should not be possible
        return null;
    }

    const acquisitionCost = AcquisitionOptionUtil.getAcquisitionCost(
        purchaseContext.checkoutAcquisitionOption,
        checkoutService.totalPriceAsNumber,
    );

    const totalPrice = checkoutService.totalPriceAsNumber + acquisitionCost;

    const handleStartCardPayment = async () => {
        if (cardPaymentStatus === 'pending') {
            return;
        }
        setCardPaymentStatus('pending');

        const paymentSession = sessionManagementService.sanitizedSessionId;

        if (paymentSession === undefined) {
            setCardPaymentStatus('failed');
            setPaymentErrorMessage(t('cardPayments.unableStartInvalidSession'));
            return;
        }

        const basketId = await checkoutService.getBasketId();

        if (!basketId) {
            setPaymentErrorMessage(t('cardPayments.unableStartInvalidBasket'));
            setCardPaymentStatus('failed');
            return;
        }

        let checkoutAcquisitionOptionInternalName = '';

        if (!purchaseContext.checkoutAcquisitionOption) {
            setPaymentErrorMessage(t('cardPayments.unableStartNoAcqOpt'));
            setCardPaymentStatus('failed');
            return;
        } else {
            checkoutAcquisitionOptionInternalName = purchaseContext.checkoutAcquisitionOption.internalName;
        }

        if (CloudshelfBridge.isAvailable() && CloudshelfBridge.canUseOnDevicePayments()) {
            //handle payment on device

            //first we need to set the status to idle (just to sanity check / keep things clean)
            CloudshelfBridge.setCurrentPaymentStatus('idle');

            //setup the callback function
            CloudshelfBridge.setPaymentCallbackFunction(async (payload: RequestPaymentResponseCallbackPayload) => {
                if (payload.success) {
                    const paidOrderResult = await createPaidOrder(
                        basketId,
                        paymentSession,
                        payload.providerTransactionId,
                    );

                    if (paidOrderResult.success) {
                        printReceiptIfPossible(
                            payload.providerTransactionId ?? 'No Transaction ID',
                            paidOrderResult.order!,
                        );
                    }
                } else {
                    CloudshelfBridge.setCurrentPaymentStatus('payment_error');
                    setPaymentErrorMessage(payload.extraData.message ?? 'Unknown error');
                    setCardPaymentStatus('failed');
                }
            });

            let tempPaymentAttemptId: string | undefined = undefined;
            const paymentAttemptIdResult = await paymentDemoService.createAttemptId(
                paymentSession,
                checkoutAcquisitionOptionInternalName,
            );

            if (!paymentAttemptIdResult.success) {
                setPaymentErrorMessage(paymentAttemptIdResult.message ?? t('cardPayments.noAttemptId'));
                setCardPaymentStatus('failed');
                return;
            } else {
                tempPaymentAttemptId = paymentAttemptIdResult.extra ?? undefined;
                if (!tempPaymentAttemptId) {
                    setPaymentErrorMessage(t('cardPayments.noAttemptId'));
                    setCardPaymentStatus('failed');
                    return;
                }
                setPaymentAttemptId(tempPaymentAttemptId);
            }

            const sessionIdExtracted = GlobalIDUtils.stripGlobalID(paymentSession);
            //Then we need to start the payment
            CloudshelfBridge.setCurrentPaymentStatus('payment_pending');
            const requestPaymentPayload: RequestPaymentPayload = {
                money: _.round(totalPrice * 100, 2),
                uniqueTransactionId: `${sessionIdExtracted}_${tempPaymentAttemptId}`,
            };
            CloudshelfBridge.requestPayment(requestPaymentPayload);
        } else {
            //handle payments via cloud apis

            let tempPaymentAttemptId: string | undefined = undefined;
            const paymentAttemptIdResult = await paymentDemoService.createAttemptId(
                paymentSession,
                checkoutAcquisitionOptionInternalName,
            );

            if (!paymentAttemptIdResult.success) {
                setPaymentErrorMessage(paymentAttemptIdResult.message ?? t('cardPayments.noAttemptId'));
                setCardPaymentStatus('failed');
                return;
            } else {
                tempPaymentAttemptId = paymentAttemptIdResult.extra ?? undefined;
                if (!tempPaymentAttemptId) {
                    setPaymentErrorMessage(t('cardPayments.noAttemptId'));
                    setCardPaymentStatus('failed');
                    return;
                }
                setPaymentAttemptId(tempPaymentAttemptId);
            }

            const startPaymentResult = await paymentDemoService.startPaymentRequest(
                paymentSession,
                paymentAttemptIdRef.current!,
                vivaPaymentToken?.token ?? '',
                _.round(totalPrice * 100, 2),
            );

            if (!startPaymentResult.success) {
                setPaymentErrorMessage(startPaymentResult.message ?? t('cardPayments.paymentFailedNoMessage'));
                setCardPaymentStatus('failed');
                return;
            }

            if (checkIntervalRef.current) {
                cancelInterval(checkIntervalRef.current);
            }

            const interval = setInterval(async () => {
                const result: PaymentGenericPayload = await paymentDemoService.getPaymentRequest(
                    paymentSession ?? '',
                    paymentAttemptIdRef.current!,
                    vivaPaymentToken?.token ?? '',
                );
                console.log('result', result);

                if (result.success) {
                    cancelInterval(checkIntervalRef.current);
                    setCheckInterval(undefined);
                    const paidOrderResult = await createPaidOrder(basketId, paymentSession, undefined);
                    if (paidOrderResult.success) {
                        printReceiptIfPossible(result.transactionId ?? 'No Transaction ID', paidOrderResult.order!, {
                            transactionId: result.transactionId ?? '',
                            authorizationId: result.authorizationId ?? '',
                            maskedCardNumber: result.maskedCardNumber ?? '',
                            cardType: result.cardType ?? '',
                            verificationMethod: result.verificationMethod ?? '',
                            tid: result.tid ?? '',
                        });
                    }
                } else {
                    if (result.pending) {
                        console.log('transaction pending');
                    } else if (result.cancelled) {
                        console.log('transaction cancelled');
                        cancelInterval(checkIntervalRef.current);
                        setCheckInterval(undefined);
                        setCardPaymentStatus('failed');
                        setPaymentErrorMessage(result.message ?? '');
                    } else {
                        console.log('transaction failed');
                        cancelInterval(checkIntervalRef.current);
                        setCheckInterval(undefined);
                        setCardPaymentStatus('failed');
                        setPaymentErrorMessage(result.message ?? '');
                    }
                }
            }, 2500);
            setCheckInterval(interval);
        }
    };

    const createPaidOrder = async (basketId: string, paymentUUID: string, onDevicePaymentId: string | undefined) => {
        const attemptId = paymentAttemptIdRef.current;

        if (!attemptId) {
            return { success: false, message: 'No attempt ID' };
        }

        const orderExtraDetails = await OrderAttributeUtils.getOrderExtraDetails(
            configService.config()!,
            storageService,
            sessionManagementService,
        );

        const createPaidOrderResult = await paymentDemoService.createPaidOrder(
            basketId,
            paymentUUID,
            attemptId,
            vivaPaymentToken?.token ?? 'ON-DEVICE-PAYMENT',
            emailForReceipt ?? '',
            purchaseContext.checkoutAcquisitionOption?.id ?? '',
            onDevicePaymentId,
            orderExtraDetails,
        );

        if (createPaidOrderResult.success) {
            setCardPaymentStatus('success');
            return createPaidOrderResult;
        } else {
            // alert('createPaidOrderResult: ' + createPaidOrderResult.message);
            setPaymentErrorMessage(createPaidOrderResult.message ?? '');
            setCardPaymentStatus('failed');
            return createPaidOrderResult;
        }
    };

    const handleCancelCardPayment = async () => {
        if (CloudshelfBridge.isAvailable() && CloudshelfBridge.canUseOnDevicePayments()) {
            //Theres nothing to cancel (the app is handling the payment)
            //but we will set the status to failed
            setCardPaymentStatus('failed');
        } else {
            cancelInterval(checkIntervalRef.current);
            setCheckInterval(undefined);

            const result = await paymentDemoService.abortPaymentRequest(
                sessionManagementService.sanitizedSessionId!,
                paymentAttemptId ?? '',
                vivaPaymentToken?.token ?? '',
            );

            setCardPaymentStatus('failed');
        }
    };

    useEffect(() => {
        if (cardPaymentStatus === 'success') {
            const restartSession = setTimeout(() => {
                sessionManagementService.endSession();
            }, 7500);

            return () => {
                clearTimeout(restartSession);
            };
        }
    }, [cardPaymentStatus]);

    let showCisalfaTempCode = false;
    let showIntersportTempCode = false;
    let showDecathlonTempCode = false;
    const domain = configService.providerConfig().find(k => k.key === 'domain');

    if (domain && domain.values.length > 0) {
        showIntersportTempCode =
            domain.values.includes('intersport-it-prod.myshopify.com') ||
            domain.values.includes('intersport-it-dev.myshopify.com');

        showDecathlonTempCode = domain.values.includes('decathlon-mtq.myshopify.com');

        showCisalfaTempCode = domain.values.includes('https://www.cisalfasport.it/');
    }

    const cancelInterval = (int: NodeJS.Timeout | undefined) => {
        console.log('cancelInterval', int);
        clearInterval(int);
    };

    const getOptionsUI = () => {
        if (paymentType === undefined) {
            return null;
        }

        if (paymentType === 'unknown') {
            //we need to let the user choose
            return (
                <>
                    <div className="NewPurchaseView__stackedOption">
                        <div className="NewPurchaseView__stackedOption__icon">
                            <CardPaymentIcon />
                        </div>
                        <Button
                            className={'NewPurchaseView__stackedOption__button'}
                            onClick={() => setPaymentType('card')}
                            translate
                        >
                            {t('cardPayments.payByCard')}
                        </Button>
                    </div>
                    <div className="NewPurchaseView__stackedOption">
                        <div className="NewPurchaseView__stackedOption__icon">
                            <PhoneScanQRIcon />
                        </div>
                        <Button
                            className={'NewPurchaseView__stackedOption__button'}
                            onClick={() => setPaymentType('qr')}
                            translate
                        >
                            {t('cardPayments.payByQrCode')}
                        </Button>
                    </div>
                </>
            );
        }

        if (paymentType === 'card') {
            return (
                <>
                    <div className="NewPurchaseView__sideStackedOption">
                        <div className="NewPurchaseView__receiptArea">
                            <div className="NewPurchaseView__receiptArea__inner">
                                {!emailForReceiptSaved && (
                                    <StyledText style={TextStyle.Subheading} size={TextSize.Large} translate>
                                        {t('cardPayments.getReceipt')}
                                    </StyledText>
                                )}

                                {!emailForReceiptSaved && <div className="NewPurchaseView__receiptArea__space"></div>}
                                {!emailForReceiptSaved && (
                                    <div>
                                        <StyledText
                                            style={TextStyle.Body}
                                            size={TextSize.Small}
                                            translate
                                            className="NewPurchaseView__receiptArea__inputLabel"
                                        >
                                            {t('cardPayments.email')}
                                        </StyledText>
                                        <VirtualKeyboardInput
                                            className="NewPurchaseView__receiptArea__input"
                                            type={'email'}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            onChange={val => setEmailForReceipt(val)}
                                            isOnScreen
                                            initialValue=""
                                            disabled={emailForReceiptSaved}
                                        />
                                    </div>
                                )}

                                {!emailForReceiptSaved && (
                                    <Button
                                        className={`NewPurchaseView__receiptArea__submitButton ${
                                            !(!StringUtils.validateEmail(emailForReceipt ?? '') || emailForReceiptSaved)
                                                ? 'NewPurchaseView__receiptArea__submitButton__enabled'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            if (!StringUtils.validateEmail(emailForReceipt ?? '')) {
                                                return;
                                            }

                                            setEmailForReceiptSaved(true);
                                        }}
                                        translate
                                        disabled={
                                            !StringUtils.validateEmail(emailForReceipt ?? '') || emailForReceiptSaved
                                        }
                                    >
                                        {t('submit')}
                                    </Button>
                                )}

                                {emailForReceiptSaved && (
                                    <StyledText className="NewPurchaseView__receiptArea__successText" translate>
                                        {t('cardPayments.receiptSent', { email: emailForReceipt })}
                                    </StyledText>
                                )}
                            </div>
                        </div>
                        {(cardPaymentStatus === 'failed' || cardPaymentStatus === 'success') && (
                            <div className="NewPurchaseView__illustrationContainer">
                                <StyledText
                                    className={`NewPurchaseView__${cardPaymentStatus}`}
                                    style={TextStyle.Heading}
                                    size={TextSize.Large}
                                    translate
                                    align={'center'}
                                >
                                    {cardPaymentStatus === 'failed' && t('cardPayments.failed')}
                                    {cardPaymentStatus === 'success' && t('cardPayments.success')}
                                </StyledText>
                                <StyledText
                                    className={`NewPurchaseView__${cardPaymentStatus}`}
                                    style={TextStyle.Subheading}
                                    size={TextSize.Medium}
                                    translate
                                    align={'center'}
                                >
                                    {cardPaymentStatus === 'success' && t('cardPayments.thankYou')}
                                    {cardPaymentStatus === 'failed' && paymentErrorMessage}
                                </StyledText>
                                <div className="NewPurchaseView__illustrationContainer__illustration">
                                    {cardPaymentStatus === 'failed' && <FailedPersonIllustration />}
                                    {cardPaymentStatus === 'success' && <SuccessPersonIllustration />}
                                </div>
                                {cardPaymentStatus === 'failed' && (
                                    <Button
                                        className={'NewPurchaseView__illustrationContainer__button'}
                                        onClick={() => {
                                            setCardPaymentStatus('ready');

                                            if (vivaPaymentToken === undefined || !vivaPaymentToken.success) {
                                                setGetToken(old => old + 1);
                                            }
                                        }}
                                        translate
                                    >
                                        {t('cardPayments.tryAgain')}
                                    </Button>
                                )}
                            </div>
                        )}
                        {cardPaymentStatus !== 'failed' && cardPaymentStatus !== 'success' && (
                            <div style={{ flex: 1, alignSelf: 'center' }}>
                                <div className="NewPurchaseView__stackedOption">
                                    <div className="NewPurchaseView__stackedOption__icon">
                                        {/* <CardPaymentIcon /> */}
                                        {cardPaymentStatus === 'pending' && <GridLoader />}
                                        {cardPaymentStatus !== 'pending' && <CardPaymentIcon />}
                                    </div>
                                    {(cardPaymentStatus === 'ready' || cardPaymentStatus === 'not_ready') && (
                                        <Button
                                            className={'NewPurchaseView__stackedOption__button'}
                                            onClick={() => {
                                                handleStartCardPayment();
                                            }}
                                            translate
                                            disabled={!emailForReceiptSaved || cardPaymentStatus === 'not_ready'}
                                        >
                                            {t('cardPayments.payByCard')}
                                        </Button>
                                    )}
                                    {cardPaymentStatus === 'pending' && !usingOnDevicePayments && (
                                        <Button
                                            className={'NewPurchaseView__stackedOption__button'}
                                            onClick={() => {
                                                handleCancelCardPayment();
                                            }}
                                            translate
                                        >
                                            {t('cancel')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }

        if (paymentType === 'qr') {
            return (
                <>
                    <div className="NewPurchaseView__stackedOption">
                        <div
                            className={`NewPurchaseView__stackedOption__icon ${
                                hasScannedQrCode ? 'NewPurchaseView__stackedOption__icon__scanned' : ''
                            }`}
                        >
                            <PurchaseQrCode
                                onScan={() => setHasScannedQrCode(true)}
                                onCancelClearSession={onCloseButtonClicked}
                            />
                        </div>
                        {!hasScannedQrCode && (
                            <StyledText
                                className="NewPurchaseView__stackedOption__text"
                                style={TextStyle.Body}
                                size={TextSize.Small}
                                translate
                            >
                                {t('cardPayments.scanToPay')}
                            </StyledText>
                        )}
                    </div>
                </>
            );
        }
    };

    return (
        <div className="NewPurchaseView">
            <PanelHeader
                onBackButtonClicked={() => purchaseContext.continue(PurchaseStep.BASKET)}
                // onCloseButtonClicked={onCloseButtonClicked}
                title={t('purchase_view.header.title', {
                    option: purchaseContext.checkoutAcquisitionOption?.displayName,
                })}
            />
            <div className="NewPurchaseView__body">
                {acquisitionCost > 0 && (
                    <div className="NewPurchaseView__acquisitionCost">
                        <StyledText
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            className={'NewPurchaseView__acquisitionCost__title'}
                            translate
                        >
                            {purchaseContext.checkoutAcquisitionOption?.displayName}
                            {': '}
                        </StyledText>
                        <StyledText
                            style={TextStyle.Body}
                            size={TextSize.Small}
                            className={'NewPurchaseView__acquisitionCost__price'}
                            translate
                        >
                            {CurrencyService.format(acquisitionCost)}
                        </StyledText>
                    </div>
                )}
                <StyledText
                    className="NewPurchaseView__totalPrice"
                    style={TextStyle.Heading}
                    size={TextSize.Large}
                    translate
                >
                    {t('checkout_view.total')}
                    {': '}
                    <span className="NeverTranslate">{CurrencyService.format(totalPrice)}</span>
                </StyledText>
                {showIntersportTempCode && (
                    <StyledText
                        style={TextStyle.Body}
                        size={TextSize.Medium}
                        className={'PurchaseView__tempText'}
                        translate
                    >
                        {t('intersport_temp')}
                        <br />
                        <br />
                        {t('intersport_temp_2')}
                    </StyledText>
                )}

                {showCisalfaTempCode && (
                    <StyledText
                        style={TextStyle.Body}
                        size={TextSize.Medium}
                        className={'PurchaseView__tempText'}
                        translate
                    >
                        Scegli il ritiro gratuito in negozio con il servizio Click & Collect o approfitta della consegna
                        gratuita per ordini superiori a 99€.
                        <br />
                        <br />
                        Sei un cliente Cisalfa PRO? Per te la spedizione standard è sempre gratuita
                    </StyledText>
                )}

                {showDecathlonTempCode && paymentType === 'qr' && (
                    <StyledText
                        style={TextStyle.Body}
                        size={TextSize.Medium}
                        className={'PurchaseView__tempText'}
                        translate
                    >
                        (1) Connectez vous ou créez un compte sur Decathlon.mq
                        <br />
                        <br />
                        (2) Flashez le QR code ci-dessous
                    </StyledText>
                )}

                <div className="NewPurchaseView__options">{getOptionsUI()}</div>
                <div className="NewPurchaseView__paymentIcons">
                    <img src="/paymentMethods/apple.png" alt="Apple Pay" />
                    <img src="/paymentMethods/google.png" alt="Google Pay" />
                    <img src="/paymentMethods/master.png" alt="Mastercard" />
                </div>
            </div>
        </div>
    );
};
