import { List } from 'immutable';
import { AnalyticsService } from './AnalyticsService';
import { GoogleAnalyticsService } from './GoogleAnalyticsService';
import { AnalyticsTrackerMapperService } from './mapping/AnalyticsTrackerMapperService';
import { GoogleMetadataService } from './metadata/GoogleMetadataService';
import { DisabledAnalyticsService } from './NoopAnalyticsService';
import { CloudshelfEngineConfig } from '../ConfigurationService/types/config/CloudshelfEngineConfig';
import { SessionManagementService } from '../SessionManagementService/SessionManagementService';

/**
 * The factory will build an analytics service compatible with the trackers specified
 * in the cloudshelf configuration.
 *
 * Please note that passing different tracker types is not supported at the moment. In
 * the future, we could imagine a dispatcher that serves as command center for all analytics
 * and redirects to the different tracking services (one for each active tracker).
 */
export function buildAnalyticsService(
    configuration: CloudshelfEngineConfig,
    sessionManagementService: SessionManagementService,
): AnalyticsService {
    const { trackers: trackersDtos } = configuration;
    if (!trackersDtos || !trackersDtos.length) {
        return new DisabledAnalyticsService();
    }
    const metadataService = new GoogleMetadataService(configuration, sessionManagementService);
    const trackers = AnalyticsTrackerMapperService.toTrackers(trackersDtos);
    return new GoogleAnalyticsService(metadataService, List(trackers));
}
