import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { dependenciesContainer } from '../../dependancyInjection/DependenciesInitializer';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../../services/ConfigurationService/ConfigurationService';
import _ from 'lodash';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

export interface PurchaseTickProps {
    animate?: boolean;
    useBrandPurchaseColor?: boolean;
}

const circleVariants = {
    start: {
        pathLength: 0,
        fill: 'argb(255, 255, 255, 0)',
    },
    end: {
        pathLength: 1,
        fill: '#20A000',
        transition: {
            fill: { duration: 0.5, ease: 'easeInOut', delay: 0.85 },
            pathLength: { duration: 1, ease: 'easeInOut' },
        },
    },
};

const tickVariants = {
    start: {
        pathLength: 0,
        fill: 'argb(255, 255, 255, 0)',
        stroke: 'argb(255, 255, 255, 0)',
    },
    end: {
        pathLength: 1,
        fill: '#FFF',
        // stroke: '#FFF',
        transition: {
            pathLength: { duration: 1, ease: 'easeInOut' },
            fill: { duration: 0.5, ease: 'easeInOut', delay: 0.85 },
            // stroke: { duration: 0.5, ease: 'easeInOut', delay: 0.85 },
        },
    },
};

const PurchaseTick: FunctionalComponentWithChildren<PurchaseTickProps> = props => {
    const localCircleVariants = _.cloneDeep(circleVariants);
    if (props.useBrandPurchaseColor) {
        const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
        localCircleVariants.end.fill = configService.config()?.theme.purchaseTextColor ?? '#20A000';
    }

    if (props.animate) {
        return (
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 170 170"
                fill="none"
                stroke="#FFF"
                strokeWidth={4}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                overflow="inherit"
            >
                <motion.rect
                    width="170"
                    height="170"
                    rx="85"
                    fill="#20A000"
                    variants={localCircleVariants}
                    initial="start"
                    animate="end"
                />
                <motion.path
                    d="M68.9857 105.495L51.4701 87.1256C50.545 86.1433 49.2834 85.5905 47.9669 85.5905C46.6505 85.5905 45.3889 86.1433 44.4638 87.1256C42.5121 89.1725 42.5121 92.4266 44.4638 94.4735L65.4325 116.465C67.3843 118.512 70.5371 118.512 72.4888 116.465L125.536 60.883C127.488 58.8361 127.488 55.582 125.536 53.5351C124.611 52.5528 123.349 52 122.033 52C120.717 52 119.455 52.5528 118.53 53.5351L68.9857 105.495Z"
                    variants={tickVariants}
                    initial="start"
                    animate="end"
                />
            </motion.svg>
        );
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" fill="none">
            <rect width="170" height="170" rx="85" fill="#20A000" />
            <path
                d="M68.9857 105.495L51.4701 87.1256C50.545 86.1433 49.2834 85.5905 47.9669 85.5905C46.6505 85.5905 45.3889 86.1433 44.4638 87.1256C42.5121 89.1725 42.5121 92.4266 44.4638 94.4735L65.4325 116.465C67.3843 118.512 70.5371 118.512 72.4888 116.465L125.536 60.883C127.488 58.8361 127.488 55.582 125.536 53.5351C124.611 52.5528 123.349 52 122.033 52C120.717 52 119.455 52.5528 118.53 53.5351L68.9857 105.495Z"
                fill="white"
            />
        </svg>
    );
};
export default PurchaseTick;
