import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { useState } from 'react';
import _ from 'lodash';
import Button, { ButtonSize } from '../../../../shared/inputs/Button/Button';

const SampleTextOverlay = () => {
    const parsedQuery = new URLSearchParams(window.location.search);
    const shouldShowSampleTest = parsedQuery.get('previewFonts') !== null;
    const [fontFamily, setFontFamily] = useState<TextStyle>(TextStyle.Body);

    return shouldShowSampleTest ? (
        <div className={'SampleTextOverlay_sampleText'}>
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Large}>
                This is a sample text overlay to help with theme development.
                <br /> There are three font families, and three sizes which can be combined. <br />
                Additionally there is a Cloudshelf Font Family(this font) which you cannot configure.
            </StyledText>
            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Large}>
                Font Families:
            </StyledText>
            <div className={'SampleTextOverlay_space'} />
            <StyledText style={TextStyle.Heading}>This is your heading font</StyledText>
            <StyledText style={TextStyle.Subheading}>This is your subheading font</StyledText>
            <StyledText style={TextStyle.Body}>This is your body font</StyledText>
            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Large}>
                Font Sizes:
            </StyledText>
            <div className={'SampleTextOverlay_space'} />
            <StyledText style={fontFamily} size={TextSize.Small}>
                This is the small font size
            </StyledText>
            <StyledText style={fontFamily} size={TextSize.Medium}>
                This is the medium font size
            </StyledText>
            <StyledText style={fontFamily} size={TextSize.Large}>
                This is the large font size
            </StyledText>
            <StyledText style={fontFamily} size={TextSize.Hero}>
                This is the hero font size
            </StyledText>
            <hr />
            <StyledText style={TextStyle.CloudshelfBold} size={TextSize.Large}>
                Font sizes are currently using the "{_.toLower(fontFamily)}" font family.
            </StyledText>
            <div className={'SampleTextOverlay_space'} />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1vw', justifyContent: 'center' }}>
                <Button size={ButtonSize.SM} onClick={() => setFontFamily(TextStyle.Heading)}>
                    Change to Heading
                </Button>
                <Button size={ButtonSize.SM} onClick={() => setFontFamily(TextStyle.Subheading)}>
                    Change to Subheading
                </Button>
                <Button size={ButtonSize.SM} onClick={() => setFontFamily(TextStyle.Body)}>
                    Change to Body
                </Button>
            </div>
        </div>
    ) : null;
};

export default SampleTextOverlay;
