import { AcquisitionOptionPriceType, TileSize } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CheckoutFlowAcquisitionOption } from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';

export class AcquisitionOptionUtil {
    static getAcquisitionCost(acquisitionOption: CheckoutFlowAcquisitionOption, basketValue: number): number {
        console.log('getAcquisitionCost', acquisitionOption, basketValue);
        if (acquisitionOption.priceType === AcquisitionOptionPriceType.Fixed) {
            return acquisitionOption.price;
        } else if (
            acquisitionOption.priceType === AcquisitionOptionPriceType.FreeIfAboveThreshold &&
            acquisitionOption.basketValueThreshold
        ) {
            return basketValue >= acquisitionOption.basketValueThreshold ? 0 : acquisitionOption.price;
        }

        return acquisitionOption.price;
    }
}
