import { FC, useEffect, useRef, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { FunctionalComponentWithChildren } from '../../FCWithChildren';

export interface ResponsiveYoutubePlayerProps {
    videoId: string;
}
export const ResponsiveYoutubePlayer: FunctionalComponentWithChildren<ResponsiveYoutubePlayerProps> = props => {
    const divRef = useRef<HTMLDivElement>(null);
    const [playerSize, setPlayerSize] = useState<{ height: number; width: number }>({
        height: (divRef.current?.clientWidth ?? 640) * 0.5625,
        width: divRef.current?.clientWidth ?? 640,
    });

    useEffect(() => {
        resizePlayer();
    }, [divRef]);

    useEffect(() => {
        window.addEventListener('resize', resizePlayer);
        resizePlayer();
        return () => window.removeEventListener('resize', resizePlayer);
    }, []);

    const resizePlayer = () => {
        if (divRef.current) {
            const { width } = divRef.current.getBoundingClientRect();
            const height = width * 0.5625;
            setPlayerSize({ height: height, width });
        }
    };
    const onPlayerReady: YouTubeProps['onReady'] = () => {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
    };

    const opts: YouTubeProps['opts'] = {
        height: playerSize.height,
        width: playerSize.width,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
            autoplay: 0,
            modestbranding: 1,
            rel: 0,
            controls: 1,
        },
    };
    return (
        <div ref={divRef} style={{ width: '100%' }}>
            <YouTube videoId={props.videoId} opts={opts} onReady={onPlayerReady} />
        </div>
    );
};
